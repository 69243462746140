div.DraftEditor-root {
  text-align: left;
  margin: auto;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  font-size: 16px;
  font-family: "calibri", sans-serif;
  padding: 8.5px 14px 8.5px 14px !important;
  border: 1px solid gray;
  border-radius: 4px;
}

.public-DraftEditorPlaceholder-inner {
  position: absolute;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  pointer-events: none;
  color: var(--text-3);
}

.public-DraftStyleDefault-block {
  word-break: break-all;
}
