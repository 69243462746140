:root {
  --transition-duration: 0.2s;
  --cursor-color: rgba(68, 107, 242, 0.8);
  --user-profile-avatar-hover: rgba(0, 0, 0, 0.6);
}

:root[data-theme="light"] {
  --teambook-red: #fe646466;
  --teambook-yellow: #ffdc6166;
  --background-1: #fff;
  --background-2: #f5f5f5;
  --transparent-bg-2: #f5f5f580;
  --text-1: #112a46;
  --text-2: #474747;
  --text-3: #d0d0d0;
  --text-4: #4c54a0;
  --text-5: #4c54a0;
  --text-6: #474747;
  --text-7: #474747;
  --text-8: #474747;
  --text-9: #112a46;
  --text-10: #474747;
  --text-11: #4c54a0;
  --text-12: #4c54a0;
  --text-13: rgba(71, 71, 71, 0.5);
  --text-14: #474747;
  --text-15: rgba(71, 71, 71, 0.7);
  --accent-dark: #4c54a0;
  --accent-light: #9199e7;
  --stroke: #c8d2e6;
  --icon-stroke: #c8d2e6;
  --stroke-transparent: #c8d2e666;
  --success: #23a942 !important;
  --error: #e3262f;
  --box-shadow: 10px 10px 40px 0px rgba(151, 175, 203, 0.25);
  --primary-btn-hover-bg: #353d8b;
  --secondary-btn-hover-bg: #484e5c;
  --weekend-booking-sign: #112a46;

  --estimated-bg: rgba(218, 223, 234, 0.3);
  --link-color: #007bff;
  --link: #007bff;
  --header-nav-color: #4c54a0;
  --selected-option-color: #4c54a0;
  --mui-modal-backdrop: linear-gradient(0deg, rgba(208, 208, 208, 0.4) 0%, rgba(208, 208, 208, 0.4) 100%);

  /* button outline*/
  --button-outline-border-default: #c8d2e6;
  --button-outline-text-default: #4c54a0;

  /*  default blue button */
  --button-text-hover: #4c54a0;
  --button-bg-disabled: #d0d0d0;
  --button-text-disabled: #fff;

  --user-profile-avatar: #9199e7;
  --switch-thumb: #f5f5f5;
  --switch-bg-disabled: #d0d0d0;

  --weekend-day-text: #474747;
  --planner-divider: #9199e7;
  --planner-select-view-button: #c8d2e6;
  --planner-blocked-space: #d0d0d066;
  --planner-rightclick-hover: #f5f5f5;

  --capacity-inner-content: #fbfbfb;
  --color-text-2-filter: invert(15%) sepia(20%) saturate(1500%) hue-rotate(180deg) brightness(90%);
  --header-icon-filter: invert(32%) sepia(69%) saturate(1352%) hue-rotate(221deg) brightness(91%) contrast(89%);

  --dashboard-top-blue: #7380ee;
  --dashboard-top-pink: #cea8ff;
  --dashboard-top-green: #a8ffac;
  --dashboard-top-red: #ee9797;
  --dashboard-top-yellow: #ffeca8;

  --slider-location: #f5f5f5;
  --actuals-hours-background: #c8d2e699;
}

:root[data-theme="dark"] {
  --teambook-red: #ffa0a099;
  --teambook-yellow: #ffe99e99;
  --background-1: #1a1b23;
  --background-2: #252734;
  --transparent-bg-2: rgba(72, 78, 92, 1);
  --text-1: #fff;
  --text-2: rgba(255, 255, 255, 0.75);
  --text-3: rgba(255, 255, 255, 0.6);
  --text-4: rgba(255, 255, 255, 0.75);
  --text-5: #fff;
  --text-6: #99a0e0;
  --text-7: #474747;
  --text-8: #ffffffbf;
  --text-9: #ffffffbf;
  --text-10: #fff;
  --text-11: rgba(255, 255, 255, 0.6);
  --text-12: #99a0e0;
  --text-13: rgba(255, 255, 255, 0.6);
  --text-14: #1a1b23;
  --text-15: rgba(255, 255, 255, 0.6);
  --accent-dark: #49587a;
  --accent-light: #99a0e0;
  --stroke: #484e5c;
  --icon-stroke: rgba(255, 255, 255, 0.75);
  --stroke-transparent: #484e5c66;
  --success: #4ffb77 !important;
  --error: #ff2d38;
  --box-shadow: 10px 10px 20px 0px rgba(9, 10, 18, 0.4);
  --primary-btn-hover-bg: #36476c;
  --weekend-booking-sign: #ffffffbf;

  --estimated-bg: rgba(218, 223, 234, 0.3);
  --link-color: #99a0e0;
  --link: #64acfa;
  --header-nav-color: #99a0e0;
  --selected-option-color: rgba(255, 255, 255, 0.75);
  --mui-modal-backdrop: linear-gradient(0deg, rgba(72, 78, 92, 0.4) 0%, rgba(72, 78, 92, 0.4) 100%);

  /* button outline*/
  --button-outline-border-default: rgba(255, 255, 255, 0.75);
  --button-outline-text-default: rgba(255, 255, 255, 0.75);

  /*  default blue button */
  --button-text-hover: #fff;
  --button-bg-disabled: #484e5c;
  --button-text-disabled: #252734;

  --user-profile-avatar: #49587a;
  --switch-thumb: rgba(255, 255, 255, 0.75);
  --switch-bg-disabled: #484e5c;

  --weekend-day-text: rgba(255, 255, 255, 0.75);
  --planner-divider: rgba(255, 255, 255, 0.6);
  --planner-select-view-button: #49587a;
  --planner-blocked-space: #484e5c66;
  --planner-rightclick-hover: #49587a;

  --capacity-inner-content: #1e202a;
  --color-text-2-filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) opacity(75%);
  --header-icon-filter: invert(0) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%);

  --dashboard-top-blue: #5862bb;
  --dashboard-top-pink: #af8eda;
  --dashboard-top-green: #7ec381;
  --dashboard-top-red: #ca7979;
  --dashboard-top-yellow: #d4c387;

  --slider-location: #484e5c;
  --actuals-hours-background: #484e5c99;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: overlay;
  background: var(--background-1) !important;
}

html {
  background: var(--background-1);
}

.pointer {
  cursor: pointer;
}

html ::-webkit-scrollbar {
  height: 7px;
  background: var(--background-2) !important;
  border-radius: 10px;
  width: 7px;
}

html ::-webkit-scrollbar-thumb {
  background: var(--stroke) !important;
  height: 16px;
  border-radius: 10px;
}

html ::-webkit-scrollbar-corner {
  background-color: var(--background-1) !important;
}

@-moz-document url-prefix() {
  * {
    scrollbar-width: thin;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.MuiSelect-select:focus {
  background: transparent !important;
}
.MuiSelect-select {
  background: transparent !important;
  border: none !important;
}
.MuiSelect-select option {
  padding: 10px !important;
}
.warning-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.warning-shadow {
  background: #000;
  opacity: 0.3;
  width: 100%;
  height: 100%;
  z-index: 2;
  top: 0;
  left: 0;
  position: absolute;
}

.warning-block {
  margin-top: -15%;
  background: #ffffff;
  z-index: 3;
  width: 400px;
  padding: 20px 35px;
}
.warning-buttons {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 30px;
}
.button__warning {
  background-color: #457ec1 !important;
  color: #ffffff !important;
  text-transform: capitalize !important;
}

/* Styles for mobile version */
@media screen and (max-width: 1100px) {
  .mobile_hidden {
    display: none !important;
  }

  .login__input-side__logo img {
    max-width: 250px !important;
  }

  .login__input-side {
    width: 100% !important;
  }

  .login__input-side__form {
    grid-template-columns: 2fr 15fr 2fr !important;
  }

  .register__input-side__logo img {
    max-width: 250px !important;
  }

  .register__input-side {
    width: 100% !important;
  }

  .register__input-side__form {
    grid-template-columns: 2fr 10fr 2fr !important;
  }

  .invitation_input {
    width: 85% !important;
  }
}

@media screen and (max-width: 640px) {
  .only_mobile_hidden {
    display: none !important;
  }
}

@media screen and (min-width: 1100px) {
  .desktop_hidden {
    display: none !important;
  }
}

@media screen and (max-width: 1350px) {
  .onboarding-content {
    height: 450px;
  }
}

legend {
  width: fit-content !important;
}

.flex {
  display: flex;
}

.tb-radio-button {
  margin-right: 15px;
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

.danger-text {
  color: #e3262f;
  margin-top: 10px;
}

.MuiModal-backdrop {
  background: var(--mui-modal-backdrop) !important;
}

.tb-react-select__placeholder {
  text-align: left;
}

.capterra-tracking {
  position: absolute;
  top: 0;
}
