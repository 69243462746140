:root[data-theme="light"] {
  --login-register-form-title: #474747;
  --login-register__or-line: #c8d2e6;
  --login-register__or-text: #9199e7;
}

:root[data-theme="dark"] {
  --login-register-form-title: #fff;
  --login-register__or-line: #49587a;
  --login-register__or-text: #49587a;
}

.login {
  width: 100%;
  height: 100%;
}

.login__left-side,
.login__right-side {
  width: 50%;
  position: relative;
}

@media (max-width: 1100px) {
  .login__right-side {
    width: 100%;
  }

  .login__form {
    top: 130px !important;
    width: 100% !important;
    padding: 0px 30px;
    margin: unset !important;
  }
}

.auth__left-side-background {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: var(--stroke);
  opacity: 0.4;
  z-index: 0;
}

.login__left-side-images {
  img {
    position: absolute;
  }
}

.login__tb-logo {
  width: 180px;
  height: 25px;
  left: 50px;
  top: 50px;
}

.login__input-side__login-text,
.register__input-side__register-text {
  margin-bottom: 0px;
  color: var(--login-register-form-title);
  font-size: 22px;
  font-weight: 600;
}

.login__input-side__login-link,
.register__input-side__login-link {
  font-size: 14px;
  text-decoration: unset !important;
  color: var(--link-color);
  margin-bottom: 50px;
  transition: color var(--transition-duration);
}

.login__form {
  position: absolute;
  top: 180px;
  left: 0px;
  right: 0px;
  margin: 0 auto;
  width: 350px;
  text-align: left;

  .input-form__form-text {
    margin: 0px;
  }
}

.login-eye-icon {
  position: absolute;
  bottom: 15px;
  right: 15px;
  cursor: pointer;
}

.forgot-password {
  width: fit-content;
  text-align: left;
  font-size: 0.9rem;
  cursor: pointer;
  color: var(--link-color);

  p {
    line-height: 158%;
    margin: 0;
  }
}

.login-register__or-line {
  width: 100%;
  height: 20px;
  margin: 20px 0;
  display: flex;
  align-items: center;
  background-color: transparent;

  .line {
    flex-grow: 1;
    height: 1px;
    background-color: var(--login-register__or-line);
  }

  p {
    color: var(--login-register__or-text);
    margin: 0 10px;
  }
}

.login-options {
  display: flex;
  gap: 20px;

  #google_login_btn {
    flex: 1;
    max-width: 50%;

    //div[role="button"] {
    //  width: 100%;
    //  min-width: 90px !important;
    //  height: 40px !important;
    //  justify-content: center;
    //  box-shadow: unset !important;
    //  border-radius: 7px !important;
    //  font-size: 14px;
    //  letter-spacing: 0.4px;
    //  transition: all var(--transition-duration);
    //  background-color: transparent !important;
    //  border: 1px solid var(--button-outline-border-default) !important;
    //  color: var(--button-outline-text-default) !important;
    //
    //  &:hover {
    //    border: 1px solid var(--background-2) !important;
    //    background-color: var(--background-2) !important;
    //  }
    //  &:active {
    //    border: 1px solid var(--stroke) !important;
    //    background-color: var(--stroke) !important;
    //  }
    //  p {
    //    margin: 0px;
    //    overflow: hidden;
    //    text-overflow: ellipsis;
    //  }
    //  div {
    //    background-color: transparent !important;
    //  }
    //}
  }

  #azure_login_btn {
    flex: 1;
    height: 40px;
    font-size: 14px !important;
    border-radius: 7px;
    color: var(--button-outline-text-default) !important;
    border: 1px solid var(--button-outline-border-default) !important;

    &:hover {
      border: 1px solid var(--background-2) !important;
      background-color: var(--background-2) !important;
    }
    &:active {
      border: 1px solid var(--stroke) !important;
      background-color: var(--stroke) !important;
    }
  }
}

.notifications_container {
  position: fixed;
  left: 50px;
  bottom: 50px;
  z-index: 5;
}

.notification-body {
  margin: 0px;
  width: 650px;
  max-width: 650px;
  min-width: 650px;

  background: var(--background-1);
  border: 1px solid var(--stroke);
  box-sizing: border-box;
  box-shadow: var(--box-shadow);
  border-radius: 10px;

  @media (max-height: 800px) {
    .new-login-bg {
      height: 380px !important;
      top: -350px !important;
    }
  }
  .notification-header {
    height: 30%;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      margin: auto 0;
      padding: 20px 0;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      color: var(--text-2);
    }
  }

  .notification-content {
    padding: 0 20px 20px 20px;
    text-align: left;
    color: var(--text-2);

    a {
      color: var(--link-color);
      text-decoration: underline;
    }

    .notification-content-text {
      padding: 0 0 0 20px;
      font-size: 14px;
      line-height: 24px;
      text-align: left;
    }
  }
}

.login-with-google-button {
  width: 100%;
  min-width: 90px !important;
  height: 40px !important;
  justify-content: center;
  box-shadow: unset !important;
  border-radius: 7px !important;
  font-size: 14px;
  letter-spacing: 0.4px;
  transition: all var(--transition-duration);
  background-color: transparent !important;
  border: 1px solid var(--button-outline-border-default) !important;
  color: var(--button-outline-text-default) !important;
  cursor: pointer;
  display: flex;
  flex: 1 1;
  padding: 6px 8px;

  &:hover {
    border: 1px solid var(--background-2) !important;
    background-color: var(--background-2) !important;
  }
  &:active {
    border: 1px solid var(--stroke) !important;
    background-color: var(--stroke) !important;
  }
  p {
    margin: 2px 0 0 0;
    overflow: hidden;
    text-overflow: ellipsis;
    width: fit-content;
  }
  div {
    background-color: transparent !important;
  }
}

.login-dialog-content {
  width: 480px;

  @media (max-width: 600px) {
    width: 350px;
  }
  @media (min-width: 601px) and (max-width: 1100px) {
    width: 560px;
    font-size: 16px;
  }
}
