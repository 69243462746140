.login {
  display: flex;
}

.login__bg-image {
  background-image: url(bg-signup.jpg);
  background-size: cover;
  background-position: center;
  width: 55%;
  min-height: 100vh;
  height: available;
}

.login__input-side {
  width: 45%;
}

.login__input-side__logo {
  display: flex;
}

.logo {
  padding-left: 35px;
  padding-top: 20px;
}

.login__input-side__form {
  margin-top: 15%;
  width: initial;
  grid-template-columns: 2fr 3fr 2fr;
  display: grid;
  grid-gap: 20px;
  grid-template-rows: 50px;
  grid-template-areas:
    ". a ."
    ". . ."
    ". b .";
}

.login__input-side__form__data {
  grid-area: b;
}

.login__input-side__form__data .Mui-error {
  margin-left: 0;
  margin-right: 0;
}
.login__input-side__form__data .Mui-error div {
  margin-left: 0;
}

.login__input-side__head {
  grid-area: a;
  text-align: initial;
  font-size: 1.5rem;
}

.form-value {
  display: flex;
  align-self: center;
  justify-self: center;
  margin-bottom: 12px !important;
}

.form-value .text-field {
  min-width: 100%;
}

.validation-text {
  margin-left: 15px;
  min-width: fit-content;
}

.login__input-side__policy {
  grid-area: g;
  font-size: 0.9rem;
  white-space: nowrap;
}

.login__input-side__policy__links {
  display: flex;
  justify-content: center;
}

.login__input-side__policy__links p {
  margin-top: 0;
  margin-left: 5px;
  margin-right: 5px;
}

.login__input-side__submit {
  margin-top: 20px !important;
}
