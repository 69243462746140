.schedule__top-text {
  margin-bottom: 40px;
  font-weight: 400;
  font-size: 16px;
  color: var(--text-1);
  text-align: left;
}

.schedule__day {
  display: grid;
  grid-template-columns: 1fr 2fr 3fr 2fr 1fr;
  padding-bottom: 16px;

  .MuiOutlinedInput-notchedOutline {
    border: unset;
  }

  .MuiInputBase-input {
    padding: unset;
    text-decoration: underline;
    width: 45px !important;
    -webkit-text-fill-color: var(--text-2) !important;
  }
}

.schedule__day-checkbox {
  display: flex;
  align-items: center;
  min-height: 30px;
  img {
    min-width: 20px;
    max-width: 20px;
    max-height: 20px;
    min-height: 20px;
  }
  p {
    margin: 0 0 0 10px;
    font-size: 14px;
  }
}

.schedule__lunch-pickers {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  .MuiInputBase-root {
    padding-right: 0px;
  }
}

.schedule-time__picker {
  border: 0px;
  border-bottom: 1px solid var(--stroke);
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  color: var(--text-2);
  background-color: var(--background-2);

  &:focus {
    border-bottom: 1px solid var(--stroke);
    outline: 0;
  }
  &:focus-visible {
    border-bottom: 1px solid var(--stroke);
    outline: 0;
  }

  &::-webkit-calendar-picker-indicator {
    filter: var(--color-text-2-filter);
  }
}

.schedule__header {
  display: grid;
  grid-template-columns: 1fr 2fr 3fr 2fr 1fr;
  padding-bottom: 20px;

  p {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: unset;
  }
}

.schedule__header-end {
  margin-right: 16px;
}

.schedule__header-start {
  margin-right: 10px;
}

.schedule__header-lunch {
  margin-right: 65px;
}

.schedule__day-duration {
  p {
    margin: 0;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 300;
    line-height: 24px;
  }
}

.schedule__day-start {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  .MuiInputBase-root {
    padding-right: 10px;
  }
}
.schedule__day-end {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  .MuiInputBase-root {
    padding-right: 10px;
  }
}
