$new_blue: #4c54a0;

.planner-page,
.grouped-planner-page {
  display: flex;
  flex-direction: column;
  height: 100vh;

  .date-row__weekend-cell {
    background: var(--background-2);
  }
  .user-row-weekends__day {
    background: var(--background-2);
  }

  .planner-page__planner-control {
    margin: 0;
    position: fixed;
    top: 60px;
    width: 100%;

    z-index: 7;

    .planner-control__filter-text-field {
      display: flex;
      position: relative;
      font-size: 12px;

      @media (max-width: 1100px) {
        display: flex;
        align-items: center;
      }

      .planner-control__filter-icon {
        position: sticky !important;
        z-index: 5;
        left: 10px;
        top: 8px;
        cursor: pointer;

        @media (max-width: 640px) {
          position: relative;
          left: unset;
          top: unset;
        }
      }

      .input-form-component {
        margin: 0;
        border: 1px solid #c8d2e6;
        border-radius: 7px;
        padding: 14px 4px 14px 40px;
        width: 400px;
        font-family: "Montserrat";
        //line-height: 16px;
        font-size: 16px;
        &:focus,
        &:focus-visible {
          outline: none;
        }
      }
    }

    .planner-page__control-bar-first-line {
      width: 100%;
      display: flex;
      height: 40px;
      margin: 10px 0 10px 0;
      padding: 0px 30px;
      gap: 40px;

      @media (max-width: 1100px) {
        margin: 10px 0 10px 0;
        padding: 0px 10px;
        gap: 3px;
        width: 100%;
        justify-content: space-around;
      }

      .calnedar-icon {
        display: none;
        @media (max-width: 1100px) {
          display: block;
          width: 24px;
          height: 24px;
          margin: auto;
        }
      }
    }

    .planner-page__control-bar-second-line {
      width: 100%;
      display: flex;
      justify-content: space-between;
      //position: fixed;
      height: 50px;

      @media (max-width: 1100px) {
        padding-right: 0px;
      }

      .input-form-component {
        margin: 0;
      }
    }

    .control-bar-second-line__whitespace {
      background-color: unset;
      height: 40px;
      display: flex;
      // position: fixed;
      margin-left: auto;
      padding-right: 0px !important;
      gap: 40px;
    }

    .control-bar-second-line__whitespace.right {
      padding-left: 25px;
      padding-right: 30px;
    }

    .control-bar-second-line__whitespace.left {
      padding-right: 79px;
    }

    .planner-control__right-side,
    .planner-control__left-side {
      display: flex;
      gap: 12px;

      @media (max-width: 1100px) {
        width: unset;
        gap: 10px;
      }
    }

    @media (max-width: 1100px) {
      flex-direction: column;
    }

    .planner-select {
      .MuiOutlinedInput-input {
        padding: 15px 18px;
        margin-right: 20px;
      }
    }
  }

  .date-row__date-cell,
  .date-row__date-cell__past,
  .date-row__weekend-cell,
  .date-row__date-cell__today,
  .date-row__hours {
    border-top: 1px solid var(--stroke);
  }

  .planner-grid,
  .grouped-planner-grid {
    position: fixed;
    top: 120px;
    bottom: 0;
    right: 0;
    overflow-y: hidden !important;
    user-select: none;
    left: 30px;
    color: var(--text2);

    .grouped-planner__filter-cell {
      background-color: var(--background-1);
      position: sticky;
      left: 0px;
      z-index: 6;
      border-bottom: 1px solid var(--stroke);
      border-right: 1px solid var(--stroke);
      user-select: none;
      font-size: 16px;
      display: flex;
      gap: 0.5rem;
    }

    .date-row__month-row {
      display: flex;
      width: fit-content;
      position: sticky;
      top: 0;
      z-index: 5;
    }

    .date-row__month-row.zoom-month-row-1 {
      width: 1720px !important;
      background-color: var(--background-1);
    }

    .date-row__date-row {
      display: flex;
      width: fit-content;
      position: sticky;
      top: 34px;
      z-index: 5;
      background: var(--background-1);
    }

    .date-row__weekend-cell__container {
      position: relative;
      height: fit-content;
      background: var(--background-1);
    }

    .date-row__weekend-cell {
      border-left: 1px solid var(--text-3);
      // background: repeating-linear-gradient(45deg, var(--background-1) -1px, var(--background-1) 3px, var(--text-3) 1px, var(--text-3) 4px);

      height: 30px;
      padding: 0;
      position: -webkit-sticky; /* for Safari */
      position: sticky;
      top: 30px;
      z-index: 2;
    }

    .date-row__weekend-cell__right-border {
      position: absolute;
      top: 0;
      right: -1px;
      z-index: 4;
      height: 100%;
      border-right: 1px solid var(--planner-divider);
    }

    .grouped-planner__weekend-cell {
      height: 29px;
      padding: 0;
      position: -webkit-sticky; /* for Safari */
      position: sticky;
      top: 30px;
      z-index: 2;
      width: 10px;
    }

    .date-row__date-cell {
      height: 30px;
      padding: 0;
      position: -webkit-sticky; /* for Safari */
      position: sticky;
      top: 34px;
      background-color: var(--background-1);
      border-bottom: 1px solid var(--stroke);
      cursor: pointer;
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;

      &.weekend {
        background: red;
      }
    }

    .date-row__date-cell__today {
      padding: 0;
      position: -webkit-sticky; /* for Safari */
      position: sticky;
      top: 34px;
      background-color: var(--stroke);
      border-bottom: 1px solid var(--stroke);
      cursor: pointer;
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .date-row__date-cell__past {
      height: 30px;
      padding: 0;
      position: -webkit-sticky; /* for Safari */
      position: sticky;
      top: 34px;
      background-color: var(--background-2);
      border-bottom: 1px solid var(--stroke);
      cursor: pointer;
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .date-row__month-cell {
      height: 34px;
      position: sticky;
      top: 0px;
      background-color: var(--background-1);
      z-index: 2;
    }

    .date-row__month-filter-cell {
      background-color: var(--background-1);
      position: sticky;
      left: 0px;
      z-index: 6;
      user-select: none;
      font-size: 16px;
      display: flex;
      gap: 0.5rem;
    }

    .filter-cell {
      border-top: 1px solid var(--stroke);
      width: 100%;
    }

    .avatar-cell-container {
      padding: 0 10px;
      height: 100%;
      display: flex;
      align-items: center;
      border-left: 1px solid var(--stroke);
      border-bottom: 1px solid var(--stroke);

      .avatar-cell__drag-icon {
        width: 18px;
        height: 18px;
        margin-left: auto;
      }
    }

    .date-row__filter-cell {
      height: 30px;
      background-color: var(--background-1);
      position: sticky;
      left: 0px;
      z-index: 6;
      // border-left: 1px solid var(--stroke);
      border-right: 1px solid var(--stroke);
      border-bottom: 1px solid var(--stroke);
      user-select: none;
      font-size: 16px;
      display: flex;
      gap: 0.5rem;
    }

    .date-row__date-text {
      font-size: 12px;
      color: var(--text-1);
      margin: unset;
    }

    .date-row__date-text__today {
      font-size: 12px;
      color: var(--text-1);
      margin: unset;
    }

    .date-row__sort-icon {
      cursor: pointer;
      margin-left: 10px;
      width: 24px;
      height: 24px;

      &:hover {
        svg {
          color: var(--text-1);
        }
      }
    }

    .creation-booking {
      position: absolute;
      border-right: 1px solid var(--stroke);
      border-bottom: 1px solid var(--stroke);
      cursor: grab;
      z-index: 1;
    }

    .day-info {
      position: absolute;
      background-color: #444c63;
      width: 220px;
      z-index: 6;

      p {
        color: white;
      }

      .day-info__cancel-button {
        display: flex;
        justify-content: flex-end;
        margin: 5px 5px 0 0;
        svg {
          color: white;
          cursor: pointer;
        }
      }

      .day-info__info-link {
        font-size: 12px;
        text-decoration: underline;
        margin: 10px 0 10px 10px;
        cursor: pointer;
      }

      .day-info__booking {
        border-bottom: 1px solid white;
        padding-bottom: 10px;
        margin-bottom: 15px;

        .day-info__info-text {
          p {
            font-size: 11px;
            margin: 0 0 0 5px;
            text-align: start;
            max-width: 100px;
            min-width: 100px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .bold {
            font-size: 13px;
          }

          .client {
            padding-top: 10px;
          }
        }

        .day-info__info-hours {
          color: white;
          font-size: 11px;
        }

        .day-info__booking-color {
          width: 4px;
          height: 40px;
          margin-right: 4px;
        }
      }
    }

    .user-avatar {
      position: -webkit-sticky; /* for Safari */
      position: sticky;
      left: 0;
      user-select: none;
      box-sizing: border-box;
      border-right: 1px solid var(--stroke);
      vertical-align: top;
      text-overflow: ellipsis;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;
      cursor: pointer;
      color: var(--text-2);
      transition: border var(--transition-duration);
      background-color: var(--background-1);

      .user-avatar__user-info {
        margin: 0px;
        font-size: 14px;
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
      }

      .user-avatar__top-buttons {
        display: flex;
        width: 100%;
        justify-content: space-between;
        height: 40px;
        position: absolute;
        top: 0;
      }
    }

    .tag-section {
      position: sticky;
      z-index: 4;
      width: 137px;
      left: 0px;

      .tag-section__tags {
        position: absolute;
        width: 100px;
        border-style: solid;
        border-color: var(--stroke);
        border-width: 1px 1px 1px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: var(--background-1);
        z-index: 1;

        .tag-section__tag {
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;

          p {
            margin: 0px;
          }

          font-size: 0.8rem;
          line-height: 1rem;
          max-width: 100%;
          word-break: break-word;
          height: fit-content;
          border-radius: 10px;
          color: var(--text-10);
          padding: 1px 3px 1px 3px;
          margin: 2px 3px 2px 3px;
          overflow: hidden;
        }
      }
    }

    .booking-line {
      height: 100%;
      position: absolute;
      left: 0px;
      top: 0px;
    }

    .planner__blocked-space {
      position: absolute;
      background-color: var(--planner-blocked-space);
      cursor: pointer;
      border-top: var(--stroke);
    }

    .booking {
      position: absolute;
      overflow: hidden;
      z-index: 1;

      &[data-active="true"] {
        opacity: 0.9 !important;
        border: 2px solid #4a59ac !important;
      }

      .booking__project-duration {
        font-size: 10px;
      }

      .booking__project-name {
        max-width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow-x: hidden;
        height: 16px;
        font-size: 10px;
        letter-spacing: 0.7px;
        font-weight: 600;

        img {
          width: 16px;
          height: 16px;
        }
      }

      .booking__project-code {
        max-width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        height: 16px;
        font-size: 10px;
        letter-spacing: 0.7px;
        font-weight: 600;
        text-transform: uppercase;

        img {
          width: 16px;
          height: 16px;
        }
      }

      .booking__task-name {
        max-width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        height: 16px;
        font-size: 10px;
        text-align: left;
        font-weight: 500;
      }

      .booking__booking-icon {
        display: flex;
        align-items: center;

        img {
          width: 16px;
          height: 16px;
        }
      }

      .booking__start-time-block {
        .booking__start-time-text {
          font-weight: 500;
          font-size: 0.6rem;
          margin-top: 2px;
          margin-bottom: 2px;
        }
      }

      .booking__comment {
        font-size: 10px;
        text-align: left;
        font-weight: 400;
      }
    }

    .booking__weekend-booking-sign {
      font-size: 20px;
      position: absolute;
      top: calc(45% - 4px);
      width: 15px;
      height: 15px;
      border-radius: 50%;
    }

    .hours-left-component {
      position: absolute;
      height: 17px;
      display: flex;
      z-index: 3;
      font-size: 12px;
      color: var(--text-2);
      cursor: pointer;

      .hours-left-component__weekend {
        width: 10px;
        background-color: #d0d0d0;
        height: 17px;
        border-right: 1px solid #d0d0d0;
      }
    }
  }

  .planner__context-menu {
    position: absolute;
    height: fit-content;
    min-width: 95px;
    background: var(--background-1);
    border: 1px solid var(--stroke);
    border-radius: 10px;
    z-index: 100;

    .planner__context-menu-option {
      height: 38px;
      display: flex;
      align-items: center;
      padding: 0px 10px;
      gap: 10px;
      cursor: pointer;

      &:hover {
        background: var(--planner-rightclick-hover);

        p {
        }
      }

      &:first-child {
        border-radius: 10px 10px 0px 0px;
      }

      &:last-child {
        border-radius: 0px 0px 10px 10px;
      }

      &:only-child {
        border-radius: 10px 10px 10px 10px !important;
      }
    }

    p {
      font-size: 14px;
      color: var(--text-2);
      margin-bottom: 0px;
      transition: background var(--transition-duration);
    }
  }

  .user-row-weekends {
    display: flex;
    position: relative;
  }

  .user-row {
    display: flex;
    width: fit-content;
    .user-row-week {
      border-bottom: 1px solid var(--stroke);
      cursor: pointer;
    }
  }

  .project-row,
  .project-row__weekends {
    border-bottom: 1px solid var(--stroke);
    cursor: pointer;
    display: flex;
    width: fit-content;
  }

  .milestone {
    position: absolute;
    cursor: pointer;
    height: 18px;
    border-radius: 16px;
  }

  .milestone-existing {
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    //margin-top: 7px;
    text-align: center;
    padding: 0 5px;
    cursor: pointer;
    overflow: hidden;
    display: flex;

    p {
      color: var(--background-1);
      font-size: 13px;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: auto;
      line-height: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .milestone__hook {
    position: absolute;
    height: 20px;
    z-index: 10;
    background: transparent;
    cursor: col-resize;
    display: block;
  }

  .planner-control-block {
    display: flex;
    justify-content: center;
    border-radius: 7px;
    border: 1px solid var(--stroke);
    height: 100%;
    padding: 10px;
    width: 44px;
    cursor: pointer;
    align-items: center;

    @media (max-width: 1100px) {
      margin: auto 0 !important;
    }

    .MuiSlider-marked {
      margin-bottom: 0;
    }

    .planner-control-block__today-link {
      margin: auto;
      text-decoration: none;
      font-size: 16px;
      line-height: 22px;
      color: $new_blue;
      cursor: pointer;
    }
  }

  .booking-window {
    position: fixed;
    top: 120px;
    bottom: 0;
    width: 360px;
    background-color: var(--background-1);
    right: 0;
    border-radius: 10px 0px 0px 10px;
    //box-shadow: -3px 3px 9px 0px rgba(170, 170, 170, 0.25);
    color: var(--text-2);
    z-index: 10;
    border-top: 1px solid var(--stroke);
    border-left: 1px solid var(--stroke);

    @media (max-width: 1100px) {
      top: 150px;
    }

    &:focus-visible {
      outline: none;
    }

    &:focus {
      outline: none;
    }

    @media (max-width: 640px) {
      width: 100%;
    }

    @media (max-width: 1100px) and (min-width: 641px) and (min-height: 430px) {
      top: 178px;
      height: 80%;
    }

    .MuiCheckbox-root {
      color: #7fa6ce;
    }

    .Mui-checked {
      color: #7fa6ce !important;
    }

    .start-time__block {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .start-time_picker {
      border: 0px;
      border-bottom: 1px solid var(--stroke);
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 24px;
      color: var(--text-2);
      background-color: var(--background-1);

      &:focus {
        border-bottom: 1px solid var(--stroke);
        outline: 0;
      }
      &:focus-visible {
        border-bottom: 1px solid var(--stroke);
        outline: 0;
      }

      &::-webkit-calendar-picker-indicator {
        filter: var(--color-text-2-filter);
      }
    }

    .booking-window__header {
      margin: 20px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;

      p {
        margin: 0;
      }
    }

    .booking-window__booking-data-block {
      position: absolute;
      width: calc(100% - 7px);
      top: 70px;
      bottom: 75px;
      overflow-y: auto;
      overflow-x: hidden;
      right: 7px;
      padding: 0 7px 10px 20px;
      .row {
        margin-right: unset !important;
        margin-left: unset !important;
        .col-md-3,
        .col-md-6,
        .col-md-8,
        .col-md-9 {
          padding: unset !important;
        }
      }

      @media (max-height: 430px) and (orientation: landscape) {
        top: 80px;
      }
    }

    .booking-window__grouped-data-element {
      background-color: var(--background-1);

      margin-bottom: 0px;
      flex-direction: column;

      .beta-image {
        position: absolute;
        right: 20px;
        height: 21px;
      }

      .locationPicker {
        .MuiSlider-thumb {
          width: 12px;
          height: 12px;
          color: var(--header-nav-color);
        }

        .MuiSlider-track {
          color: var(--header-nav-color);
        }

        .MuiSlider-rail {
          color: var(--slider-location);
          height: 1px;
          opacity: 1;
        }

        .MuiSlider-mark {
          color: var(--slider-location);
          width: 12px;
          height: 12px;
          border-radius: 100%;
          transform: translate(-50%, -50%);
        }

        .MuiSlider-markActive {
          color: var(--header-nav-color) !important;
          background: var(--header-nav-color) !important;
        }
      }

      .checkbox {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        line-height: 30px;

        &:last-child {
          margin-bottom: 0px;
        }

        width: 100%;

        div {
          align-items: center;
        }

        p {
          white-space: nowrap;
          margin-left: 15px;
        }

        .col-md-5 {
          padding: unset !important;
        }
      }

      img {
        width: 24px;
        height: 24px;
        cursor: pointer;
        display: flex;
      }

      .teambook-react-select {
        width: 100%;
      }
    }

    .booking-window__project-estimation {
      padding: 0px;
      margin-bottom: 16px;
      @media (max-width: 1100px) {
        flex-wrap: nowrap !important;
      }
    }

    .booking-window__data-element {
      background-color: var(--background-1);
      margin-bottom: 16px;
      justify-content: space-between;
    }

    .booking-window__data-element-text {
      margin: unset;
      align-self: center;
      text-align: left;
      cursor: pointer;
    }

    .booking-window__control-buttons__create {
      height: 80px;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      gap: 20px;
      padding: 20px;
      z-index: 3;
      p {
        margin: 0px;
      }
    }

    .booking-window__data-block__edit {
      width: calc(100% - 7px);
      position: absolute;
      right: 7px;
      top: 70px;
      bottom: 82px;
      overflow-y: overlay;
      overflow-x: hidden;
      padding: 0px 7px 0 20px;
      @media (max-height: 430px) and (orientation: landscape) {
        top: 100px;
      }
      .row {
        margin-right: unset !important;
        margin-left: unset !important;
        .col-md-3,
        .col-md-6,
        .col-md-8,
        .col-md-9 {
          padding: unset !important;
        }
      }
    }

    .booking-window__control-buttons__edit {
      height: 80px;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: var(--background-1);
      display: flex;
      justify-content: space-between;
      gap: 20px;
      padding: 20px;
      z-index: 3;
    }

    .booking-delete-button {
      max-width: 44px !important;
      min-width: 44px !important;
      border-radius: 7px;
      border: 1px solid var(--stroke);
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background: transparent !important;
      transition: var(--transition-duration);
      color: var(--button-outline-text-default) !important;

      &:hover,
      &:active {
        background: var(--stroke) !important;
        border: 1px solid var(--background-2) !important;
      }
    }

    .booking-window__button-save {
      color: #ffffff !important;
      background-color: #9199e7 !important;
      padding: 10px !important;
      width: 90px;
    }

    .booking-window__button-cancel {
      background: #fff !important;
      color: #9199e7 !important;
      border: 1px solid #9199e7 !important;
      box-shadow: 5px 5px 10px rgba(170, 170, 204, 0.5), -5px -5px 10px #ffffff !important;
      border-radius: 4px !important;
      padding: 10px !important;
      width: 90px;
    }

    .booking-window__button-delete {
      color: #4a59ac !important;
      padding: 0 10px !important;
      text-decoration: underline;
      cursor: pointer;
      margin: auto 0;
      align-self: center;
    }

    .booking-window__duration-select {
      input {
        padding: 8px;
        background-color: #f5f9fd !important;
      }
    }

    .booking-window__linear-progress {
      width: 65%;
    }

    .booking-window__repetition {
      align-items: center;
      cursor: pointer;
      justify-content: space-between;
      p {
        white-space: nowrap;
      }
      @media (max-width: 620px) {
        flex-wrap: nowrap !important;
      }
    }

    .booking-window__last-change-info {
      text-align: start;
      font-size: 13px;
      margin-top: 20px;
      white-space: initial;
    }
    .booking-window__last-change-at {
      text-align: start;
      font-size: 14px;
    }

    .holiday-info {
      border-radius: 10px;
      position: relative;
      margin-bottom: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      a {
        color: #a3a3a3;
        margin: 0;
      }

      img {
        cursor: pointer;
        width: 20px;
        height: 20px;
      }
    }
  }
}

.grouped-planner-page {
  .user-row-weekends {
    .user-row-weekends {
      border-bottom: 1px solid var(--stroke);
    }
  }
}

.delete-team-dialog {
  .delete-team-dialog__title {
    border-bottom: 1px solid #d5d9ff;
    p {
      margin-bottom: 0;
    }
  }

  .delete-team-dialog__content {
    width: 600px;
    padding: 12px 24px 20px 24px !important;
    p {
      margin-bottom: 6px;
    }
  }

  .delete-team-dialog__actions {
    gap: 30px;
    padding: 0px 30px 30px 0px;
  }

  .delete-team-dialog__text-field {
    width: 100%;
    .MuiInputBase-input {
      padding: 10px !important;
    }
  }
}

.arrow-block__date-picker-container {
  //box-shadow: 0px 0px 7px 3px rgba(156, 156, 156, 0.3);
  border: 1px solid var(--stroke);
  border-radius: 7px;
}

.arrow-block__date-picker-container.borderless {
  border: unset !important;
}

.actuals-modal {
  .MuiPaper-root {
    width: 600px;
    border-radius: 10px;
  }

  .actuals-modal__title {
    display: flex;
    justify-content: space-between;
    border: 1px solid #d5d9ff;
  }

  .actuals-modal__content {
    margin-top: 20px;
  }
}

.planner-settings-dialog__title {
  display: flex;
  justify-content: space-between;
  border: 1px solid #d5d9ff;
}

.users_planner-settings {
  width: 490px;
  margin: 20px 0px;

  &[language="it"] {
    width: 530px;
  }
}

.planner-settings-dialog {
  box-shadow: 0 0 20px 1000px rgba(0, 0, 0, 0.25);

  .MuiPaper-root {
    width: 400px;
    border-radius: 10px;
  }

  .planner-settings-dialog__content {
    margin-top: 20px;
  }

  .planner-settings-dialog__setting {
    display: flex;
    justify-content: space-between;
    height: 50px;
  }

  .planner-settings-dialog__setting-text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    white-space: nowrap;
  }

  .planner-settings-dialog__setting-control {
    width: 40px;
    display: flex;
    justify-content: center;
    margin-right: 15px;
  }
}

.planner__create-user-dialog {
  .tb-react-select__single-value {
    color: var(--text-2) !important;
  }
}

.planner-add-user-dialog {
  .add-user__dialog-actions {
    gap: 15px;
  }
}

.grouped-planner-grid {
  .user-avatar {
    z-index: 5;
  }
}

.planner-grid {
  .user-avatar {
    z-index: 4 !important;
  }

  // .date-row__date-row {
  //   z-index: 7 !important;
  // }

  // .date-row__month-row {
  //   z-index: 6 !important;
  // }

  .date-row__month-filter-cell {
    z-index: 4 !important;
  }

  .month-separator-line {
    z-index: 4;
  }
}

.month-separator-line {
  width: 1px;
  position: absolute;
  background: var(--text-12);
  z-index: 6;
  top: 0px;
}

.add-user-row {
  width: 40px;
  height: 40px;
  align-self: center;
  border: 1px solid var(--stroke);
  border-radius: 7px;
  padding: 10px;
  background-color: var(--background-1);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  .add-user-row__add-button {
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .add-user-row__add-text {
    color: var(--text-2);
    margin: unset;
    font-family: Montserrat;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
  }
}

.planner-control-panel {
  position: fixed;
  display: flex;
  bottom: 25px;
  margin: auto;
  left: 0px;
  right: 0px;
  width: fit-content;
  height: 60px;
  background-color: grey;
  border-radius: 10px;
  opacity: 0.5;
  z-index: 6;
  padding: 0px 20px;

  @media (max-width: 600px) {
    height: 75px;
    opacity: 0.9;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding: 0px 15px;
  }

  &:hover {
    opacity: 1;
  }

  .planner-control-panel__option {
    margin: 0 10px;
    padding: 10px 0px;
    background-color: grey;
    cursor: pointer;
    border-radius: 10px;
    @media (max-width: 600px) {
      width: 22%;
      margin: 0px;
      padding: 5px 0px;
    }

    &:hover {
      background-color: #474747;
    }

    p {
      font-size: 14px;
      color: white;
      margin: unset;
      word-break: break-word;
      @media (max-width: 600px) {
        font-size: 11px;
      }
    }
  }

  .planner-control-panel__setting-icon {
    width: 24px;
    height: 24px;
    @media (max-width: 700px) {
      width: 24px;
      height: 24px;
    }
  }

  .planner-control-panel__team-name {
    @media (max-width: 600px) {
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    @media (max-width: 800px) {
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}

.planner-scroll-list {
  padding-bottom: 1px;
}

.planner-grid,
.grouped-planner-grid {
  border: 1px solid var(--stroke);
  height: fit-content;
  max-height: calc(100% - 120px);
  border-radius: 7px;

  margin-right: 30px;
}

.avatar-cell-container {
  border-left: unset !important;
}

.planner-control-panel__copy-information {
  position: fixed;
  display: flex;
  top: 120px;
  left: 50%;
  transform: translateX(-50%);
  height: 40px;
  background-color: grey;
  border-radius: 10px;
  opacity: 0.95;
  padding: 4px 10px;
  z-index: 50;
  width: fit-content;

  p {
    font-size: 24px;
    color: white;
  }

  .info-text {
    font-size: 14px;
    margin: 5px 20px;
  }

  .cancel-button {
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
    margin: 5px 20px 5px 0;
  }
}

.booking-window__empty_timeoffs {
  display: flex;
  margin: 10px 20px;
  text-align: left;
  p {
    margin: 0px;
  }

  a {
    margin: 0px;
    padding: 0px;
  }
}

.booking-window__select-component {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 0px 0px 10px 0px;

  @media (max-height: 430px) and (orientation: landscape) {
    height: 35px;
  }
}

.planner-control-bar__action-buttons {
  display: flex;
  gap: 12px;
}

.planner-page__date-container {
  display: flex;
  align-items: center;
  gap: 20px;
}

.planner-control-bar__action-buttons,
.control-bar__left-side,
.actuals__control-bar,
.filter-row__right-side {
  .disabled {
    pointer-events: none;
    position: relative;
    border: 1px solid var(--background-2) !important;
    border-radius: 8px;

    &::after {
      content: "";
      width: 100%;
      height: 100%;
      background: var(--background-2);
      opacity: 0.8;
      position: absolute;
      z-index: 100;
      left: 0px;
      top: 0px;
      border-radius: 7px;
    }
  }
}

.booking-window__project-type-option {
  width: 195px;
  background: var(--background-1);
  cursor: pointer;
  border: 1px solid var(--stroke);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  gap: 12px;

  p {
    color: var(--text-2);
    align-self: center;
    font-size: 16px;
    margin: 0;
    font-weight: 500;
    letter-spacing: 0.4px;
  }
}

.no-users-planner {
  left: 0px;
  padding: 0px 0px 0px 100px;
  width: 100%;

  .no-users-planner__text {
    text-align: left;
    font-size: 28px;
    color: var(--text-1);
    letter-spacing: 0.4;
  }

  button {
    min-width: 145px !important;
    display: flex;
    align-self: flex-start;

    p {
      text-transform: capitalize;
    }
  }

  img {
    position: absolute;
    left: 1100px;
    top: 135px;

    @media (max-width: 1550px) {
      left: unset;
      right: 45px;
    }

    @media (max-height: 850px) {
      top: 45px;
    }
  }
}

.booking-window__project-type-option.option-active {
  background: var(--stroke);

  p {
    font-weight: 500;
    color: var(--text-1);
  }
}

.weekend-info__day-header {
  text-align: left;
}

.did-you-know__card {
  text-align: left;
  color: var(--text-2);
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 50px;
  bottom: 50px;
  z-index: 500;
  width: 400px;
  height: auto;
  padding: 20px;
  background-color: var(--background-1);
  border: 2px solid var(--stroke);
  border-radius: 10px;
  -webkit-box-shadow: 3px 3px 9px 0px var(--box-shadow);
  box-shadow: 3px 3px 9px 0px var(--box-shadow);

  a {
    color: var(--link-color);
  }

  h3 {
    font-size: 18px;
    margin-bottom: 20px;
  }

  p {
    font-size: 14px;
    text-align: justify;
  }

  button {
    white-space: nowrap;
    transition: 0.5s;
    font-size: 12px;
    background-color: transparent;
    border: 1px solid var(--stroke);
    border-radius: 100px;
    padding: 5px 7px;
    width: 240px;
    display: flex;
    align-items: center;
    gap: 5px;
    color: var(--text-1);

    .arrow {
      &::before {
        border-color: var(--text-1);
      }
      &::after {
        border-color: var(--text-1);
      }
    }

    &:hover {
      cursor: pointer;
      transition: 0.5s;
      background-color: var(--accent-dark);
      color: #fff;

      .arrow {
        margin-left: 38px;
        transition: 0.5s;

        &::before {
          border-color: #fff;
        }
        &::after {
          border-color: #fff;
        }
      }
    }
  }

  .did-you-know__close-button {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 32px;
    height: 32px;
    opacity: 0.3;

    &:hover {
      opacity: 1;
      cursor: pointer;
    }

    &:after,
    &:before {
      position: absolute;
      left: 15px;
      content: " ";
      height: 20px;
      width: 2px;
      background-color: var(--button-outline-text-default);
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }

  .did-you-know__arrow {
    width: 10px;
    height: 10px;
    margin-left: 30px;
    margin-right: 10px;
    display: inline-block;
    position: relative;
    transition: 0.5s;

    &::before {
      content: "";
      transition: 0.5s;
      display: block;
      width: 10px;
      height: 10px;
      top: 145%;
      left: 280%;
      border-style: solid;
      border-color: #000;
      border-width: 1px 1px 0 0;
      position: absolute;
      transform-origin: 50% 50%;

      transform: rotate(45deg);
      margin: -15px 0 0 -20px;
    }

    &::after {
      content: "";
      transition: 0.5s;
      display: block;
      top: 50%;
      left: 50%;
      border-style: solid;
      border-color: #000;
      position: absolute;
      transform-origin: 50% 50%;

      width: 40px;
      height: 0;
      border-width: 1px 0 0 0;
      transform: translate(-26px, -1px);
      &:hover {
        border-color: red;
      }
    }
  }
}

.planner-multiple-select-help {
  position: fixed;
  bottom: 125px;
  padding: 7px 15px;
  margin: auto;
  border-radius: 5px;
  color: white;
  background-color: grey;
  left: 0;
  right: 0;
  width: fit-content;
}

.planner-type-select {
  margin-top: 8px;
  margin-right: 30px;
  width: 160px;
  height: 40px;
  margin-bottom: auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  border-radius: 7px;
  border: 1px solid #c8d2e6;
  color: #4c54a0;
  font-size: 16px;

  line-height: 12px;

  img {
    width: 18px;
    height: 18px;
    margin-right: 5px;
  }

  p {
    margin: 0;
  }

  .hide-icon {
    cursor: pointer;
  }

  @media (max-width: 600px) {
    padding: 3px;
    font-size: 0.7rem;
    width: 20%;
  }
}

.milestone-dialog {
  .MuiDialogContent-root {
    overflow: hidden !important;
  }

  .tb-date-picker__start-container {
    font-weight: 500;
  }
}

.planner-type-select__dropdown-option {
  background-color: #d5d9ff;
  color: #474747;
  font-size: 14px;
  width: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    p {
      font-size: 16px;
    }
  }

  p {
    margin: 5px 0;
  }
}

.planner-type-select__shown-component {
  height: 40px;
  display: flex;
  color: var(--background-1);
  right: 10px;
  width: fit-content;
  border-radius: 7px;
  overflow: hidden;
  border: 1px solid var(--stroke);

  a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .hide-icon {
    cursor: pointer;
    color: var(--text-2);
    height: 40px;
    padding-top: 10px;
  }
}

.export-booking-dialog {
  .MuiPaper-root {
    width: 360px;
    height: 380px;
  }
  img {
    cursor: pointer;
  }
  .export-booking-dialog__title {
    p {
      margin-bottom: 0px;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #d5d9ff;
    margin-bottom: 20px;
  }

  .export-booking-dialog__field-name {
    margin: 0.25rem 0 0.25rem 0;
  }

  .export-booking-dialog__specify-parameters {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;

    p {
      color: #333333;
      margin-right: 40px;
    }

    img {
      height: 24px;
    }
  }
}

.add-user-dialog__select-container {
  .tb-react-select-multi__control {
    margin: 0 auto !important;
    height: 50px !important;
  }
}

.select__menu-portal {
  z-index: 1300 !important;
}

.booking-repetition {
  padding: 0;
  display: flex;
  align-items: center;
  margin-right: 5px !important;
  gap: 10px;
  height: 45px;
  position: relative;

  .MuiInputBase-root {
    color: var(--text-2);
    border-bottom: none;

    &:before {
      border-bottom: 1px solid var(--text-2);
    }

    &:after {
      border-bottom: 1px solid var(--text-2);
    }

    &:hover {
      &:before,
      &:after {
        border-bottom: 1px solid var(--text-2) !important;
      }
    }
  }

  .MuiOutlinedInput-root {
    width: 80px;
  }

  p {
    margin: 0;
    white-space: nowrap;
  }

  .ignore-weekends {
    word-wrap: break-word;
    width: 80%;
    text-align: start;
    white-space: unset;
    cursor: pointer;
  }

  .MuiCheckbox-root {
    color: #7fa6ce;
  }
  .Mui-checked {
    color: #7fa6ce !important;
  }
}

.edit-user__value__select {
  margin: 15px 0 10px 0;
}

.planner-user-creating__field-name {
  margin-bottom: 5px;
}

.planner-month-name {
  margin: unset;
  font-size: 0.9rem;
  color: var(--text-2);
  position: absolute;
  bottom: 45px;
  left: 10px;
  padding-top: 6px;
}

.manage-team-dialog {
  .MuiDialogContent-root {
    padding: 0px;
    overflow: overlay;
  }

  .MuiPaper-root {
    height: 400px;
    width: 450px;
  }

  .manageteam__icon {
    width: 24px !important;
    height: 24px !important;
  }
  .creation {
    height: 84px !important;
  }
  .editing {
    height: 84px !important;
  }

  .manage-team-dialog__team-actions {
    display: flex;
    align-items: center;
    gap: 5px;
  }
}

.manage-team-dialog__team-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 44px;
  margin-bottom: 10px;
  width: 100%;

  .input-field {
    background: #ffffff;
    color: var(--text-2);

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      opacity: 1; /* Firefox */
    }
  }

  .manage-team-dialog__team-text {
    display: flex;
    flex: 1;
    gap: 20px;

    p {
      font-size: 16px;
      margin: 10px;
      width: 50px;
      text-align: left;

      &:nth-child(1) {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin: 10px 10px 10px 16px;
        width: 174px;
        letter-spacing: -0.408px;
      }
    }
  }

  .manage-team-icon {
    padding: 8px 10px;
    border: 1px solid var(--stroke);
    border-radius: 7px;
    max-width: 44px;
    max-height: 40px;
  }

  .MuiInputBase-input {
    padding: 10px 10px;
    &::placeholder {
      opacity: 50%;
    }
  }

  .MuiFormControl-root {
    background-color: var(--background-2) !important;
  }

  @media (max-width: 640px) {
    line-height: 1rem;
  }
  img {
    cursor: pointer;
  }

  &.editing {
    gap: 20px;
  }
}

.creation .manage-team-dialog__editing-mode-text,
.editing .manage-team-dialog__editing-mode-text {
  font-size: 16px;
  margin: 10px;
  width: 46px;
}

.onboarding {
  width: 360px;
  position: absolute;
  right: 0;
  bottom: 0;
  top: 120px;
  background-color: var(--background-1);
  border-radius: 20px 0px 0px 20px;
  z-index: 10;

  border: 1px solid var(--stroke);
  @media (max-width: 600px) {
    width: 100%;
  }

  .onboarding__header {
    width: 100%;
    font-size: 20px;
    color: var(--button-outline-text-default);
    font-weight: 500;
    border-bottom: 1px solid var(--stroke);
    padding: 20px 10px;
    margin: 0px;
  }

  .onboarding__content {
    position: absolute;
    top: 80px;
    bottom: 80px;
    overflow-y: auto;

    img {
      width: 20px;
      height: 20px;
    }
  }

  .onboarding__footer {
    color: var(--button-outline-text-default);
    padding: 16px 0 14px 0;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
  }

  .onboarding_footer-button {
    border: 1px solid var(--stroke) !important;
    color: var(--button-outline-text-default) !important;
    background-color: var(--stroke) !important;
    font-size: 0.9rem !important;
    text-transform: unset !important;
    margin-top: 10px !important;
    margin-left: 15px !important;
  }

  .point {
    font-size: 12px;
    margin: 10px 10px 20px 20px;
    text-align: left;
    display: flex;

    .point__header {
      font-size: 16px;
      color: var(--button-outline-text-default) !important;
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 6px;
      cursor: pointer;
      font-weight: 500;
      line-height: 120%;
      white-space: pre-line;
    }

    .point__header-without-link {
      font-size: 16px;
      font-size: 1rem;
      color: var(--button-outline-text-default) !important;
      font-weight: 500;
      display: flex;
      align-items: center;
      gap: 10px;

      margin-bottom: 5px;
    }

    b {
      color: var(--text-2);
    }

    .point__text {
      font-size: 12px;
      margin: 0px 0px 0px 30px;
      color: var(--text-2);
      line-height: 130%;
      white-space: pre-line;
    }
  }

  .onboarding-video.general-video {
    background-color: var(--stroke);
    cursor: pointer;
    padding: 10px;
    border-radius: 7px;

    .point__header {
      text-decoration: underline;
      text-underline-offset: 2px;
      text-transform: uppercase;
    }
  }

  .actuals-video {
    cursor: pointer;
    padding-left: 10px;

    .point__header {
      text-decoration: underline;
      text-underline-offset: 2px;
      text-transform: uppercase;
    }
  }

  .capacity-video {
    cursor: pointer;
    padding-left: 10px;

    .point__header {
      text-decoration: underline;
      text-underline-offset: 2px;
      text-transform: uppercase;
    }
  }
}

.weekend-info__container {
  width: fit-content;
  min-width: 190px;
  max-width: 410px;
  max-height: 465px;
  height: fit-content;
  position: absolute;
  background: var(--background-1);
  border-radius: 10px;
  padding: 20px 48px 0px 20px;
  border: 1px solid var(--stroke);
  z-index: 1000;
  overflow: hidden;
  box-shadow: var(--box-shadow);
  display: flex;
  flex-direction: row;
  gap: 33px;

  .weekend-info__container-day {
    display: flex;
    flex-direction: column;
  }

  .weekend-info__day-header {
    display: flex;
    text-transform: uppercase;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;

    p {
      color: var(--text-1);
      font-size: 14px;
      margin: 0px;
    }
  }

  .weekend-info__bookings-list {
    display: flex;
    flex-direction: column;
    max-width: 320px;
    padding-right: 7px;
    gap: 20px;
    height: fit-content;
    padding-bottom: 10px;
    overflow-y: auto;
    overflow-x: hidden !important;
    max-height: 190px;
    &::-webkit-scrollbar-track {
      width: 6px !important;
      height: 6px !important;
    }

    &::-webkit-scrollbar {
      width: 6px !important;
      height: 6px !important;
    }

    &::-webkit-scrollbar-thumb {
      width: 6px !important;
      height: 6px !important;
    }
    overflow-x: unset;
  }

  .weekend-info__booking {
    min-height: 80px;
    height: 80px;
    min-width: 149px;
    max-width: 149px;
    display: flex;
    gap: 8px;
    position: relative;
  }

  .weekend-info__project-color {
    min-width: 5px;
    width: 5px;
    height: 100%;
    border-radius: 2px;
  }

  .weekend-info__project-name {
    color: var(--text-2);
    font-size: 14px;
    max-width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .weekend-info__booking-duration {
    color: var(--text-2);
    font-size: 14px;
    margin-left: auto;
    opacity: 0.7;
  }

  .weekend-info__booking-actions {
    position: absolute;
    bottom: 0px;
    right: 0px;
    display: flex;
    gap: 20px;
  }

  .weekend-info__booking-button {
    padding: 6px 8px 6px 8px;
    min-width: 16px;
    min-height: 16px;
    border: 1px solid var(--stroke);
    border-radius: 5px;
  }

  .weekend-info__create-booking-button {
    border-bottom: 1px solid var(--text-1);
    min-width: 110px;
    font-size: 12px;

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    margin: 12px auto 20px auto;
    gap: 4px;

    p {
      color: var(--text-1);
      margin-bottom: 0;
      white-space: nowrap;
    }
  }
}

.project-selection {
  width: 100%;
  margin-bottom: 20px;

  #selectProject {
    margin-bottom: 0 !important;
  }

  .teambook-options-line__option {
    height: 28px;
  }
}

.tooltip-component {
  position: fixed;
  z-index: 22;
  display: none;

  .tooltip-component__block {
    height: 92px;
    width: 192px;
    border-radius: 7px;
    padding: 7px 8px 7px 8px;
    background-color: var(--background-1);
    border: 1px solid var(--stroke);
    box-shadow: 5px 10px 30px var(--box-shadow);
    .tooltip-component__icon {
      width: 10px;
      height: 10px;
    }
  }

  p {
    color: var(--text-2);
    margin: 0;
    font-size: 10px;
    text-align: start;
    white-space: nowrap;
  }

  .tooltip-component__project-name {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 151px;
    font-weight: 500;
    text-transform: uppercase;
  }

  .tooltip-component__task-name,
  .tooltip-component__comment,
  .tooltip-component__team,
  .tooltip-component__client-name {
    display: flex;
    align-items: center;
    gap: 2px;
    height: 12px;
    margin-bottom: 4px;

    p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .tooltip-component__team {
    z-index: 12;
  }

  .tooltip-component__triangle {
    position: absolute;
    bottom: -11px;
    left: 18px;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 12px solid var(--background-1);
    z-index: 10;
  }

  .tooltip-component__triangle_bg {
    position: absolute;
    bottom: -12px;
    left: 18px;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 12px solid var(--stroke);
    transform: scale(1.11);
  }

  .tooltip-component__stub {
    width: 14px;
    height: 7px;
    position: absolute;
    background: var(--background-1);
    z-index: 11;
    left: 18px;
    bottom: 1px;
  }
}

.edit-user-window {
  color: red;
  .edit-user-window__title {
    margin: 0;
  }
  .edit-user-window__dialog-content {
    &::-webkit-scrollbar {
      width: 0;
    }
    width: 500px;
    @media (max-width: 620px) {
      width: auto;
    }
  }

  .edit-user-window__value__text,
  .edit-user-window__value__select {
    margin: 10px 0px;

    .field-name {
      margin-bottom: 5px;
    }
  }

  .edit-user-window__value__text {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .input-form-component {
      margin: 0px;
    }

    .MuiOutlinedInput-input {
      padding: 12px 14px;
    }
  }

  .edit-user-window__value__select {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    text-align: left;
    justify-content: space-between;

    .edit-user-window__select-form {
      .MuiInputBase-root {
        width: 100%;
      }
    }

    .MuiOutlinedInput-input,
    .MuiOutlinedInput-adornedEnd {
      padding: 12px 14px;
    }

    .MuiFormControl-root {
      margin: 0 !important;
      max-width: 60% !important;
    }

    .edit-user-window__select {
      .MuiSelect-select {
        max-width: unset;
      }
    }
  }
}

.grouped-planner-page {
  .user-avatar {
    left: 0;
    z-index: 7 !important;
  }

  .hours-left-component {
    left: 120px;
  }
}

.milestone-editing {
  .MuiPaper-root {
    min-width: 530px;
  }
}

.milestone-creating {
  .MuiPaper-root {
    width: 530px;
  }

  .milestone-creating__title {
    padding: 20px 20px 20px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #d5d9ff;
    @media (max-width: 640px) {
      padding: 20px 24px 20px 15px;
    }

    img {
      cursor: pointer;
      width: 24px;
      height: 24px;
      margin-left: auto;
    }

    p {
      font-size: 1.3rem;
      margin: 0px;
    }
  }

  .milestone-creating__content {
    padding: 15px 0px !important;
  }

  .milestone-creating__info-milestone {
    margin-top: 30px;
    padding: 0px 20px !important;
    @media (max-width: 640px) {
      padding: 0px 15px !important;
    }
    p {
      margin-bottom: 20px;
    }
  }

  .milestone-creating__creating {
    .MuiInputBase-root {
      height: 40px;
      fieldset {
        border-color: #d5d9ff;
      }
    }
    padding: 0px 20px !important;
    border-bottom: 1px solid #d5d9ff;
    @media (max-width: 640px) {
      padding: 0px 15px !important;
    }
  }

  .milestone-creating__actions {
    padding: 8px 20px 8px 8px;
  }
}

.milestone-creating__text-row {
  width: 100%;
  gap: 54px;
  display: flex;
}

b {
  font-weight: 500;
}

.milestone-date-picker__container {
  width: 210px;
  display: flex;
  gap: 10px;
  align-items: baseline;

  p {
    margin: 0;
    font-size: 14px;
  }

  .MuiInputBase-root {
    color: var(--text-2);
    border-bottom: none;

    &:before {
      border-bottom: 1px solid var(--text-2);
    }

    &:after {
      border-bottom: 1px solid var(--text-2);
    }

    &:hover {
      &:before,
      &:after {
        border-bottom: 1px solid var(--text-2) !important;
      }
    }
  }

  .inline_picker {
    border-bottom: unset !important;
    text-decoration: underline;
  }
}

.milestone-editing {
  .milestone-editing__title {
    padding: 20px 20px 20px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #d5d9ff;
    @media (max-width: 640px) {
      padding: 20px 24px 20px 15px;
    }

    img {
      cursor: pointer;
      width: 24px;
      height: 24px;
      margin-left: auto;
    }

    p {
      font-size: 1.3rem;
      margin: 0px;
    }
  }

  .milestone-editing__content {
    padding: 15px 0px !important;

    .milestone-editing__editing {
      .MuiInputBase-root {
        height: 40px;
        fieldset {
          border-color: #d5d9ff;
        }
      }
      padding: 0px 20px !important;
      border-bottom: 1px solid #d5d9ff;
      @media (max-width: 640px) {
        padding: 0px 15px !important;
      }
    }
    .milestone-editing__info {
      margin-top: 30px;
      padding: 0px 20px 60px 20px !important;
      @media (max-width: 640px) {
        padding: 0px 15px !important;
      }
      p {
        margin-bottom: 0px;
      }
    }
  }
}

.milestone-editing__textfield {
  width: 100%;
  display: flex;
  gap: 49px;
}

.project-group__group-row {
  display: flex;
  width: fit-content;
  position: relative;
  height: 50px;

  .project-group__project-container {
    min-width: 102px;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .project-group__project-circle {
    width: 8px;
    height: 8px;
    border-radius: 2px;
  }

  .project-grouped__project-name {
    font-size: 14px;
  }
}

.project-group__cell {
  height: 50px;
  gap: 10px;
  user-select: text;
  background: var(--background-1);
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
  border-right: unset !important;

  p {
    font-size: 14px;
    line-height: 16.8px;
    color: var(--text-2);
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  img {
    margin-right: 10px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}

.project-group__client-holder.date-row {
  border-top: 1px solid var(--stroke);
}

.project-group__client-holder {
  width: 50px;

  position: sticky;
  left: 0;
  background-color: var(--background-1);

  z-index: 10;
  color: var(--text-2);

  &.date-row {
    border-bottom: 1px solid var(--stroke);
  }
}

.project-group__milestone-hint {
  position: absolute;
  top: 15px;
  cursor: pointer;
  width: max-content;
  font-size: 14px;
  color: var(--text-2) !important;
  font-family: Montserrat;
}

.MuiButton-root {
  text-transform: none !important;
}

#create-window .MuiSelect-root {
  display: flex !important;
}

#create-window .repetition-every .MuiSelect-root {
  padding: 10px 5px;
}

#create-window .repetition-after .MuiInputBase-input {
  padding: 10px 5px;
}

#edit-window .MuiSelect-root {
  display: flex !important;
}

.repetition-select__menu ::-webkit-scrollbar {
  padding-right: 4px;
  width: 6px;
  border-radius: 10px;
}

.repetition-select__control {
  margin-right: 9px !important;
}

.repetition-select__menu-list {
  padding: 7px 10px !important;
  gap: 5px;
  display: flex;
  flex-direction: column;
}

.repetition-select__option {
  border-radius: 4px;
  cursor: pointer !important;
}

.repetition-select__menu ::-webkit-scrollbar-thumb {
  background: red;
  border-radius: 10px;
}

.repetition-select__indicator-separator {
  display: none;
}

.planner-grid,
.grouped-planner-grid {
  position: relative;
  top: 120px;
  bottom: 0;
  right: 0;
  overflow: hidden !important;
  user-select: none;
  display: flex;
  flex-direction: column;
}

.date-cell-header-90 {
  width: 20px;
}

.create {
  background-color: #dd2c00;
}
.user-more-dropdown {
  position: absolute;
  bottom: 0;
  left: 0;
  background: #000000;
  color: #fff;
  width: 100%;
  font-weight: 400;
  font-family: Montserrat;
  opacity: 0.85;
}

button:focus {
  outline: none;
}

.filter-row__buttons button:hover span {
  opacity: 1;
  background: transparent;
}

.remove-user-item {
  font-size: 0.9rem;
  cursor: pointer;
  padding: 10px;
  opacity: 0.85;
}

.remove-user-item:hover {
  opacity: 1;
}

.date-cell-header__week-number {
  position: absolute;
  display: flex;
  justify-content: center;
  left: 0px;
  bottom: 3px;
  color: var(--text-2);
  margin: 0px;
  text-align: center;
  font-family: Montserrat;
  font-size: 10px;
  font-style: italic;
  font-weight: 400;
  line-height: 120%; /* 12px */
  letter-spacing: 0.4px;
  text-transform: uppercase;
  z-index: 3;
  text-align: left;
}

.filter-row .MuiInput-underline {
  text-align: left;
}

.users-list {
  display: flex;
  padding: 7px;
  justify-content: flex-start;
  align-items: center;
}

.users-list:hover {
  background: lightgrey;
  cursor: pointer;
}

.planner-info-modal__dialog {
  .MuiPaper-root {
    width: 620px;
    min-width: 620px;
    max-width: 620px;
    height: 310px;
  }

  .MuiDialogContent-root {
    padding-right: 14px;
    margin-right: 10px;
  }

  .MuiDialogTitle-root {
    padding: 30px;
    margin: 0px;
    border: 0px;
  }

  .MuiDialogActions-root {
    padding-top: 30px;
    padding-right: 30px;
  }

  .planner-info-modal__user-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    p {
      margin-bottom: 0px;
    }
  }

  .planner-info-modal__arrow {
    margin-left: auto;
    width: 24px;
    height: 24px;
  }

  .planner-info-modal__group-header {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 10px;
  }

  .planer-info-modal__user-name {
    max-width: 170px;
    width: 170px;
    min-width: 170px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
  }

  .planner-info-modal__days {
    max-width: 15%;
    font-size: 14px;
  }

  .planner-info-modal__days-data {
    .planner-info-modal__day-info {
      display: flex;
      justify-content: flex-end;
      text-align: start;
      margin-right: 35px;
      font-size: 14px;
      margin-bottom: 10px;
      gap: 10px;
    }

    .planner-info-modal__booking-date {
      width: 110px;
      text-align: right;
      font-feature-settings: "liga" off, "clig" off;
      display: flex;
      align-items: center;
      gap: 5px;

      &::before {
        content: "●";
        line-height: 14px;
        display: flex;
        align-items: center;
        font-size: 6px;
      }
    }

    .planner-info-modal__booking-message {
      width: 135px;
    }
  }
}

.users-list div {
  margin-left: 0 !important;
  margin-bottom: 0 !important;
  margin-right: 7px;
}

.users-list p {
  margin-bottom: 0;
}

.filter-row #planner-filters {
  display: flex;
}

.filter-row .MuiAutocomplete-root {
  width: 100%;
}

.selected-planning {
  color: #fff;
  position: relative;
  justify-content: space-between;
  align-items: center;
  display: flex;
  width: max-content;
  margin: 3px;
  border-radius: 12.5px;
}

.selected-planning .MuiChip-root {
  height: 28px !important;
}

.MuiAutocomplete-tag {
  margin: 0 !important;
  max-width: max-content !important;
}

.selected-planning .MuiChip-outlined {
  color: #fff;
}

.selected-planning .MuiChip-outlined svg {
  fill: #fff;
}

.selected-planning .close-icon {
  color: #4c549f;
  position: absolute;
  background: white;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  padding: 0;
  top: 3px;
  right: 0px;
  border-left: none;
}

.selected-planning .close-icon svg {
  width: 11px;
  height: 11px;
}

.planning-popper .MuiAutocomplete-listbox {
  display: flex;
}

.group-wrapper {
  width: 20%;
  height: auto;
  padding: 0 10px 10px 0;
}

.group-wrapper li {
  font-size: 0.9rem;
  opacity: 0.7;
  padding-top: 0;
  padding-right: 10px;
  padding-left: 10px;
}

.group-wrapper svg {
  width: 20px;
  height: 20px;
  fill: #212529;
}

.group-wrapper .advanced-icon svg {
  fill: #212529;
}

.group-wrapper .clients-icon svg {
  fill: #212529;
}

.group-wrapper .users-icon svg {
  fill: #212529;
}

.group-wrapper .projects-icon svg {
  fill: #212529;
}

.group-wrapper .tags-icon svg {
  fill: #212529;
}

.option-title {
  margin-top: 2px;
  margin-left: 5px;
}

.group-title {
  color: #fff;
  padding: 5px;
  text-transform: uppercase;
  font-size: 0.9rem;
  font-weight: 600;
}

.MuiTypography-h4 {
  font-size: 1.3rem !important;
}

.date-cell-header-28 {
  min-width: 72px;
  max-width: 72px;
  width: 72px;
}

.date-cell-header-14 {
  min-width: 140px;
  max-width: 140px;
  width: 140px;
}

.Group > div:nth-child(2) {
  display: none;
}

.no-bookings-filtered {
  top: 210px;
  width: 100%;

  img {
    position: absolute;
    top: 310px;
    left: 800px;

    @media (max-height: 800px) {
      top: 240px;
      left: 730px;
    }
  }

  p {
    position: absolute;
    top: 240px;
    left: 120px;
    font-size: 20px;
    margin: 20px 0;
    color: var(--text-2);
  }
}

.no-users-team {
  position: absolute;
  top: 160px;
  width: 100%;

  img {
    width: 18%;
  }

  p {
    font-size: 20px;
    margin: 20px 0;
    color: var(--text-1);
  }
}

.planner-filter-row__multi-value {
  .label-icon {
    width: 15px;
    display: flex;
    align-items: center;
  }

  .planner-filter-row__option-icon {
    width: 12px !important;
    height: 12px !important;
    margin: 0px 1px;
  }

  p {
    color: var(--text-9);
  }
}

.planner-filter-row {
  font-family: Montserrat;
  padding: 0px 5px;
  display: flex;
  z-index: 7;
  margin: 0;
  align-items: center;
  border: 1px solid var(--stroke);
  border-radius: 7px;
  flex: 1;
  width: 100%;
  // justify-content: end;

  .planner-control__filter-icon {
    position: absolute;
  }

  .planner-filter-row__option-icon {
    width: 18px;
    height: 18px;
  }

  .planner-filter-row__option--is-focused {
    background: unset;
  }

  .advanced-icon {
    opacity: 0.75;
  }

  .planner-filter-row__filter-copmonent {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    //margin-left: 30px;

    @media (max-width: 1100px) {
      width: 100%;
    }

    img {
      cursor: pointer;
    }
  }

  @media (max-width: 1100px) {
    padding: 5px 0px 0px 0px;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }

  .planner-filter-row__option-value-container {
    display: flex;
    width: 100%;
  }

  .planner-filter-row__menu-list {
    .planner-filter-row__option-value {
      font-size: 14px;
    }
  }

  .planner-filter-row__select {
    width: 100%;

    .planner-filter-row__control {
      border-radius: 7px;
      caret-color: var(--cursor-color);
      background: var(--background-1);
      max-width: 100%;

      input {
        color: var(--text-2) !important;
      }
    }
  }

  .planner-filter-row__indicators {
    display: none;
  }

  .planner-filter-row__control {
    border: 0;
    box-shadow: none;
  }

  .planner-filter-row__option {
    color: #000;
    word-break: break-word;
    padding: 6px;
  }

  .planner-filter-row__menu {
    margin-top: 8px;
    background: var(--background-1);
    border: 1px solid var(--stroke);
    max-width: 1000px;

    @media (min-width: 1100px) and (max-width: 1366px) {
      width: fit-content;
    }

    @media (max-width: 1100px) and (min-width: 640px) {
      height: 100vh;
      width: 50%;
      margin-top: 15px;
    }

    @media (max-width: 1100px) {
      left: unset;
    }

    @media (max-width: 660px) {
      left: 0;
      width: 100%;
      min-height: 100vh;
      position: absolute;
      border: none;
      padding-bottom: 150px;
      margin-top: 2px;
    }
  }

  .planner-filter-row__menu-list {
    display: flex;
    flex-direction: row;
    padding: 25px 10px 0px 10px;
    gap: 15px;

    @media (max-width: 1100px) and (min-height: 640px) {
    }

    @media (max-width: 1100px) {
      flex-direction: column;
      padding: 20px 0px 5% 0px;
      overflow: scroll;
      gap: 0px;
      justify-content: flex-start;
      height: 100%;
    }
  }

  .planner-filter-row__group {
    margin-left: unset;

    .label-icon {
      display: flex;
      align-items: center;
    }

    @media (max-width: 1100px) {
      width: 75%;
      margin-left: 30px;
    }
  }

  .planner-filter-row__group-heading {
    border-radius: 15px;
    font-size: 14px;
    font-weight: 500;
    color: var(--background-1);
    text-align: left;
    margin-bottom: 20px;
    padding: 3px 10px 3px 10px;
    max-height: 50px;
    font-family: Montserrat;
    text-transform: capitalize;
    white-space: nowrap;

    @media (max-width: 1100px) {
      border-radius: 0px;
    }
  }

  .planner-filter-row__multi-value {
    padding: 0 10px 0 10px;
    height: 24px;
    color: var(--text-2);
    border-radius: 12.5px;
    background: var(--background-2);
    border-radius: 20px;
    min-width: fit-content;
    align-items: center;
  }

  .planner-filter-row__multi-value__label {
    color: var(--text-2);
    background: var(--background-2);
    border-radius: 12.5px;
    padding: 0px;
    font-size: 0.8125rem;
  }

  .planner-filter-row__option-value {
    font-family: Montserrat;
    margin: 0;
    text-align: left;
    margin: 0px 0px 0px 4px;
    font-size: 12px;
    letter-spacing: 0.15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }

  .planner-filter-row__value-container {
    margin-right: 83px;
    width: 100%;
    overflow-x: auto;
    border-bottom: 0;
    flex-wrap: nowrap;
    padding: 2px 0px 2px 5px;
    scroll-behavior: smooth;

    @media (max-width: 1100px) {
      overflow: auto;
      padding: 0px 15px;
    }
  }

  ::-webkit-scrollbar {
    height: 4px;
    width: 4px;
    background: #d4d4d4;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #4c549f;
    border-radius: 10px;
  }

  .planner-filter-row__multi-value__remove {
    margin-left: auto;
    color: var(--text-2);
    background: var(--background-2);
    cursor: pointer;
    border-radius: 50px;
    padding: 0px 3px;

    img {
      width: 12px;
      height: 12px;
    }

    &:hover {
      background: var(--stroke);
      color: var(--text-2);
    }
  }
}

:root[data-theme="light"] {
  .planner-control-panel__setting-icon.revert {
    width: 18px;
    height: 18px;
  }

  .dashboard__tb-button.revert {
    img {
      width: 18px;
      height: 18px;
    }
  }
}

.planner-control__button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 8px;
  border: 1px solid var(--stroke);
  border-radius: 7px;
  cursor: pointer;
  min-width: 40px;

  img {
    width: 100%;
    height: 100%;
  }
}

.planner-control__team-picker {
  padding: 8px 8px;
  margin-left: auto;
  max-width: 150px;
  min-width: 150px;

  @media (max-width: 660px) {
    max-width: 125px;
    margin-left: unset;

    .planner-control__team-name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .planner-control__team-name {
    color: var(--button-outline-text-default);
    margin: auto;
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.scroll-arrow__container {
  min-width: 30px;
  max-width: 2.5%;
  cursor: pointer;
  width: 30px;
}

.planner-control__date-picker {
  width: 100px;

  @media (max-width: 640px) {
    width: 80px !important;
  }

  .MuiOutlinedInput-input {
    padding: 15px 18px;
    background-color: white;
    border-radius: 6px;
  }

  @media (max-width: 1100px) {
    width: 100px;

    .MuiPickersBasePicker-container {
      top: 0px !important;
    }
  }

  input {
    color: var(--button-outline-text-default) !important;
    font-family: "Montserrat";
    font-size: 16px;
    display: flex;
    align-items: center;
    letter-spacing: 0.15px;
    text-align: center;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: var(--background-1);
    margin: 0 0 0 7%;
    height: 35px;
    cursor: pointer;

    &:hover {
      border: 0px;
    }

    .MuiPaper-root {
      top: 150px !important;
      left: 50px !important;
    }

    @media (max-width: 640px) {
      margin: 0px;
      font-size: 14px;
      width: 80px;
    }

    @media (min-width: 641px) {
      margin: 0;
    }
  }
}

.planner__date-picker {
  .MuiPaper-root {
    @media (max-width: 640px) {
      width: 320px;
      margin: 0px auto;
      top: 150px !important;
      left: 0px !important;
      right: 0px !important;
    }
  }
}

.planner-filter-row__placeholder {
  text-align: left;
  font-size: 14px;
  color: var(--text-2) !important;
  opacity: 0.5;
}

.arrow-block {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0 10px;
  height: 100%;

  img {
    cursor: pointer;
    width: 24px !important;
    height: 24px;
  }

  @media (max-width: 1100px) {
    gap: 0px;
    padding: 0px 5px;
  }
}

.planner-control__filter-text-field.capacity {
  margin: 0px 20px;
}

.planner-control__filter-icon {
  width: 30px;
  height: 24px;
}

.planner-control__filter-text-field {
  display: flex;
  position: relative;
  font-size: 12px;
  width: 500px;
  max-width: 500px;
  min-width: 30%;

  @media (max-width: 1100px) {
    display: flex;
    align-items: center;
  }

  .planner-control__filter-icon {
    top: 0;
    bottom: 0;
    margin: auto 0;
    left: 10px;
    cursor: pointer;
    position: sticky;

    @media (max-width: 640px) {
      position: relative;
      left: unset;
      top: unset;
    }
  }

  .input-form-component {
    margin: 0;
    border: 1px solid #c8d2e6;
    border-radius: 7px;
    padding: 14px 4px 14px 40px;
    width: 400px;
    font-family: "Montserrat";
    //line-height: 16px;
    font-size: 16px;
    &:focus,
    &:focus-visible {
      outline: none;
    }
  }
}

.days-select-slider {
  width: 150px !important;
  margin: 0 10px;
  display: flex;
  align-items: center;
  min-width: 120px;

  .MuiSlider-thumb {
    color: #9199e7 !important;
    width: 12px;
    height: 12px;
    &:hover {
      box-shadow: 0px 0px 0px 6px rgba(255, 255, 255, 0.15);
    }
    transition: cubic-bezier(0.4, 0, 0.2, 1) 0ms, left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      bottom 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
  .MuiSlider-markLabelActive {
    color: #7fa6ce !important;
  }
  .MuiSlider-markLabel {
    color: #f5f5f5b2 !important;
  }
  .MuiSlider-track {
    color: #7fa6ce !important;
  }
  .MuiSlider-rail {
    color: #7fa6ce !important;
  }
  .MuiSlider-mark {
    color: #7fa6ce !important;
  }
  .Mui-active {
    box-shadow: 0px 0px 0px 10px rgba(255, 255, 255, 0.15) !important;
  }
  @media (max-width: 600px) {
    width: 100px !important;
    margin: 15px;
  }

  input {
    width: 20px;
    height: 20px;
  }
}

.date-row__hours {
  width: 100px;
  border-right: 1px solid var(--stroke);
  padding-left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid var(--stroke);
  background-color: var(--background-1);

  p {
    color: var(--text-3);
    margin: 0;
  }
}

.planner-settings-dialog__setting {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  height: 50px;
  border-bottom: 1px solid var(--background-2);
  align-items: center;

  p {
    margin: 0;
  }
}

.planner-settings-dialog__content {
  height: 310px;
  max-height: unset;
}

.planner-settings-dialog__slider {
  .MuiSlider-markLabel {
    top: 20px;
    font-size: 11px;
    margin-top: 3px;
    font-family: Montserrat;
    color: var(--text-2);
  }

  .MuiSlider-thumb {
    color: var(--text-12);
    width: 12px;
    height: 12px;
  }

  .MuiSlider-mark {
    color: var(--slider-location);
    width: 12px;
    height: 12px;
    border-radius: 100%;
    transform: translate(-50%, -50%);
  }

  .MuiSlider-rail {
    height: 1px;
    color: var(--slider-location);
    opacity: 1;
  }

  .MuiSlider-markActive {
    background: var(--header-nav-color);
  }
}

.planner-settings-dialog__slider.en {
  .MuiSlider-markLabel {
    &[data-index="0"] {
      left: 4% !important;
    }

    &[data-index="2"] {
      transform: none;
      left: 85% !important;
    }
  }
}

.planner-settings-dialog__slider.de {
  .MuiSlider-markLabel {
    &[data-index="0"] {
      left: 3% !important;
    }

    &[data-index="2"] {
      transform: none;
      left: 85% !important;
    }
  }
}

.planner-settings-dialog__slider.fr {
  .MuiSlider-markLabel {
    &[data-index="0"] {
      left: 3% !important;
    }

    &[data-index="2"] {
      transform: none;
      left: 85% !important;
    }
  }
}

.planner-settings-dialog__slider.es {
  width: 200px;
  left: 10%;
  .MuiSlider-markLabel {
    &[data-index="0"] {
      left: 10% !important;
    }

    &[data-index="2"] {
      transform: none;
      left: 83% !important;
    }
  }
}

.planner-settings-dialog__slider.it {
  .MuiSlider-markLabel {
    &[data-index="0"] {
      left: 6% !important;
    }

    &[data-index="2"] {
      transform: none;
      left: 82% !important;
    }
  }
}

.planner-settings-dialog__zoom-slider {
  .MuiSlider-markLabel {
    top: 20px;
    color: var(--text-2);
    font-size: 11px;
    font-family: Montserrat;
    margin-top: 3px;
  }
  .MuiSlider-thumb {
    color: var(--text-12);
    width: 12px;
    height: 12px;
  }
  .MuiSlider-mark {
    color: var(--slider-location);
    width: 12px;
    height: 12px;
    border-radius: 100%;
    transform: translate(-50%, -50%);
  }
  .MuiSlider-track {
    color: var(--accent-dark);
  }
  .MuiSlider-rail {
    height: 1px;
    color: var(--slider-location);
    opacity: 1;
  }
  .MuiSlider-markActive {
    background: var(--header-nav-color);
  }
}

.staging-settings__container {
  position: fixed;
  background: #fff;
  width: 52px;
  height: fit-content;
  border: 1px solid var(--stroke);
  padding: 10px;
  border-radius: 7px;
  z-index: 1301;
}

.planner-settings-dialog__zoom-slider.en.planners {
  .MuiSlider-markLabel {
    &[data-index="3"] {
      transform: none;
      left: 83% !important;
    }
    &[data-index="0"] {
      left: 4% !important;
    }
  }
}

.planner-settings-dialog__zoom-slider.de.planners {
  .MuiSlider-markLabel {
    &[data-index="3"] {
      transform: none;
      left: 83% !important;
    }
    &[data-index="0"] {
      left: 8% !important;
    }
  }
}

.planner-settings-dialog__zoom-slider.fr.planners {
  .MuiSlider-markLabel {
    &[data-index="3"] {
      transform: none;
      left: 91% !important;
    }
    &[data-index="0"] {
      left: 6% !important;
    }
  }
}

.planner-settings-dialog__zoom-slider.it.planners {
  .MuiSlider-markLabel {
    &[data-index="3"] {
      transform: none;
      left: 91% !important;
    }
    &[data-index="0"] {
      left: 1% !important;
    }
  }
}

.planner-settings-dialog__zoom-slider.es.planners {
  width: 200px;
  left: 10%;
  .MuiSlider-markLabel {
    &[data-index="0"] {
      left: 5% !important;
    }
    &[data-index="3"] {
      transform: none;
      left: 85% !important;
    }
  }
}

.planner-settings-dialog__zoom-slider.en.grouped_planners {
  .MuiSlider-markLabel {
    &[data-index="2"] {
      transform: none;
      left: 83% !important;
    }
    &[data-index="0"] {
      left: 3% !important;
    }
  }
}

.planner-settings-dialog__zoom-slider.de.grouped_planners {
  .MuiSlider-markLabel {
    &[data-index="2"] {
      transform: none;
      left: 84% !important;
    }
    &[data-index="0"] {
      left: 3% !important;
    }
  }
}

.planner-settings-dialog__zoom-slider.fr.grouped_planners,
.planner-settings-dialog__zoom-slider.it.grouped_planners {
  .MuiSlider-markLabel {
    &[data-index="0"] {
      left: 4% !important;
    }
    &[data-index="2"] {
      transform: none;
      left: 90% !important;
    }
  }
}

.planner-settings-dialog__zoom-slider.es.grouped_planners {
  width: 200px;
  left: 10%;
  .MuiSlider-markLabel {
    &[data-index="0"] {
      left: 2% !important;
    }
    &[data-index="2"] {
      transform: none;
      left: 85% !important;
    }
  }
}

.project-group__client-name {
  writing-mode: vertical-lr;
  transform: rotate(-180deg);
  height: 100%;
  max-height: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-top: 3px;
}

.gtm-auth-container {
  position: fixed;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  z-index: 10000;
}

.planner-overlap {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.15;

  top: 0;
  left: 0;
}

.booking-repetition-text {
  text-align: left;
  margin: 10px 0;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}

.booking-repetition-delete {
  cursor: pointer;
  margin-left: 20px;
  height: 24px;
  margin-top: 10px;
}

.onboarding__content {
  margin-right: 4px;
  &::-webkit-scrollbar-track {
    width: 6px !important;
    height: 6px !important;
  }

  &::-webkit-scrollbar {
    width: 6px !important;
    height: 6px !important;
  }

  &::-webkit-scrollbar-thumb {
    width: 6px !important;
    height: 6px !important;
  }
}

.onboarding-video-component {
  .MuiPaper-root {
    height: fit-content;
  }

  .MuiDialogContent-root {
    overflow-x: hidden;
    scrollbar-gutter: unset;
  }

  .MuiTypography-root {
    margin-top: 0px;
    padding: 0px;
  }

  .tutorial-title {
    padding: 40px 0;
    line-height: 26.82px;
  }
}

.grabbing-mode * {
  cursor: grabbing !important;
}

.planner__print-pdf__button {
  padding: 8px 10px !important;

  .planner__print-pdf__button-text {
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 14px;

    img {
      height: 20px;
      width: 20px;
    }
  }
}

.suggested-projects {
  display: flex;
  align-items: center;
  margin-top: 3px;
  overflow: hidden;

  p {
    font-size: 12px;
    margin: 3px 0 0 0;
    letter-spacing: 0.4px;
  }

  .suggested-projects__project-block {
    cursor: pointer;
    display: flex;
    align-items: baseline;
    .suggested-projects__circle {
      width: 8px;
      height: 8px;
      margin: 0 6px 0 18px;
      border-radius: 2px;
    }
    p {
      font-family: Montserrat;
      font-size: 14px;
      font-style: italic;
      font-weight: 400;
      letter-spacing: 0.4px;
    }
  }
}

.date-row__month {
  position: absolute;
  display: flex;
  z-index: 2;
  height: 34px;
  top: 0px;
  padding: 0px 30px 0px 30px;

  p {
    position: sticky;
    width: fit-content;
    margin: 3px auto 0px auto;
    top: 0px;
    bottom: 0px;
    text-transform: uppercase;
    color: var(--text-1);
    font-size: 12px;
    letter-spacing: 0.4px;
    line-height: 14.4px;
  }
}

.planner-info-modal__not-enough {
  margin: 10px 0 20px 0;

  button {
    margin-left: auto;
  }

  .planner-info-modal__not-enough-element {
    display: flex;
    align-items: center;
    margin: 5px 0;

    .planner-info-modal__not-enough__text {
      margin: 7px 10px 0 0;
    }
  }
}

.manage_teams__content-header {
  width: 100%;
  background-color: var(--background-2);
  display: flex;
  border-radius: 7px;
  margin-bottom: 10px;
  position: sticky;
  top: 0;
  height: 39px;

  p {
    padding: 10px;
    font-size: 16px;
    font-weight: 500;
    line-height: 120%;
    user-select: none;
    cursor: pointer;
    margin: unset;
  }
}

.planner__department-line {
  width: 4px;
  height: 100%;
  position: absolute;
  left: 0;
}

.milestone-modal {
  .MuiDialogTitle-root {
    border: unset;
    margin: unset;
    padding: 30px 30px 0px 30px;
  }
  .MuiDialogContent-root {
    margin-top: 20px;

    .text-form__container {
      margin: 0px 0px 20px 0px;
      p {
        margin: unset;
      }
    }
    .MuiOutlinedInput-root {
      padding-right: 0px;
      .MuiIconButton-root {
        margin-left: unset;
        padding: unset;
      }
    }
  }
  .MuiDialogActions-root {
    padding: 0px 32px 30px 30px;
    p {
      font-weight: 400;
    }
  }
}

.booking__resizer {
  position: absolute;
  height: 10px;
  z-index: 10;
  bottom: 0;
  background: transparent;
  cursor: row-resize;
  width: 100%;
  left: 1px;
}

.booking-window__repetition-container {
  padding: 10px 10px 10px 16px;
  border: 1px solid var(--stroke);
  border-radius: 7px;
  margin-top: 10px;
}

.booking-window__repetition-end-switch {
  display: flex;
  align-items: center;
  width: 108px;
  height: 32px;
  border: 1px solid var(--stroke);
  border-radius: 7px;
}

.booking-window__repetition-end-switch__option {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 100%;
  cursor: pointer;
  p {
    text-transform: capitalize;
    color: var(--text-1);
    font-size: 14px;
  }

  &:first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  &:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  &.active {
    background-color: var(--stroke);
  }
}

.booking-window__repetition-container__header {
  text-align: left;
  font-size: 14px;

  p {
    margin-bottom: 10px !important;
  }
}

.tb-react-select__option {
  text-transform: capitalize;
}

.booking-window__repetition-row {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;

  .tb-react-select__single-value {
    color: var(--text-1) !important;
    text-transform: capitalize;
  }

  p {
    margin: 0px;
    font-size: 14px;
    color: var(--text-1);
  }

  .input-number__container {
    input {
      height: 32px !important;
      width: 66px !important;
      color: var(--text-1) !important;
    }
  }

  .booking-window__repetition-row__input-number {
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 7px;
  }

  .tb-radio-button {
    margin: 0px;
  }
}

.booking-window__repetition-ignore-weekends {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;

  p {
    margin-bottom: 0px;
    font-size: 14px;
  }
}
