.users-block__items {
  overflow: hidden;
  display: flex;
  height: 26px;
  gap: 10px;
}

.users-block__item {
  border-radius: 20px;
  width: fit-content;
  padding: 6px 8px;
  align-items: center;
  display: flex;
  max-width: 130px;

  p {
    text-align: center;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: 0.15px;
    margin: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: var(--text-10);
  }

  &.remaining {
    margin: 0 auto 2px;
  }

  &.is-teams {
    background: var(--background-2);
  }

  &.is-teams p {
    color: var(--text-2);
  }
}

.user-block__tags-overflow-indicator {
  min-width: 40px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--background-2);
  padding: 6px 8px;
  border-radius: 20px;
  margin: 0;
  color: var(--text-2);
  font-size: 12px;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: 0.15px;
}
