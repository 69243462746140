.App {
  text-align: center;
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.my-week-page {
  font-family: "Montserrat";
}

.my-week-page .jwfGuq {
  margin-right: 5px;
}

.bg-shadow {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  outline: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
html {
  font-size: 1rem;
}

@media screen and (min-width: 540px) {
  html {
    font-size: 0.5rem;
  }
}
@media screen and (min-width: 720px) {
  html {
    font-size: 0.8rem;
  }
}
@media screen and (min-width: 960px) {
  html {
    font-size: 1rem;
  }
}

.blurred {
  opacity: 0.8 !important;
  border: 2px solid #4a59ac !important;
}

.blurred-proj {
  opacity: 0.6 !important;
}

body > iframe {
  display: none;
}

.ReactVirtualized__Grid {
  outline: none;
}
