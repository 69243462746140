.account-subscribed {
  background-color: #d0e2be;

  p {
    color: var(--text-1);
  }
}

.account-mobile {
  opacity: 0.4;
}

.locales-component {
  position: absolute;
  top: 80px;
  left: 20px;
  bottom: 140px;
  right: 20px;

  .language-column {
    margin-left: 20px;

    textarea {
      width: 700px;
      height: 100%;
      white-space: nowrap;
    }

    .language-header {
      justify-content: space-between;
    }
  }

  p {
    color: var(--text-1);
  }
}

.administration__content-list {
  display: flex;
  margin-top: 100px;
  padding: 0 120px;
  flex-wrap: wrap;

  .administration__statistics__block {
    background-color: var(--background-2);
    width: 200px;
    min-width: 200px;
    height: 200px;
    border: 1px solid var(--stroke);
    border-radius: 10px;
    padding: 60px 10px 0 10px;
    margin-right: 30px;
    margin-bottom: 30px;
    cursor: pointer;
  }

  p {
    color: var(--text-1);
  }
}

.control-panel__container {
  position: fixed;
  display: flex;
  bottom: 25px;
  margin: auto;
  align-items: center;
  left: 0px;
  right: 0px;
  width: fit-content;
  height: 60px;
  background-color: black;
  border-radius: 10px;
  opacity: 0.5;
  z-index: 22;

  a {
    color: white;
  }

  &:hover {
    opacity: 1;
  }

  .control-panel__option {
    margin: 0 10px;
    padding: 10px 10px;
    background-color: black;
    cursor: pointer;
    border-radius: 10px;

    p {
      font-size: 14px;
      color: white;
      margin: unset;
    }
  }
}

.table-hover__head {
  position: sticky;
  top: 0;
  background: white;
}

.table-hover__head-cell {
  cursor: pointer;
}

.icons-component {
  position: absolute;
  top: 80px;
  left: 20px;
  bottom: 140px;
  right: 20px;

  .icons-column {
    margin-left: 20px;
  }

  p {
    color: var(--text-1);
  }
}

.icons-list {
  display: flex;
  flex-direction: column;
  border: 3px solid var(--stroke);

  .icons-list__icon-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    height: 50px;
  }
}
