/*--------------------
SHARED VARIABLES
--------------------*/
$red: #e91e63;
$blue: #3f51b5;
$grey: #eae8e9;
$grey2: #f3f3f3;
$white: #fff;

/*--------------------
GENERAL
--------------------*/

#cardForm {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*--------------------
PANEL FORM
--------------------*/

.panel {
  background: var(--background-1);
  width: 350px;
}

.panel__header {
  background: $blue;
  color: $white;
}

.panel__footer {
  margin: 28px 0px 20px 0px;
  display: flex;
}

.panel__header {
  padding: 1em 2em;
}

.panel__content {
  padding: 0;
  overflow: hidden;
}

.textfield--float-label {
  width: 50%;
  float: left;
  display: inline-block;
}

.hosted-field--label {
  transform: translateY(0.4em);
  font-size: 14px;
  line-height: 32px;
  transition: all 0.15s ease-out;
  display: block;
  width: 100%;
  font-weight: 400;
  overflow: hidden;
  margin-bottom: 0.5em;
  // &.label-float {
  //   text-overflow: ellipsis;
  //   color: #2196f3;
  //   transition: all 0.15s ease-out;
  // }
  // &.filled {
  //   @extend .label-float;
  //   color: rgba(0, 0, 0, 0.54);
  // }
  // &.invalid {
  //   @extend .label-float;
  //   color: #f44336;
  // }
}

span.icon {
  position: relative;
  top: 0.2em;
  margin-right: 0.2em;
}

.hosted-field {
  height: 44px;
  margin-bottom: 12px;
  display: block;
  color: var(--text-2) !important;
  border: 1px solid var(--stroke);
  outline: 0;
  width: 100%;
  font-size: 16px;
  padding: 10px 16px;
  box-shadow: none;
  border-radius: 8px;
  background: var(--background-1);
  position: relative;
  font-family: roboto, verdana, sans-serif;
  caret-color: var(--cursor-color) !important;
  font-family: Montserrat;
  transition: all var(--transition-duration);

  .input {
    color: var(--text-2) !important;
  }

  &:focus,
  &:focus-visible {
    background-color: var(--background-2);
  }

  &::placeholder {
    color: var(--text-2) !important;
    opacity: 0.5 !important;
  }
}

.pay-button {
  color: #fff;
  height: 40px;
  text-transform: unset !important;
  font-family: "Montserrat", sans-serif;
  font-size: 16px !important;
  border-radius: 4px !important;
  border: none !important;
  background-color: var(--accent-dark) !important;
  margin-left: auto;
  width: 130px;
  font-family: Montserrat;

  &:disabled {
    border: unset !important;
    background-color: #d0d0d0 !important;
  }
}

/*--------------------
BT HOSTED FIELDS SPECIFIC
--------------------*/

.braintree-hosted-fields-focused {
  background-color: var(--background-2);
  transition: all var(--transition-duration);
}

.braintree-hosted-fields-invalid {
  border: 1px solid $red;
  transition: all 200ms ease;
}

/*---------------------
Media Queries
----------------------*/

@media (max-width: 600px) {
  html {
    overflow: auto;
  }
  #cardForm {
    height: auto;
    margin: 2em;
    font-size: 13px;
  }
  .panel {
    width: 100%;
  }
  .textfield--float-label {
    width: 100%;
    float: none;
    display: inline-block;
  }
  .pay-button {
    width: 100%;
  }
}

.blink-text {
  animation: blinker 2s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.error-text {
  color: #e3262f;
}
