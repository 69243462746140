.project-budget {
  display: flex;
  width: 100%;
  height: 30px;

  .project-budget__text {
    margin: 4px 0 0 0;

    svg {
      margin-top: 4px !important;
      margin-right: 2px !important;
    }
  }

  .project-budget__bar {
    width: 100%;
  }

  .project-budget__booked {
    margin: unset;
    text-align: center;
    font-size: 14px;
  }

  .project-budget__middle {
    width: 100%;
    margin: -5px 15px;
  }
}

.teambook-filter__type-switcher {
  width: 40px;
  height: 38px;
  border-left: 1px solid var(--stroke);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    font-size: 12px;
    color: var(--text-9) !important;
    letter-spacing: 0.15px;
    margin: 0;
  }
}

.teambook-filter__right-side {
  position: absolute;
  right: -5px;
  z-index: 2;
  display: flex;
}

.teambook-default-menu {
  .MuiMenu-paper {
    margin-top: 9px;
    width: fit-content;
    background: var(--background-1);
    border: 1px solid var(--stroke);
    border-radius: 10px;
    box-shadow: unset;
    overflow: visible;
  }

  .MuiModal-backdrop {
    background: none !important;
  }

  .teambook-default-menu__option {
    cursor: pointer;
    height: 38px;
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    color: var(--text-2);
    font-feature-settings: "clig" off, "liga" off;
    font-size: 14px;
    line-height: 14px;
    white-space: nowrap;

    p {
      margin: unset;
    }

    &:hover {
      color: var(--header-menu__item-text);
      background: var(--background-2);

      img {
        filter: var(--header-icon-filter);
      }
    }
  }
}

.apply-filter-modal__option {
  display: flex;
  border-bottom: 1px solid var(--stroke);
  margin-bottom: 10px;
  justify-content: space-between;

  p {
    margin: 15px 20px;
    cursor: pointer;
  }

  .planner-control__button {
    width: 40px;
    height: 40px;
    margin-top: 5px;
    margin-left: 20px;
  }
}

.tb-react-select__indicator-separator {
  display: none;
}

.tb-react-planner-selector__indicator-separator {
  display: none;
}

.tb-react-select__control {
  font-family: Montserrat;
  font-size: 14px !important;
  border-radius: 8px !important;
  min-width: 100px;
  border: 1px solid var(--stroke) !important;
  box-shadow: none !important;
  height: 43px;
}

.tb-react-planner-selector__control {
  font-family: Montserrat;
  font-size: 14px !important;
  border-radius: 8px !important;
  min-width: 100px;
  border: 1px solid var(--stroke) !important;
  box-shadow: none !important;
  height: 43px;
  text-align: left;
}

.highlighted-date {
  color: var(--text-2) !important;
  background: var(--stroke) !important;
  font-weight: 500;
  border-radius: 10px;
}

.tb-react-select__menu-list {
  border-radius: 8px !important;
  padding: 0px !important;
}

.tb-react-planner-selector__menu-list {
  border-radius: 8px !important;
  padding: 0px !important;
}

.tb-react-select__option {
  color: var(--text-2);
  font-size: 12px !important;
  padding: 8px 10px !important;
  font-family: Montserrat;
}

.tb-react-planner-selector__option {
  color: var(--text-2);
  font-size: 12px !important;
  padding: 8px 10px !important;
  font-family: Montserrat;
}

.tb-react-select__single-value {
  color: var(--button-outline-text-default) !important;
  transform: unset !important;
  top: 0px !important;
  bottom: 0px !important;
  margin: auto 0;
  display: flex;
  align-items: center;
}

.tb-react-planner-selector__single-value {
  color: var(--text-9) !important;
  transform: unset !important;
  top: 0px !important;
  bottom: 0px !important;
  margin: auto 0;
}

.tb-react-select__dropdown-indicator {
  transform: unset !important;
  padding: 0px 8px !important;
}

.tb-react-planner-selector__dropdown-indicator {
  transform: unset !important;
  padding: 0px 8px !important;
}

.tb-react-select__menu {
  border-radius: 8px !important;
  z-index: 1000;
}

.tb-react-planner-selector__menu {
  border-radius: 8px !important;
  z-index: 1000;
}

.tb-react-select__menu-portal {
  z-index: 1501 !important;
}

.tb-react-planner-selector__menu-portal {
  z-index: 1501 !important;
}

.tb-react-select-multi__indicator-separator {
  display: none;
}

.tb-react-planner-selector-multi__indicator-separator {
  display: none;
}

.tb-react-select__option {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.tb-react-planner-selector__option {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.control-bar__today-button {
  height: 40px;
  min-width: 44px;
  max-width: 44px;
  border: 1px solid var(--stroke);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  cursor: pointer;
}

.teambook-filter-component__accessories {
  position: absolute;
  right: 6px;
  top: 0px;
}

.react-select__no-options-menu.planner-filter-row__menu {
  width: 100% !important;
  left: 0px;
  border-radius: 7px;

  .planner-filter-row__menu-list {
    padding: 0px;
    height: 66px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .planner-filter-row__menu-notice {
    color: var(--text-8);
    font-size: 12px;
  }

  @media (min-width: 1100px) and (max-width: 1366px) {
    width: 100;
  }
}

.tb-react-select-multi__control {
  font-family: Montserrat;
  font-size: 14px !important;
  border-radius: 8px !important;
  max-width: 280px;
  min-width: 100px;
  border: 1px solid var(--stroke) !important;
  height: 43px;
  background-color: var(--background-1) !important;
  box-shadow: none !important;

  ::-webkit-scrollbar {
    height: 6px;
    width: 6px;
    background: #d4d4d4;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #4c549f;
    border-radius: 10px;
  }

  .tb-react-select-multi__multi-value {
    padding: 0 10px 0 10px;
    height: 24px;
    color: #474747;
    border-radius: 20px;
    min-width: -moz-fit-content;
    min-width: fit-content;
    align-items: center;

    .select__multi-value__label {
      font-family: Montserrat;
    }

    .tb-react-select-multi__multi-value__remove {
      min-width: 16px;
      min-height: 16px;
      stroke-width: -1;

      &:hover {
        background: var(--background-2);
        border-radius: 4px;
        opacity: 0.7;
      }
    }

    -ms-scroll-translation: vertical-to-horizontal;
  }
}

.month-picker {
  position: relative;

  .arrow-block {
    display: flex;
    padding: 0px;
    gap: 5px;
  }

  .month-picker__current-date {
    color: var(--text-9);
    margin: 0;
    font-size: 14px;
    white-space: nowrap;
    text-transform: capitalize;
    letter-spacing: 0.4px;
  }

  .month-picker__picker-container {
    position: absolute;
    width: 310px;
    height: 270px;
    background: var(--background-1);
    z-index: 1000;
    border-radius: 8px;
    padding: 24px;
    border: 1px solid var(--stroke);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: var(--box-shadow);
  }

  .month-picker__picker-header {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    p {
      font-size: 20px;
      color: var(--text-1);
      margin: 0px;
      text-transform: capitalize;
    }
  }

  .month-picker__picker-months {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .month-picker__year-selector {
    position: absolute;
    border-radius: 10px;
    width: 100px;
    background: var(--background-1);
    z-index: 1001;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--stroke);
  }

  .month-picker__year {
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px;
    font-size: 14px;
    color: var(--text-2);

    &:first-child {
      border-radius: 8px 8px 0px 0px;
    }

    &:last-child {
      border-radius: 0px 0px 8px 8px;
    }

    &:hover {
      background: var(--background-2);
      color: var(--text-1);
    }
  }

  .month-picker__year.selected {
    background: var(--stroke);
    color: var(--text-5);
  }

  .month-picker__picker-month.selected {
    background: var(--stroke) !important;

    p {
      color: var(--text-5);
    }
  }

  .month-picker__picker-month {
    width: 70px;
    border-radius: 7px;
    height: 30px;
    margin-bottom: 12px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background: var(--background-2);

      p {
        color: var(--text-2);
      }
    }

    p {
      color: var(--text-2);
      font-size: 14px;
      letter-spacing: 1.5px;
      margin: 0px;
    }
  }
}

.tb-react-planner-selector-multi__control {
  font-family: Montserrat;
  font-size: 14px !important;
  border-radius: 8px !important;
  max-width: 280px;
  min-width: 100px;
  border: 1px solid var(--stroke) !important;
  height: 43px;
  background-color: var(--background-1) !important;
  box-shadow: none !important;

  ::-webkit-scrollbar {
    height: 6px;
    width: 6px;
    background: #d4d4d4;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #4c549f;
    border-radius: 10px;
  }

  .tb-react-planner-selector-multi__multi-value {
    padding: 0 10px 0 10px;
    height: 24px;
    color: #474747;
    border-radius: 20px;
    min-width: -moz-fit-content;
    min-width: fit-content;
    align-items: center;

    .select__multi-value__label {
      font-family: Montserrat;
    }

    .tb-react-planner-selector-multi__multi-value__remove {
      min-width: 16px;
      min-height: 16px;
      stroke-width: -1;
    }

    -ms-scroll-translation: vertical-to-horizontal;
  }
}

.tb-react-select-multi__value-container {
  overflow-x: auto !important;
  border-bottom: 0 !important;
  flex-wrap: nowrap !important;
  scroll-behavior: smooth !important;
}
.tb-react-planner-selector-multi__value-container {
  overflow-x: auto !important;
  border-bottom: 0 !important;
  flex-wrap: nowrap !important;
  scroll-behavior: smooth !important;
}

.tb-react-select-multi__menu-list {
  border-radius: 8px !important;
  padding: 0px !important;
}
.tb-react-planner-selector-multi__menu-list {
  border-radius: 8px !important;
  padding: 0px !important;
}

.tb-react-select-multi__option {
  color: #000;
  font-size: 12px !important;
  padding: 8px 10px !important;
  font-family: Montserrat;
}
.tb-react-planner-selector-multi__option {
  color: #000;
  font-size: 12px !important;
  padding: 8px 10px !important;
  font-family: Montserrat;
}

.tb-react-select-multi__single-value {
  transform: unset !important;
  top: 0px !important;
  bottom: 0px !important;
  margin: auto 0;
  display: flex;
  align-items: center;
}
.tb-react-planner-selector-multi__single-value {
  transform: unset !important;
  top: 0px !important;
  bottom: 0px !important;
  margin: auto 0;
  display: flex;
  align-items: center;
}

.tb-react-select-multi__dropdown-indicator {
  transform: unset !important;
  padding: 0px 8px !important;
}
.tb-react-planner-selector-multi__dropdown-indicator {
  transform: unset !important;
  padding: 0px 8px !important;
}

.tb-react-select-multi__menu {
  border-radius: 8px !important;
  z-index: 1000;
}
.tb-react-planner-selector-multi__menu {
  border-radius: 8px !important;
  z-index: 1000;
}

.tb-react-select-multi__menu-portal {
  z-index: 1300 !important;
}
.tb-react-planner-selector-multi__menu-portal {
  z-index: 1300 !important;
}

.tb-react-select-multi__clear-indicator {
  display: none !important;
}

.tb-react-planner-selector-multi__clear-indicator {
  display: none !important;
}

// .select__control {

//   .select__indicator-separator {
//     display: none;
//   }
// }

.delete-task-confirmation__text {
  p {
    margin: 0;
    line-height: 120%;
    font-size: 16px;
  }

  .delete-task-confirmation__task-name {
    color: var(--header-nav-color);
  }
}

.input-number__container {
  position: relative;
  width: fit-content;
  height: fit-content;
}

.input-number__field {
  width: 80px;
  height: 44px;
  font-family: Montserrat;
  padding: 10px 30px 10px 15px;
  border-radius: 4px;
  border: 1px solid var(--stroke);
  background: var(--background-1);
  color: var(--text-2);
  caret-color: var(--cursor-color);

  outline: none;

  :focus {
    outline: none;
  }
}

.bordered_inline_picker {
  width: 130px;
}

.booking-window__repetition-row__checkbox {
  display: block;
}

.input-number__arrows {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  align-items: center;
  gap: 0px !important;
  top: 0px;
  right: 0px;
  bottom: 0px;
  height: 100%;
  border-left: 1px solid var(--stroke);

  :first-child {
    border-bottom: 1px solid var(--stroke);
  }

  img {
    width: 20px;
    cursor: pointer;
  }
}

.input-number__field::-webkit-outer-spin-button,
.input-number__field::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.language-picker {
  display: flex;
}

.tb-banner {
  width: 100%;
  display: flex;
  height: 70px;
  background: #4c54a0;
  position: fixed;
  bottom: 0px;
  align-items: center;
  z-index: 10;

  &.end-of-free-banner {
    height: 160px !important;
  }
}

.tb-banner__content {
  textarea {
    padding: 10px 15px;
  }
}

.tb-banner__container {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
}

$darkblue: #4c54a0;
$grey: #d0d0d0;

.disabled-date-picker {
  display: none;
}

.tb-date-picker__start-container {
  display: flex;
  color: var(--text-9);
  letter-spacing: 0.4px;
}

.tb-date-picker {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--stroke);
  border-radius: 7px;
  max-width: 260px;

  img {
    cursor: pointer;
  }
}

.tb-date-picker__date-show {
  display: flex;
  gap: 5px;
  white-space: nowrap;
  min-width: 70px;
  justify-content: center;

  p {
    margin: 0px;
    color: var(--text-9);
    cursor: pointer;
    font-size: 14px;
    text-align: center;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    letter-spacing: 0.4px;
    text-transform: capitalize;
    margin-top: 1px;
  }
}

.date-picker {
  height: 420px;
  background: var(--background-1);
  border-radius: 8px;
  padding: 24px;
  display: flex;
  justify-content: space-between;
  z-index: 1500;
  position: fixed;
  top: 0px;
  // box-shadow: 2px 16px 19px 0px var(--box-shadow);
  border: 1px solid var(--stroke);
  box-shadow: var(--box-shadow);

  p {
    margin: 0px;
    text-align: center;
  }

  img {
    cursor: pointer;
  }
}

.date-picker__header {
  display: flex;
  width: 100%;
  height: fit-content;
}

.date-picker__ranges {
  display: flex;
  align-items: center;
  height: 32px;
  width: 100%;
  gap: 10px;

  p {
    margin: 0px;
    font-size: 14px;
    color: var(--button-outline-text-default);
  }
}

.ranged-picker {
  min-height: 380px;
}

.default-picker {
  height: 335px;
}

.date-picker__dropdown-months {
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: column;
  min-width: 100px;
  width: fit-content;
  background: var(--background-1);
  border: 1px solid var(--stroke);
  border-radius: 10px;
  max-height: 200px;
  overflow: auto;

  &::-webkit-scrollbar {
    height: 8px;
    width: 8px !important;
    background: #d4d4d4;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    width: 7px !important;
    background: #4c549f;
    border-radius: 10px;
  }

  p {
    color: var(--text-2);
    font-size: 14px;
    text-align: start;
    padding: 6px 10px;
    cursor: pointer;
    text-transform: capitalize;

    &:hover {
      color: var(--text-8) !important;
      background: var(--background-2);
    }
  }

  p.active {
    color: var(--text-1) !important;
    background: var(--stroke);
  }
}

.date-picker__left-side,
.date-picker__right-side {
  max-width: 266px;
  min-width: 266px;
  display: flex;
  flex-direction: column;
}

.date-picker__start-range {
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 20px;
  font-size: 14px;

  .flex {
    align-items: center;
  }

  .date-picker__range-month {
    color: var(--text-9);
    line-height: 14px;
    text-transform: capitalize;
  }
}
.date-picker__end-range {
  display: flex;
  align-items: center;
  margin-right: auto;
  gap: 20px;
  font-size: 14px;

  .flex {
    align-items: center;

    p {
      cursor: pointer;
    }
  }

  .date-picker__range-month {
    color: var(--text-9);
    line-height: 14px;
    text-transform: capitalize;
  }
}

.date-picker__range-text {
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: var(--text-3);
}

.date-picker__range-arrow {
  width: 16px;
  height: 16px;
}

.date-picker__range-date {
  display: flex;

  p {
    white-space: nowrap;
    cursor: pointer;
  }

  gap: 10px;
}

.date-picker__date-switcher {
  margin-top: 22px;
}

.date-picker__small-dropdown-trigger {
  position: relative;
  p {
    cursor: pointer;
    margin: 0px;
    font-size: 14px;
    color: var(--text-2);
  }
}

.date-picker__date-switcher-left,
.date-picker__date-switcher-right {
  display: flex;
  position: relative;
  align-items: center;

  img {
    position: absolute;
  }
}

.date-picker__date-switcher-right {
  img {
    right: 0px;
    transform: rotate(180deg);
  }
}

.calendar__week-days {
  margin-top: 20px;
  display: flex;

  p {
    font-size: 12px;
  }
}

.calendar__dates {
  margin-top: 20px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

.calendar__week-day {
  min-width: 30px;

  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
}

.calendar__name-of-day {
  min-width: 38px;
  max-width: 38px;
  color: var(--text-3);
}

.calendar__day-number {
  cursor: pointer;
  width: 30px;
  padding: 3px 0px;
  color: var(--text-2);

  &:hover {
    background: var(--background-2);
    border-radius: 10px;
  }
}

.range-picker__day {
  &:hover {
    background: unset;
  }
}

.empty-cell {
  height: 30px;

  background: #c8d2e680;
  position: absolute;
}

.warning-modal__content {
  min-width: 650px;
  width: 650px;
  max-width: unset !important ;
  padding: 30px 40px !important;
  display: flex;
  flex-direction: column;

  p {
    margin: 0px;
  }

  img {
    width: 100px;
    height: 100px;
    margin: auto auto 20px auto;
  }
}

.warning-modal {
  margin-bottom: 400px;
}

.warning-modal__title {
  border-bottom: unset !important;
}

.moved-feature-message {
  position: absolute;
  width: 100%;
  top: 120px;

  img {
    width: 80px;
    margin-bottom: 10px;
  }
  .moved-feature-message__text {
    display: flex;
    justify-content: center;
    gap: 5px;
  }
  padding: 40px 0px;

  font-size: 24px;
  letter-spacing: 0.4px;
}

.tb-default-picker__inline-calendar-icon {
  position: absolute;
  right: -30px;
  opacity: 0.75;
}

.inline_picker {
  border: unset !important;
  position: relative;
  border-bottom: 1px solid var(--stroke) !important;
  border-radius: unset;
  padding: 0px !important;

  .tb-date-picker__date-show {
    p {
      color: var(--text-9) !important;
    }
  }
}

.delete-dialog {
  .MuiPaper-root {
    border-radius: 20px;
    background: var(--background-1);
    color: var(--text-2);
    min-width: 470px;
    box-shadow: var(--box-shadow);

    @media (max-width: 640px) {
      min-width: 90% !important;
      max-width: 90% !important;

      p {
        white-space: unset;
      }
    }
  }

  .delete-dialog__title {
    align-self: center;
    img {
      padding-bottom: 35px;
      padding-top: 35px;
    }
  }

  .delete-dialog__content {
    p {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    min-width: 450px;
    max-width: fit-content !important;

    div {
      font-family: Montserrat;

      p {
        margin: 0;
        font-size: 15px;
      }
    }

    @media (max-width: 640px) {
      min-width: 100% !important;
      max-width: 100% !important;
    }

    @media (max-width: 640px) {
      p {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: unset;
      }
    }
  }
}

.notification-container {
  min-width: 380px;
  max-width: 380px;
  max-height: 150px;
  min-height: 90px;
  background-color: var(--background-1);
  position: fixed;
  bottom: -150px;
  right: 2%;
  border: 1px solid var(--stroke);
  box-shadow: var(--box-shadow);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  z-index: 1301;
  opacity: 0;
  transition: bottom 0.5s ease, opacity 0.5s ease;

  p {
    margin: unset;
    font-size: 16px;
    font-weight: 400;
    color: var(--text-2);
  }
}

.update-notification {
  max-width: unset;
  max-height: unset;
  min-width: 350px;
  max-width: 380px;
  width: fit-content;
  min-height: 180px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: unset;
  justify-content: unset;
  padding: 20px;

  &[lang="es"] {
    max-width: 460px;
  }

  .notification-container__header,
  .notification-container__message {
    text-align: left;
    color: var(--text-2);
  }

  .notification-container__message {
    // padding: 5px 0px 10px 0px;
  }

  p {
    color: unset;
  }
}

.notification-container__header {
  color: var(--text-2);
  font-weight: 600 !important;
  margin-bottom: 10px !important;
}

.notification-container__actions {
  margin-top: auto;
  display: flex;
  gap: 20px;
  justify-content: flex-end;
}

.notification-container.show {
  bottom: 5%;
  opacity: 1;
}

.notification-container.hide {
  bottom: -150px;
  opacity: 0;
}

.loading-component {
  position: fixed;
  top: var(--top, 40%);
  left: var(--left, 40%);
}

.header-error__container {
  position: fixed;
  top: 150px;
  width: 100%;
  left: 0px;
  text-align: left;

  .error__header {
    font-weight: 700;
    font-size: 36px;
    line-height: 43.2px;
    letter-spacing: 0.4px;
    color: var(--text-1);
  }

  p {
    position: absolute;
    left: 155px;
  }

  .text {
    color: var(--text-2);
    font-size: 20px;
    top: 70px;
    white-space: pre-line;
  }
}

.header-role-error__container {
  position: fixed;
  top: 150px;
  width: 100%;
  left: 0px;
  text-align: left;

  .error__header {
    color: var(--text-1);
    text-align: center;
    font-family: Montserrat;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 43.2px */
    letter-spacing: 0.4px;
  }

  p {
    position: absolute;
    left: 155px;
  }

  .text {
    color: #777777;
    font-size: 20px;
    top: 70px;
    white-space: pre-line;
  }
}

.blocked-role_img.notfound_img {
  width: 420px;
  height: 325px;

  @media (max-height: 850px) {
    bottom: 100px !important;
    top: unset !important;
  }

  @media (max-width: 1800px) {
    right: 100px !important;
    left: unset !important;
  }
}

.planner-error__container {
  position: fixed;
  top: 60px;
  width: 100%;

  p {
    font-size: 24px;
  }
}

.error_component {
  position: fixed;
  top: 60px;
  width: 100%;

  .not_found {
    margin-top: 100px;
  }

  .text {
    color: var(--text-2);
    font-size: 20px;
    margin-top: 100px;
  }

  h2 {
    color: var(--text-1);
  }
}

.text-form__container {
  margin: 15px 0 10px 0;

  .input-container {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--stroke);
    border-radius: 8px;
    background: var(--background-1);
  }

  .input-container.focused {
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      background-color: unset !important;
      -webkit-box-shadow: 0 0 0 50px var(--background-1) inset !important;
      border-radius: 7px;
      border: unset;
      -webkit-text-fill-color: var(--text-2) !important;
    }

    background: var(--background-2) !important;

    .text-form__password-eye {
      background: var(--background-2) !important;
    }
  }

  .text-form__password-eye {
    height: 42px;
    cursor: pointer;
    border-left: unset;
    border-radius: 0px 8px 8px 0px;
    display: flex;
    align-items: center;
    padding: 0px 10px;
    background: var(--background-1);
    border-left: 1px solid var(--stroke);
  }

  .input-form-component,
  .text-form__container {
    .MuiInputBase-root {
      height: 42px !important;
      min-height: 42px !important;
      max-height: 42px !important;
      padding-block: 0px !important;
    }
  }

  .input-field.password {
    width: calc(100% - 44px);
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    background: transparent;
    height: 42px;

    input,
    fieldset {
      border-top-right-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
    }
  }

  .input-field {
    width: 100%;
    border-radius: 8px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  .MuiOutlinedInput-input {
    padding: 10px 14px 11px 14px;
    font-size: 14px !important;
    font-family: Montserrat;
    caret-color: var(--cursor-color) !important;
    color: var(--text-2);
    border-radius: 8px !important;
    transition: -webkit-box-shadow var(--transition-duration);
    background: transparent;

    &::placeholder {
      opacity: 0.5;
      color: var(--text-2);
      font-family: Montserrat;
    }

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 100px var(--background-1) inset;
      -webkit-text-fill-color: var(--text-2) !important;
    }

    &:focus {
      -webkit-box-shadow: unset;
    }
  }

  .MuiInputBase-root {
    background: transparent !important;
    border-radius: 8px !important;
    height: 42px;
  }

  .MuiOutlinedInput-notchedOutline {
    // border: 1px solid var(--stroke) !important;
    border: unset !important;
    border-radius: 8px;
  }
}
.text-form__paragraph {
  margin: 0 0 5px 0;
  text-align: left;
  font-size: 12px;
  color: var(--text-2);
  white-space: nowrap;
}

.rich-text__container {
  border: 1px solid var(--stroke);
  border-radius: 7px;

  .rich-text__controls {
    display: flex;
    margin-bottom: 20px;
    gap: 10px;
    padding: 5px 5px 5px 14px;
    border: 0px;
    border-radius: 12px 12px 0px 0px;
    justify-content: flex-start;
    flex-wrap: wrap;
    font-size: 15px;
    margin-bottom: 5px;
    user-select: none;
    margin: 0px;
    border-radius: 7px 7px 0px 0px;
    background: var(--background-2);

    .rich-text__button {
      border: 1px solid var(--stroke);
      padding: 5px;
      min-width: 20px;
      width: 20px;
      height: 20px;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      text-transform: capitalize;
      font-size: 12px;
      color: var(--accent-dark);
      transition: box-shadow var(--transition-duration);
    }
  }

  .editor-section .DraftEditor-root {
    text-align: left;
    margin: auto;
    height: 63px;
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    font-size: 14px;
    font-family: "calibri", sans-serif;
    padding: 18.5px 14px 18.5px 14px;
    border: 0px;
    caret-color: var(--cursor-color);
    .DraftEditor-editorContainer {
      height: 100%;
      .public-DraftEditor-content {
        height: 100%;
      }
    }
  }
}

.tb-react-select__wrapper {
  position: relative;
}

.MuiFormControl-root.invalid-value {
  text-decoration-color: var(--error) !important;

  input {
    color: var(--error);
  }
}

.picker-form-component {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.invalid-value {
  .input-container,
  .tb-react-select-multi__control {
    border: 1px solid var(--error) !important;
  }

  .text-form__paragraph,
  .tb-react-select__field-name {
    color: var(--error);
  }
}

.input-form__error-field,
.select-form__error-field,
.picker-form__error-field {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: -17px;
  left: 0px;
  gap: 4px;

  img {
    width: 15px;
    height: 15px;
  }

  p {
    font-family: Roboto;
    font-size: 11px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 14px !important;
    color: var(--error) !important;
    white-space: nowrap;
    margin: 0px !important;
    width: 100% !important;
  }
}

.planner-editor .editor-section .DraftEditor-root {
  height: 100% !important;
}

.problem-page {
  padding: 154px 75px 0px 154px;
  width: fit-content;
  height: 100%;
  color: var(--text-2);
  position: relative;

  &[lang="de"],
  &[lang="fr"],
  &[lang="es"],
  &[lang="it"] {
    max-width: 65% !important;
  }

  .problem-page__icon {
    right: -490px;
    bottom: 40px;
    position: absolute;

    @media (max-height: 800px) {
      // bottom: 40px;
    }
  }

  .main-text {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 40px;
    color: var(--text-1);
  }

  @media (max-height: 800px) {
    padding: 120px 0px 0px 90px;

    p {
      max-width: 85%;
    }
  }

  p,
  b {
    letter-spacing: 0.4px;
  }

  b {
    color: var(--text-1);
    font-weight: 700;
  }

  .secondary-text {
    font-size: 20px;
  }

  .problem-page__dash {
    background: var(--text-1);
    height: 5px;
    width: 5px;
    margin: 12px 10px 0 0;
    border-radius: 5px;
  }
}

.tb-role-picker__role-name {
  margin-bottom: 4px;
}

.tb-role-picker__role-description {
  font-size: 12px;
  letter-spacing: -0.4px;
  font-style: italic;
  white-space: wrap;
  margin: 0;
}

.teambook-blue-button,
.teambook-blue-button.MuiButton-root {
  border-radius: 7px;
  border: unset;
  transition: var(--transition-duration);
  background-color: var(--accent-dark);
  min-width: 90px;
  color: #fff;
  margin-left: unset;

  &:hover {
    background-color: var(--primary-btn-hover-bg);
  }

  &:active {
    background-color: var(--accent-dark);
    color: #fff;
  }

  &:disabled {
    background-color: var(--button-bg-disabled);
    color: var(--button-text-disabled);
  }

  &.no-animations {
    &:hover {
      background-color: var(--accent-dark);
    }

    &:active {
      background-color: var(--accent-dark);
      color: #fff;
    }
  }

  p {
    margin: 0;
    text-overflow: ellipsis;
    text-align: center;
    text-transform: unset !important;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    line-height: 100%;
  }
}

.notfound_img {
  position: absolute;
  left: 860px;
  top: 300px;

  @media (max-width: 1550px) {
    left: unset;
    right: 45px;
  }

  @media (max-height: 850px) {
    top: 250px;
  }
}

//TODO: Create Teambook internal buttons, instead of MUI buttons
.teambook-outlined-button,
.teambook-outlined-button.MuiButton-root {
  background-color: var(--background-1);
  border-radius: 7px;
  min-width: 90px;
  transition: all var(--transition-duration);
  border: 1px solid var(--stroke);
  color: var(--text-1);
  margin: unset;

  &:hover {
    background-color: var(--stroke);
  }

  &:active {
    border: 1px solid var(--stroke);
    background-color: var(--background-1);
  }

  &:disabled {
    color: var(--button-bg-disabled);
    border: 1px solid var(--background-2);
    background-color: var(--background-2);
  }

  p {
    margin: 0;
    font-weight: 400;
    text-transform: unset !important;
    text-align: center;
    line-height: 100%;
  }
}

.teambook-red-button {
  margin: 0;
  min-width: 90px;
  cursor: pointer;
  color: var(--error);
  border: 1px solid var(--background-1);
  background-color: var(--background-1);
  border-bottom: 1px solid var(--error);

  &:hover {
    border: 1px solid var(--error);
    color: var(--error);
    border-radius: 7px;
  }

  &:active {
    border: 1px solid var(--background-1);
    border-bottom: 1px solid var(--error);
    border-radius: 0px;
  }

  p {
    line-height: 100%;
    text-align: center;
    font-weight: 400;
    margin: 0;
  }
}

.teambook-borderless-button {
  margin: 0;
  min-width: 90px;
  cursor: pointer;
  color: var(--text-4);
  border: 1px solid var(--background-1);
  background-color: var(--background-1);
  border-bottom: 1px solid var(--text-4);

  &:hover {
    border: 1px solid var(--text-4);
    border-radius: 7px;
    color: var(--text-4);
  }

  &:active {
    border: 1px solid var(--background-1);
    border-bottom: 1px solid var(--text-4);
    border-radius: 0px;
  }

  &:disabled {
    color: var(--button-bg-disabled);
    border: 1px solid var(--background-1);
    border-bottom: 1px solid var(--button-bg-disabled);
  }

  p {
    line-height: 100%;
    text-align: center;
    font-weight: 400;
    margin: 0;
  }
}

.teambook-icon-button {
  margin: unset;
  width: 44px;
  height: 40px;
  padding: 8px 10px;
  cursor: pointer;
  border: 1px solid var(--stroke);
  background-color: var(--background-1);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: var(--stroke);
  }

  &:active {
    background-color: var(--background-1);
  }

  &:disabled {
    background-color: var(--background-2);
    border: 1px solid var(--background-2);
  }

  img {
    width: 24px;
    height: 24px;
  }
}

.teambook-icon-button__gray {
  background-color: var(--background-2);
}

.teambook-icon-button:disabled img {
  filter: brightness(0) saturate(100%) invert(86%) sepia(1%) saturate(10%) hue-rotate(180deg) brightness(90%)
    contrast(95%);
}

.teambook-white-button {
  border: 1px solid var(--stroke) !important;
  background-color: var(--background-1) !important;
  box-shadow: unset !important;
  height: 40px;
  font-size: 0.9rem !important;
  text-transform: unset !important;
  border-radius: 7px !important;

  &:hover {
    border: 1px solid var(--background-2) !important;
    background-color: var(--background-2) !important;
  }

  &:active {
    border: 1px solid var(--stroke) !important;
    background-color: var(--stroke) !important;
  }

  p {
    margin: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  div {
    background-color: transparent !important;
  }
}

.teambook-options-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3px;
  width: 100%;

  &.united {
    .teambook-options-line__option {
      &:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        border-right: unset;
      }

      &:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        border-left: unset;
      }
    }
  }

  &.splitted {
    gap: 10px;

    .teambook-options-line__option {
      border-radius: 8px;
      border: 1px solid var(--stroke);
    }
  }

  .teambook-options-line__option {
    user-select: none;
    flex: 1;
    background: var(--background-1);
    cursor: pointer;
    border: 1px solid var(--stroke);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42px;
    opacity: 0.8;

    img {
      margin-right: 10px;
      height: 24px;
      width: 24px;
    }

    p {
      color: var(--text-2);
      align-self: center;
      font-size: 16px;
      margin: 0;
      letter-spacing: 0.4px;
    }

    &.option-active {
      opacity: 1;
      background: var(--stroke);

      p {
        color: var(--text-1);
      }
    }
  }
}

.tb-react-select-multi__placeholder {
  text-align: left;
}

.tb-number-select-container__value {
  p {
    font-size: 16px;
    font-weight: 500;
    line-height: 120%;
  }
}

.tb-number-select-container__buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.input-number__dropdown__items {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  margin-right: 2px;
  overflow-y: auto;
  max-height: 100%;
  padding: 5px 2px 5px 0px;
}

.input-number__dropdown {
  position: absolute;
  background-color: var(--background-1);
  border: 1px solid var(--stroke);
  border-radius: 4px;
  z-index: 1000;
  height: 170px;
  padding: 5px 2px 5px 10px;
}

.input-number__container--dropdown {
  input {
    border-radius: 4px;
    cursor: pointer;
  }

  .input-number__arrows {
    height: 100%;
  }
}

.input-number__dropdown__item {
  padding: 10px 5px;
  border-radius: 7px;
  cursor: pointer;
  height: 34px;
  width: 40px;

  p {
    color: var(--text-1);
    text-align: center;
    font-weight: 400;
    line-height: 14px;
  }
}

.input-number__dropdown__item:hover {
  background-color: var(--stroke);
  border-radius: 7px;
}
