.tb-whats-new-dialog {
  .MuiPaper-root {
    border-radius: 20px;
    background-color: var(--background-1) !important;
    background-image: none;
  }

  .input-form__form-text {
    font-size: 14px;
  }

  .MuiDialogTitle-root {
    margin: 30px 40px 0px 40px;
    padding: 0px 0px 10px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 22px;
    color: var(--text-1);

    @media (max-width: 1100px) {
      margin: 15px 30px 0px 30px;
    }

    .MuiTypography-h6 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }

    p {
      margin: 0;
    }

    img {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }

  .MuiDialogContent-root {
    scrollbar-gutter: stable;
    overflow: overlay;
    padding: 0 22px 0 40px;
    margin: 10px 10px 20px 0;
    color: var(--text-2);

    @media (max-width: 1100px) {
      padding: 0px 30px;
      max-width: 100%;
    }
  }

  .MuiDialogContent-root::-webkit-scrollbar {
    width: 6px;
  }

  .MuiDialogActions-root {
    padding: 0px 40px 30px 40px;

    img {
      cursor: pointer;
    }
  }

  .MuiDialog-paper {
    max-width: 750px;
  }

  .whats-new-container {
    max-width: 670px;
    background-color: var(--background-2);
    border-radius: 7px;
    margin-bottom: 18px;

    .whats-new-element {
      display: flex;
      justify-content: space-between;

      p {
        margin-bottom: unset;
      }

      .title {
        padding: 6px 0px 8px 12px;
        font-size: 16px;
        font-weight: 600;
        min-width: 145px;
      }

      .date {
        padding-top: 6px;
        padding-right: 12px;
        text-align: end;
        opacity: 0.5;
        font-size: 14px;
        max-width: 100px;
        min-width: 100px;
      }
    }

    .description {
      font-size: 14px;
      font-weight: 400;
      margin: unset;
      padding-left: 12px;
      padding-bottom: 6px;
    }
  }

  .whats-new__header {
    margin: 0 auto;
    font-weight: 400;
  }
}
