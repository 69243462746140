.vat-bottom-control-bar {
  position: fixed;
  z-index: 5;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border-top: 1px solid #c8d2e6;
  bottom: 0;
}

.custom-quarter-picker {
  font-size: 1.2rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.75;
  letter-spacing: 0.00938em;

  .Quarter-dialog-title {
    background: #3f51b5;
    color: white;
  }

  .Quarter-dialog-content {
    width: 310px;
    height: 200px;
    padding: 0px !important;
  }

  .Quarter-dialog-quarterElement {
    height: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    cursor: pointer;

    &:hover {
      background: #e5e8f5;
    }
  }
}
