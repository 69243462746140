$wrong_components: "name" 1 0, "email" 2 245px, "phone_number" 3 465px;

.clients-modal {
  .MuiPaper-root {
    border-radius: 20px;
    padding: 30px 40px;
    max-width: 960px !important;
  }
}

.clients-modal__header {
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  p {
    color: var(--text-1);
    font-size: 22px;
    line-height: 21.6px;
    margin: 0;
  }

  img {
    cursor: pointer;
    width: 24px;
    height: 24px;
  }

  :nth-child(2) {
    margin-left: 10px;
  }

  :nth-child(3) {
    margin-left: 20px;
  }

  :last-child {
    margin-left: auto;
  }
}

.clients-modal__filter-input {
  height: 30px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 10px;

  border-radius: 3px;
  border: 1px solid var(--stroke);

  img {
    opacity: 0.6;
    width: 16px;
    height: 16px;
  }

  input {
    width: 198px;
    font-family: Montserrat;
    color: var(--text-2);
    height: 20px;
    font-size: 12px;
    line-height: 19px;
    letter-spacing: 0.15px;
    background: transparent;
    caret-color: var(--cursor-color);
    display: flex;
    align-items: center;
    border: none;
    outline: none;

    &::placeholder {
      color: var(--text-2);
      opacity: 0.5;
    }
  }
}

.clients-modal__content-titles {
  height: 40px;
  display: flex;
  align-items: center;
  gap: 20px;
  background-color: var(--background-2);
  border-radius: 7px;

  p {
    width: 200px;
    padding: 10px;
    margin: 0;
    font-size: 16px;
    color: var(--text-2);
    line-height: 120%;
    font-weight: 500;

    &:first-child {
      width: 225px;
      padding-left: 16px;
    }
  }
}

.clients-modal__list {
  width: 906px;
  margin-top: 10px;
  height: 240px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding-right: 19px;

  > * {
    margin-bottom: 10px;

    &.clients-modal__row.wrong-name,
    &.clients-modal__row.wrong-email,
    &.clients-modal__row.wrong-phone_number {
      margin-bottom: 0;
    }

    &.clients-modal__error-message {
      margin-top: 3px;
      margin-bottom: 10px;
    }
  }
}

.clients-modal__row {
  height: 44px;
  display: flex;
  gap: 20px;

  p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 200px;
    padding: 11px 10px 11px 16px;
    margin: 0;
    color: var(--text-2);
    font-size: 16px;
    line-height: 22px;

    &:first-child {
      width: 225px;
    }
  }

  input {
    width: 200px;
    padding: 0 16px;
    font-family: Montserrat;
    color: var(--text-2);
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.408px;
    display: flex;
    align-items: center;
    outline: none;
    border-radius: 8px;
    border: 1px solid var(--stroke);
    background: var(--background-1);
    caret-color: var(--cursor-color);
    &:focus {
      background-color: var(--background-2);
    }
    &:first-child {
      width: 225px;
    }
  }

  @each $name, $number in $wrong_components {
    &.wrong-#{$name} {
      input:nth-child(#{$number}) {
        border: 1px solid var(--error);
      }
    }
  }
}

.clients-modal__icons-container {
  display: flex;
  gap: 20px;
  margin-left: 16px;

  img {
    width: 24px;
    height: 24px;
  }

  .teambook-icon-frame {
    &.disable {
      background-color: var(--background-2);
      opacity: 0.4;
      border: 1px solid var(--background-2) !important;
    }
  }
}

.clients-modal__icon-frame {
  width: 44px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  border: 1px solid var(--stroke);
  cursor: pointer;

  &.disable {
    border: 1px solid var(--background-2);
    background: var(--background-2);
    cursor: default;
  }
}

.clients-modal__error-message {
  display: flex;
  gap: 5px;
  width: 200px;
  height: 32px;

  p {
    font-family: Roboto;
    font-weight: 400;
    font-size: 12px;
    font-style: normal;
    line-height: 16px;
    color: var(--error);
  }

  img {
    width: 16px;
    height: 16px;
  }

  @each $name, $number, $offset in $wrong_components {
    &.wrong-#{$name} {
      margin-left: $offset;

      @if $name == "email" {
        height: 16px;
      }
    }
  }
}
