.tb__text__black {
  color: #333333;
}

.tb__text__light-black {
  color: #444c63;
}

.tb__text__red {
  color: #f2545b;
}

.tb__text__white {
  color: #ffffff;
}

.tb__text__light-blue {
  color: #7fa6ce;
}

.tb__bg__light-grey {
  background-color: #f8f9fb;
}

.tb__bg__white {
  background-color: #ffffff;
}

.tb__bg__aqua {
  background-color: #f5f9fd;
}

p,
h1,
h2,
h3,
h4,
h5,
a,
span {
  font-family: Montserrat;
}
