.pdf-export-page {
  .planner-page__planner-control {
    margin: 0;
    position: fixed;
    top: 0;
    width: 100%;
    background-color: white;
    border-bottom: 1px solid #c8d2e6;
    z-index: 7;

    .planner-control__filter-text-field {
      display: flex;
      position: relative;
      font-size: 12px;

      @media (max-width: 1100px) {
        display: flex;
        align-items: center;
      }

      .planner-control__filter-icon {
        position: sticky !important;
        z-index: 5;
        left: 10px;
        top: 8px;
        width: 24px;
        height: 24px;
        cursor: pointer;

        @media (max-width: 640px) {
          position: relative;
          left: unset;
          top: unset;
        }
      }

      .input-form-component {
        margin: 0;
        border: 1px solid #c8d2e6;
        border-radius: 7px;
        padding: 14px 4px 14px 40px;
        width: 400px;
        font-family: "Montserrat";
        //line-height: 16px;
        font-size: 16px;
        &:focus,
        &:focus-visible {
          outline: none;
        }
      }
    }

    .planner-page__control-bar-first-line {
      width: 100%;
      display: flex;
      justify-content: space-between;
      height: 40px;
      margin: 10px 0 10px 0;
      padding: 0px 30px;
      gap: 15px;

      @media (max-width: 1100px) {
        margin: 10px 0 10px 0;
        padding: 0px 10px;
        gap: 3px;
        width: 100%;
        justify-content: space-around;
      }

      .calnedar-icon {
        display: none;
        @media (max-width: 1100px) {
          display: block;
          width: 24px;
          height: 24px;
          margin: auto;
        }
      }
    }

    .planner-page__control-bar-second-line {
      width: 100%;
      display: flex;
      justify-content: space-between;
      //position: fixed;
      height: 50px;

      @media (max-width: 1100px) {
        padding-right: 0px;
      }

      .input-form-component {
        margin: 0;
      }
    }

    .control-bar-second-line__whitespace {
      background-color: white;
      height: 57px;
      margin-top: 1px;
      display: flex;
      position: fixed;

      .planner-control__button {
        margin: 8px 15px 9px 0;
      }
    }

    .control-bar-second-line__whitespace.right {
      padding-left: 16px;
    }

    .control-bar-second-line__whitespace.left {
      padding-right: 16px;
    }

    .planner-control__right-side,
    .planner-control__left-side {
      display: flex;
      gap: 12px;

      @media (max-width: 1100px) {
        width: unset;
        gap: 10px;
      }
    }

    @media (max-width: 1100px) {
      flex-direction: column;
    }

    .planner-select {
      .MuiOutlinedInput-input {
        padding: 15px 18px;
        margin-right: 20px;
      }
    }

    .arrow-block__date-picker-container {
      //box-shadow: 0px 0px 7px 3px rgba(156, 156, 156, 0.3);
      border: 1px solid #c8d2e6;
      border-radius: 7px;
    }

    .planner-control__date-picker {
      width: 100px;

      @media (max-width: 640px) {
        width: 80px !important;
      }

      .MuiOutlinedInput-input {
        padding: 15px 18px;
        background-color: white;
        border-radius: 6px;
      }

      @media (max-width: 1100px) {
        width: 100px;

        .MuiPickersBasePicker-container {
          top: 0px !important;
        }
      }

      input {
        color: #4c54a0 !important;
        font-family: "Montserrat";
        font-size: 16px;
        display: flex;
        align-items: center;
        letter-spacing: 0.15px;
        text-align: center;
        box-sizing: border-box;
        border-radius: 4px;
        background-color: white;
        margin: 0 0 0 7%;
        height: 35px;
        cursor: pointer;

        &:hover {
          border: 0px;
        }

        .MuiPaper-root {
          top: 150px !important;
          left: 50px !important;
        }

        @media (max-width: 640px) {
          margin: 0px;
          font-size: 14px;
          width: 80px;
        }

        @media (min-width: 641px) {
          margin: 0;
        }
      }
    }
  }
}

.pdf-planner {
  position: absolute;
  top: 60px;

  .user-row {
    display: flex;
    height: 137px;
    overflow: hidden;

    .user-row__workday {
      border: 1px solid #c8d2e6;
      width: 72px;
    }

    .user-row__weekend {
      border: 1px solid #c8d2e6;
      background-color: #ececec;
      width: 20px;
    }
  }

  .date-row {
    height: 30px;
    display: flex;
    text-align: left;

    p {
      margin-left: 18px !important;
    }

    .date-row__date-text {
      font-size: 0.7rem;
      margin: unset;
      height: 30px;
      padding-top: 6px;
    }

    .date-row__date-text__today {
      font-size: 0.7rem;
      color: #ffffff;
      margin: unset;
      height: 30px;
      padding-top: 6px;
    }

    .date-row__weekend-cell {
      border: 1px solid #c8d2e6;
      background-color: #ececec;
      height: 29px;
      padding: 0;
      position: -webkit-sticky; /* for Safari */
      position: sticky;
      top: 30px;
      z-index: 2;
      width: 20px;
    }

    .date-row__date-cell__today {
      height: 29px;
      padding: 0;
      position: -webkit-sticky; /* for Safari */
      position: sticky;
      top: 57px;
      background-color: #b0b8ff;
      box-shadow: 0 1px #c8d2e6;
      cursor: pointer;
      z-index: 2;
    }
  }

  .user-block {
    width: 125px;
    height: 137px;
    border: 1px solid #c8d2e6;
  }

  .date-row__splitter {
    width: 125px;
  }

  .booking {
    display: flex;
    justify-content: space-between;
    width: 71px;
    padding: 2px 2px 2px 6px;

    .booking-line {
      height: 100%;
      position: absolute;
      left: 0px;
      top: 0px;
    }

    p {
      font-size: 10px;
      color: white;
    }
  }

  .pdf-blocked {
    width: 72px;
  }
}

.pdf-month-row {
  display: flex;
  height: 40px;
  border-bottom: 1px solid #c8d2e6;

  p {
    margin: 0;
    color: #8c97b2;
    font-size: 0.9rem;
  }

  .pdf-month-row__cell {
    height: 40px;
    display: flex;
    align-items: center;
  }
}
