$darkblue: #4c54a0;

.control-bar .react-datepicker-wrapper {
  align-self: center;
}

.control-bar .react-datepicker-wrapper,
.control-bar .react-datepicker__input-container {
  display: block;
}

.react-datepicker-wrapper .react-datepicker__input-container input {
  width: 67.5%;
}

//.MuiSelect-outlined {
//  padding: 15px 10px !important;
//}

.dashboard-date-select {
  .MuiOutlinedInput-input {
    padding: 11px 10px 10px 10px !important;
    font-family: Montserrat;
    font-size: 14px;
  }
  .MuiOutlinedInput-notchedOutline {
    background: unset !important;
  }
  .jss1 {
    background: unset !important;
  }
}

.select-field {
  .MuiOutlinedInput-input {
    padding: 15px 20px 15px 18px !important;
    color: #7fa6ce;
  }
}

.MuiOutlinedInput-input {
  color: #516f90;
}

.MuiList-padding {
  padding: unset !important;
}

.overview-row {
  display: flex;
  min-height: 38px;
  border-top: 1px solid var(--stroke);
  border-bottom: 1px solid var(--stroke);
  border-right: 1px solid var(--stroke);
  background-color: var(--background-1);
  position: sticky;
  top: 0;

  p {
    margin: unset;
    color: var(--text-2);
    opacity: 0.7;
    font-size: 14px;
  }

  .side-columns {
    width: 15%;
  }

  .data-columns {
    width: 10%;
  }

  div {
    padding: 6px 0 6px 0;
  }
}

.compare-colors {
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  height: 50px;
  background: var(--background-2);
  top: 120px;
  left: 0px;
  right: 0px;
  margin: 0 auto;
  color: var(--text-2);
}

#tooltip-round {
  border-radius: 50% !important;
}

.chart__range-switcher {
  position: absolute;
  gap: 15px;
  font-size: 12px;
  right: 32px;
  display: flex;

  p {
    letter-spacing: 0.4px;
    cursor: pointer;
    white-space: nowrap;
  }

  ul {
    p {
      color: var(--text-2) !important;
    }
  }
}

.compare-colors__color-wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
  z-index: 1;

  p {
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 10px;
    margin: 0px;
    user-select: none;
  }
}

.dashboard__button-wrapper {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--stroke);
  border-radius: 7px;
  height: 40px;
  width: 44px;
}

.reporting__control-bar {
  height: 60px;
  background-color: transparent;
  position: fixed;
  top: 60px;
  width: 100%;
  z-index: 2;
  display: flex;
  align-items: center;
  padding: 0 30px;
  gap: 40px;

  .reporting__control-bar__button {
    border: 1px solid #c8d2e6;
    color: #7fa6ce;
    background: transparent;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    margin: 0px;
    border-radius: 4px;
    font-size: 14px;
    display: none;
    &:hover {
      border-color: black;
    }
    @media (max-width: 1100px) {
      display: block;
      flex-wrap: nowrap;
      width: 40px;
      height: 40px;
    }
  }
  .flex {
    display: flex;
    align-items: center;
    gap: 15px;
    height: 24px;
  }

  @media (max-width: 640px) {
    display: flex;
    justify-content: space-around;
    padding: 0px;

    .reporting__button-row {
      width: 100%;
      justify-content: space-around;
    }
  }

  .MuiFormControl-root {
    vertical-align: unset !important;
  }

  .MuiInputBase-input {
    font-size: 14px;
  }

  .dashboard-date-select {
    .MuiOutlinedInput-root {
      border-color: var(--stroke);
      border-radius: 7px;
      color: $darkblue;
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: var(--stroke);
      border-radius: 7px;
    }
  }
}

.reporting__date-picker {
  margin-top: 4px;
  margin-left: 15px;
  max-width: 240px;
  height: 40px;

  .MuiOutlinedInput-input {
    padding: 9.5px 14px 7px 14px;
    color: $darkblue;
  }

  .MuiOutlinedInput-notchedOutline {
    border: 1px solid var(--stroke);
    border-radius: 7px;
  }
}

.reporting-split-select__container {
  min-width: 170px;
  max-width: 170px;
  height: 42px;
  flex: 1;

  .tb-react-select__single-value {
    color: $darkblue;
  }

  @media (max-width: 640px) {
    font-size: 14px;
  }
}

.reporting__table-wrapper {
  position: fixed;
  top: 120px;
  right: 30px;
  width: calc(100% - 30px);

  .month-row {
    margin: 0 30px;
    height: 50px;
    width: calc(100% - 30px);
    position: relative;

    display: flex;

    p {
      color: var(--button-outline-text-default);
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 18px;
      margin: auto;
      font-family: Montserrat;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      letter-spacing: 0.4px;
      text-transform: capitalize;
      height: 14px;
      width: fit-content;
    }
  }
}

.reporting__user-initials {
  font-size: 10px;
  margin: 0px;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  border-radius: 100px;
  background: var(--accent-light);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-2);
}

.reporting__table-content {
  overflow: auto;
  position: fixed;
  // height: fit-content;
  max-height: calc(100% - 111px);
  right: 0px;
  width: calc(100% - 60px);
  left: 30px;
  right: 30px;
  // border-left: 1px solid var(--stroke);
  top: 111px;

  .main-reporting-table {
    border-collapse: separate;
    border-spacing: 0;

    .main-row {
      border-bottom: 1px solid var(--stroke);
    }

    .fixed-column {
      position: sticky;
      position: -webkit-sticky;
      left: 0;
      background-color: var(--background-1);
      z-index: 1;
      min-width: 170px;
      max-width: 170px;
      height: 44px;

      gap: 5px;
      padding-left: 10px;

      &.padded {
        padding: 10px;
      }

      .reporting__fixed-user-row {
        text-align: left;
        color: var(--text-2);
        font-size: 14px;
        font-style: normal;
      }

      p {
        color: var(--text-2);
        text-overflow: ellipsis;
        overflow: hidden;
        width: 90%;
        white-space: nowrap;
        margin-left: 5px;
        display: block;
      }
    }

    .main-table__users-row {
      padding-left: 0px !important;
      gap: 5px;
      background: unset;
      min-width: 14px;
      max-width: 14px;

      &.saturday {
        color: var(--accent-light);
        position: relative;
      }

      &.sunday {
        position: relative;
        color: var(--accent-light);
        border-right: 1px solid var(--stroke);
      }
    }

    .main-table__project-row {
      min-width: 14px;
      max-width: 14px;
      background-color: var(--background-2);

      &.saturday {
        color: var(--accent-light);
        position: relative;
      }

      &.sunday {
        position: relative;
        color: var(--accent-light);
        border-right: 1px solid var(--stroke);
      }
    }

    .fixed-total-color {
      position: sticky;
      right: 0;
      z-index: 1;
      min-width: 70px;
      max-width: 70px;
      border-left: 1px solid var(--stroke);
      border-right: 1px solid var(--stroke);
      height: 32px;
      background-color: var(--background-1);

      p {
        color: var(--text-2);
        font-weight: 700;
      }
    }

    .main-row__total {
      background: var(--background-2);
    }

    .reporting-week-row {
      position: sticky;
      top: 0;
      z-index: 3;
      background-color: var(--background-1);
    }

    .totals-columns {
      min-width: 70px;
      max-width: 70px;
      position: sticky;
      right: 0;
      z-index: 1;
      background-color: var(--background-1);
      border-left: 1px solid var(--stroke);

      .totals-div__fixed-text {
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--background-1);
        border-right: 1px solid var(--stroke);
        width: 100%;
        position: absolute;
        height: 76px;
        top: 0px;
        left: 0px;
        z-index: 1000;
        border-top: 1px solid var(--stroke);
        border-bottom: 1px solid var(--stroke);
      }

      p {
        color: var(--button-outline-text-default);
        margin: 0;
      }
    }

    .week-text {
      margin: unset;
      color: var(--button-outline-text-default);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.4px;
      text-transform: capitalize;
      overflow: unset;
      white-space: nowrap;
    }

    .week-row__fixed-column {
      position: sticky;
      left: 0;
      background-color: var(--background-1);
      z-index: 1;
      min-width: 120px;
      max-width: 120px;
      height: 32px;

      p {
        margin: unset;
        font-size: 14px;
      }
    }

    .week-row__table-column {
      min-width: 60px;
      max-width: 60px;
      white-space: nowrap;
      border-top: 1px solid var(--stroke);

      p {
        font-size: 14px;
      }
    }

    .entity-day-row__totals-column {
      min-width: 76px;
      max-width: 70px;
      position: sticky;
      right: 0;
      z-index: 1;
      background-color: var(--background-1);
      border-left: 1px solid var(--stroke);
    }

    .entity-day-row__main-row {
      position: sticky;
      top: 104px;
      z-index: 2;
    }

    .entity-day-row__day-text {
      margin: unset;
      font-size: 11px;
    }

    .entity-day-row__fixed-column {
      position: sticky;
      left: 0;
      background-color: var(--background-1);
      z-index: 11;
      min-width: 120px;
      max-width: 120px;
      height: 32px;
      border-top: 1px solid var(--stroke);
      border-right: 1px solid var(--stroke);
      border-bottom: 1px solid var(--stroke);

      p {
        margin: unset;
        font-size: 14px;
      }
    }

    .entity-day-row__table-column {
      min-width: 14px;
      max-width: 14px;

      background-color: var(--background-1);
      position: relative;
      border-top: 1px solid var(--stroke);
      border-bottom: 1px solid var(--stroke);
    }

    .bottom-total__totals-column {
      min-width: 76px;
      max-width: 76px;
      height: 59px;

      position: sticky;
      right: 0;
      z-index: 1;
      background-color: var(--background-1);
      border-left: 1px solid var(--stroke);
      border-right: 1px solid var(--stroke);
      border-top: 1px solid var(--stroke);
      border-bottom: 1px solid var(--stroke);
    }

    .bottom-total__row {
      position: sticky;
      bottom: 0;
      z-index: 2;
      background-color: var(--background-1);

      p {
        color: var(--text-2);
        font-weight: 500;
      }
    }

    .bottom-total__day-text {
      margin: unset;
      color: var(--text-2);
    }

    .bottom-total__fixed-column {
      position: sticky;
      left: 0;
      background-color: var(--background-1);
      border-left: 1px solid var(--stroke);
      border-top: 1px solid var(--stroke);
      border-bottom: 1px solid var(--stroke);

      z-index: 1;
      min-width: 120px;
      max-width: 120px;
      height: 59px;

      p {
        text-align: left;
        margin: 0px 0px 0px 10px;
        font-size: 14px;
        font-weight: 500;
      }
    }

    .bottom-total__table-column {
      min-width: 14px;
      max-width: 14px;
      border-top: 1px solid var(--stroke);
      border-bottom: 1px solid var(--stroke);

      p {
        font-size: 14px;
      }
    }
  }

  .column-text {
    margin: unset;
    color: var(--text-2);
    font-size: 14px;

    &.fs-16 {
      font-size: 16px !important;
    }

    &.weekend {
      color: var(--accent-light) !important;
    }
  }

  .compare__table-column {
    min-width: 14px;
    max-width: 14px;
    padding: 0px;
    border-bottom: 1px solid var(--stroke);

    p {
      font-size: 14px;
    }

    .column-day-text {
      margin: unset;
      color: var(--text-2);
    }
  }

  .bottom-total-row {
    position: sticky;
    bottom: 0;
    height: 72px;
    z-index: 2;
    background-color: var(--background-1);

    p {
      font-size: 16px;
      font-weight: 500;
    }
  }

  .compare__fixed-column {
    position: sticky;
    left: 0;
    background-color: var(--background-1);
    z-index: 1;
    min-width: 120px;
    max-width: 120px;
    height: 59px;
    border: 1px solid var(--stroke);

    p {
      color: var(--text-2);
      text-align: left;
      margin: 0px 0px 0px 10px;
      font-size: 16px;
    }
  }

  .compare-reporting-table {
    border-collapse: separate;
    border-spacing: 0;

    .table-column {
      min-width: 14px;
      max-width: 14px;
      border-top: 1px solid var(--stroke);
      padding: 0;
    }

    .compare__planning-data {
      font-size: 14px;
      background: rgba(76, 84, 160, 0.1);
    }

    .compare__actual-data {
      font-size: 14px;
      color: var(--text-2);
    }

    .total_column {
      padding: 0;
      position: sticky;
      right: 0;
      z-index: 1;
      min-width: 70px;
      max-width: 70px;
      border-right: 1px solid var(--stroke);
      border-left: 1px solid var(--stroke);
      height: 32px;
      background-color: var(--background-1);

      p {
        font-weight: 700;
      }
    }

    .day-row__main-row {
      position: sticky;
      top: 104px;
      z-index: 2;

      .day-row__day-text {
        margin: unset;
        font-size: 11px;
      }

      .day-row__fixed-column {
        position: sticky;
        left: 0;
        background-color: var(--background-1);
        z-index: 11;
        min-width: 120px;
        max-width: 120px;
        height: 32px;
        border-left: 1px solid var(--stroke);
        border-right: 1px solid var(--stroke);
        border-top: 1px solid var(--stroke);

        p {
          margin: unset;
          font-size: 14px;
        }
      }

      .day-row__table-column {
        min-width: 14px;
        max-width: 14px;

        background-color: var(--background-1);
        position: relative;
      }

      .compare-reporting__totals-column {
        min-width: 76px;
        max-width: 76px;
        position: sticky;
        right: 0;
        z-index: 1;
        background-color: var(--background-1);
        border-left: 1px solid var(--stroke);
      }
    }

    .compare-reporting__week-row {
      position: sticky;
      top: 0;
      z-index: 3;
      background-color: var(--background-1);
    }

    .compare-reporting__totals-column {
      min-width: 70px;
      max-width: 70px;
      position: sticky;
      right: 0;
      z-index: 1;
      background-color: var(--background-1);
      border-left: 1px solid var(--stroke);

      .totals-div__fixed-text {
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--background-1);
        width: 100%;
        position: absolute;
        height: 76px;
        top: 0px;
        left: 0px;
        z-index: 1000;
        border-top: 1px solid var(--stroke);
        border-right: 1px solid var(--stroke);
      }

      p {
        color: var(--text-2);
        margin: 0px;
      }
    }

    .compare-reporting__week-text {
      margin: unset;
      color: var(--button-outline-text-default);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.4px;
      text-transform: capitalize;
    }

    .compare-reporting__fixed-column {
      position: sticky;
      left: 0;
      background-color: var(--background-1);
      z-index: 1;
      min-width: 120px;
      max-width: 120px;
      height: 32px;
      border-top: 1px solid var(--stroke);

      p {
        margin: unset;
        font-size: 14px;
      }
    }

    .compare-reporting__table-column {
      min-width: 60px;
      max-width: 60px;
      white-space: nowrap;
      border-top: 1px solid var(--stroke);

      p {
        font-size: 14px;
      }
    }
  }
}

.greyed {
  background-color: var(--capacity-inner-content);
}

.dashboard__tb-button {
  border: 1px solid var(--stroke);
  color: var(--button-outline-text-default);
  background: var(--background-1);
  padding: 8px 10px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  margin: 0;
  border-radius: 7px;
  cursor: pointer;
  height: 40px;
  min-width: 40px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;

  &.view-switch {
    width: 44px;
  }

  p {
    margin: 0px;
  }

  @media (max-width: 640px) {
    margin: 0px !important;
    padding: 8px 4px;
    font-size: 14px;
    white-space: nowrap;
  }
}

.reporting-control-bar__action-buttons {
  display: flex;
  gap: 12px;
}

.dashboard__tb-reports-button {
  border: 1px solid var(--stroke);
  color: var(--button-outline-text-default);
  padding: 8px 12px;
  font-style: normal;
  border-radius: 7px;
  cursor: pointer;
  height: 40px;
  min-width: 40px;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;

  p {
    margin: 1px 0px 0px 5px;
    letter-spacing: 0.4px;
  }

  @media (max-width: 640px) {
    margin: 0px !important;
    padding: 8px 4px;
    font-size: 14px;
    white-space: nowrap;
  }
}

.dashboard__tb-dashboard-button {
  border: 1px solid var(--stroke);
  color: var(--button-outline-text-default);
  padding: 8px 12px;
  font-style: normal;
  border-radius: 7px;
  cursor: pointer;
  height: 40px;
  min-width: 40px;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;

  p {
    margin: 1px 0px 0px 5px;
    letter-spacing: 0.4px;
  }

  @media (max-width: 640px) {
    margin: 0px !important;
    padding: 8px 4px;
    font-size: 14px;
    white-space: nowrap;
  }
}

.dashboard {
  .reporting__headline__name {
    padding: 0px 30px 0px 15px;
  }

  .reporting__headline__name,
  .users-block__headline__user-name {
    min-width: 399px;
    max-width: 399px;
    border-left: 1px solid var(--stroke);
  }

  .users-block__headline__user-name {
    padding-left: 15px;
    border-right: unset;

    .dashboard__tb-button {
      background: var(--background-2) !important;
    }

    .dark {
      width: 200px !important;
      max-width: 200px !important;
      min-width: 200px !important;
      text-align: left;
    }
  }
}

.reporting__overview-page {
  position: fixed;
  height: calc(100% - 175px);
  top: 130px;
  bottom: 0;
  left: 30px;
  right: 0px;
  width: calc(100% - 60px);
  overflow-x: hidden;
}

.overview-title__project {
  height: 44px;
}

.overview-title__empty {
  height: 75px;
}

.fixed-column__content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.reporting__fixed-project-column {
  position: sticky;
  position: -webkit-sticky;
  left: 0;
  z-index: 1;
  min-width: 170px;
  max-width: 170px;
  height: 54px;
  background-color: var(--background-2);
  padding-left: 10px;
  gap: 10px;

  .flex {
    gap: 10px;
    width: 100%;
    height: 100%;
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  p {
    width: 120px;
    overflow: hidden;
    color: var(--text-2);
    text-overflow: ellipsis;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }
}

.reporting__column-text {
  margin: unset;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
}

.reporting__sort {
  display: flex;
  background-color: var(--background-1);
  gap: 15px;
}

.reporting__sort-switch {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  border: 1px solid var(--stroke);
  width: 44px;
  height: 40px;
  margin-right: 15px;
  cursor: pointer;

  img {
    // width: 20px;
    // height: 20px;
    cursor: pointer;
  }

  svg {
    cursor: pointer;
    width: 24px;
    height: 24px;

    path {
      stroke: var(--button-outline-text-default);
    }
  }
}

.chart-container {
  position: relative;
  width: 80%;
  height: 410px;
  border: 1px solid #9199e7;
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 0 10px 0 10px;

  @media (max-width: 640px) {
    width: 100%;
    margin: 0 auto;
  }

  .range-selection {
    position: absolute;
    display: flex;
    top: 3px;
    right: 10px;
    color: #d0d0d0;
    font-size: 14px;

    p {
      margin-right: 10px;
      cursor: pointer;

      &[data-active="true"] {
        color: $darkblue;
      }
    }
  }
}

.dashboard-component {
  position: absolute;
  top: 120px;
  background: var(--background-2);
  padding: 20px 30px 0px 30px;

  @media (max-width: 640px) {
    top: 130px;
  }
}

.tops-tooltip {
  display: flex;
  flex-direction: column;

  .tops-tooltip__planned-hours {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    color: var(--text-2);
    margin: 5px 0px 0px 0px;
  }

  .tops-tooltip__name {
    display: flex;
    align-items: center;
    gap: 5px;

    p {
      color: var(--text-2);
      margin: 0px;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 15px;
      opacity: 0.6;
    }
  }

  .tops-tooltip__circle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
}

.percentage__wrapper {
  width: 25%;
  background: var(--background-1);
  position: relative;
  border-radius: 10px;
  border: 1px solid var(--stroke);

  svg {
    margin-top: 12px;
  }

  p {
    color: var(--text-2);
    margin: 20px 0 0 20px;
    text-align: left;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 19.2px */
    text-transform: capitalize;
  }
}

.percentage__circles {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 30px;
}

.dashboard__first-line {
  margin-bottom: 20px;
}

.dashboard__first-line,
.dashboard__second-line {
  display: flex;
  gap: 20px;
}

.dashboard-bars-container {
  border: 1px solid var(--stroke);
  flex: 1;
  height: 430px;
  min-width: 480px;
  max-width: 480px;
  background: var(--background-1);
  border-radius: 10px;
  border: 1px solid var(--stroke);
  display: flex;
}

.percentage-container {
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 20px 30px;
  min-width: 330px;

  @media (max-width: 640px) {
    margin: 0 auto;
  }
  height: 385px;
}

.dashboard__tb-button.percentage {
  color: var(--text-9);
  &:hover {
    background-color: var(--stroke);
  }
}

.dashboard__bars {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 70px 20px 60px 50px;
  position: relative;
}

.dashboard-bars__grid {
  width: 100%;
  height: 100%;
  position: relative;
}

.dashboard-grid__hours {
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;

  .grid-row {
    width: calc(100% - 38px);
    height: 1px;
    position: absolute;
    background: var(--background-2);
    top: 5px;
    left: 38px;
  }

  .dashboard-grid__hour {
    direction: rtl;
    margin-bottom: 26px;
    width: 36px;
    text-align: center;
    font-family: Montserrat;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 10px;
    color: var(--text-2);
    white-space: nowrap;
  }
}

.dashboard-bars__bars-wrapper {
  display: flex;
  padding-left: 25px;
  position: absolute;
  bottom: 0px;
  align-items: flex-end;
  height: 100%;
  width: 100%;
}

.dashboard-bars__bar-name {
  color: var(--text-2);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 10px;
  white-space: nowrap;
  text-align: center;
  user-select: none;
}

@media (max-width: 1550px) {
  .dashboard-bars__bar-name {
    font-size: 11px;
  }
}

.tops-container {
  border: 1px solid #9199e7;
  border-radius: 10px;
  padding: 6px 10px 20px 10px;
  height: 300px;

  @media (max-width: 640px) {
    padding: 0;
    margin-top: 30px;
  }
}

.dashboard-tops {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: var(--background-1);
  padding: 20px;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border-radius: 10px;
  border: 1px solid var(--stroke);
  justify-content: center;
  min-width: 480px;
  max-width: 480px;
}

.dashboard-tops__rows {
  display: flex;
  flex-wrap: wrap;
  gap: 35px;
  width: 100%;
  margin-top: 20px;
}

.dashboard-tops__row {
  display: flex;
  align-items: center;
  width: 100%;
  height: 24px;
  margin-top: -10px;
  gap: 30px;

  p {
    text-align: center;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    left: 0px;
    right: 0px;
    color: var(--text-2);
    user-select: none;
    margin: 10px auto 0px auto;
    opacity: 0.7;
  }
}

.dashboard__chart {
  background-color: var(--background-1);
  border-radius: 10px;
  border: 1px solid var(--stroke);
  padding: 60px 20px 20px 20px;
}

.dashboard-tops__block {
  width: 33%;
  text-align: center;
  position: relative;
  height: 20px;
}

#legend-container {
  margin: 0 auto;

  div {
    flex-wrap: wrap;
  }

  p {
    font-size: 12px;
  }

  span {
    width: 12px !important;
    height: 12px !important;
    border-radius: 50%;
  }
}

.dashboard-bars__title {
  color: var(--text-2);
  font-size: 16px;
  text-align: left;
  position: absolute;
  left: 20px;
  top: 20px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 19.2px */
  text-transform: capitalize;
}

.dashboard-tops__title {
  color: var(--text-3);
  font-size: 16px;
  text-align: left;
  position: absolute;
  left: 20px;
  top: 20px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 19.2px */
  text-transform: capitalize;

  &[data-active="true"] {
    color: var(--text-2);
  }
  &[data-active="false"] {
    cursor: pointer;
  }
}

.dashboard-tops__low-title {
  color: var(--text-3);
  font-size: 16px;
  text-align: left;
  position: absolute;
  right: 20px;
  top: 20px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 19.2px */
  text-transform: capitalize;

  &[data-active="true"] {
    color: var(--text-2);
  }
  &[data-active="false"] {
    cursor: pointer;
  }
}

.active-container {
  padding: 0;
  height: 300px;
  border: 1px solid #9199e7;
  border-radius: 10px;

  @media (max-width: 640px) {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
  }
}

.reporting-control__filter-text-field {
  display: flex;
  position: relative;
  font-size: 12px;
  width: 37.5%;
  height: 40px;

  .planner-filter-row {
    margin-left: 0px;
  }

  @media (max-width: 1100px) {
    display: flex;
    align-items: center;
  }

  .planner-control__filter-icon {
    position: sticky !important;
    z-index: 5;
    left: 10px;
    top: 8px;
    cursor: pointer;

    @media (max-width: 640px) {
      position: relative;
      left: unset;
      top: unset;
    }
  }

  .input-form-component {
    margin: 0;
    border: 1px solid var(--stroke);
    border-radius: 7px;
    padding: 14px 4px 14px 40px;
    width: 400px;
    font-family: "Montserrat";
    //line-height: 16px;
    font-size: 16px;
    &:focus,
    &:focus-visible {
      outline: none;
    }
  }
}

.tb-default-dialog.export {
  .MuiPaper-root {
    width: 400px;
  }
}

.dashboard__view-switch__option {
  &:first-child {
    border-radius: 10px 10px 0px 0px;
  }

  &:last-child {
    border-radius: 0px 0px 10px 10px;
  }
}

.dashboard__view-switch__portal {
  z-index: 10000;
}

.dashboard__view-switch__menu-list {
  padding: 0px !important;
}

.dashboard__view-switch__control {
  border-radius: 7px !important;
  outline: none;

  .dashboard__view-switch__single-value {
    color: var(--button-outline-text-default);
    font-size: 14px;
  }

  .dashboard__view-switch__indicator-separator {
    display: none;
  }

  * {
    font-family: Montserrat;
  }
}

.dashboard__view-switch__menu {
  border-radius: 10px !important;
}

.dashboard__control-bar {
  height: 60px;
  border-bottom: 1px solid var(--stroke);
  background-color: var(--background-1);
  position: fixed;
  top: 60px;
  width: 100%;
  z-index: 2;
  margin: unset !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1100px) {
    justify-content: center;
    flex-wrap: nowrap;
  }
  flex-wrap: nowrap;
  gap: 30px;
  padding: 0 30px;
}

.dashboard-control-bar__right-side {
  .tb-react-select__single-value {
    color: var(--text-9) !important;
  }
}

.dashboard__control-bar_wrapper {
  .planner-control__filter-text-field {
    margin-left: 20px;
  }
}

.dashboard__control-bar-datepicker {
  display: flex;
  gap: 20px;
}

.dashboard__control-bar_wrapper {
  height: 40px;
  width: 100%;
  display: flex;
  gap: 20px;
  position: relative;

  .arrow-block__date-picker-container {
    height: 40px !important;
  }
}

.reporting-type-button {
  display: flex;
  color: var(--text-9);
  gap: 5px;
  height: 40px;

  border-radius: 7px;
  border: 1px solid var(--stroke);
  align-items: center;
  padding: 0px 10px;
  cursor: pointer;
  position: relative;

  img {
    width: 24px;
  }

  p {
    font-size: 14px;
    margin: 0px;
  }
}

.reporting-type-dropdown {
  position: absolute;
  top: 44px;
  left: 0px;
  width: 200px;
  height: fit-content;
  background: var(--background-1);
  border-radius: 10px;
  border: 1px solid var(--stroke);
}

.reporting-type-dropdown__group {
  p {
    padding: 10px 10px 10px 20px;
    text-align: left;
    color: var(--text-2);
    &:hover {
      background: var(--background-2);
    }
  }

  color: var(--text-2);
}

.reporting-type-dropdown__group-name {
  padding: 10px;
  text-align: left;
  color: var(--text-2);
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    color: $darkblue;
  }
}

.reporting-link__active {
  .reporting-type-dropdown__group-name {
    &:hover {
      background: var(--background-2);
    }
  }
}

.reporting-link__not_active {
  .reporting-type-dropdown__group-name {
    &:hover {
      background: var(--background-2) !important;
    }
  }
}

.group-element__active,
.group-element__not_active {
  display: flex;
  align-items: center;
  gap: 10px;
}

.group-element__not_active {
  .reporting-type-dropdown__group-name {
    &:hover {
      background: var(--stroke) !important;
    }
  }
}

.compare__table-cell {
  padding: 0;
  margin: unset;

  .compare__actual-data,
  .compare__planning-data {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 36px;
    color: var(--text-2);
  }
}

.overview-table {
  .overview-table__header {
    p {
      opacity: 1;
      color: var(--button-outline-text-default) !important;
      text-transform: capitalize;
    }
  }

  .overview-table__subheader {
    height: 32px;
  }

  .overview-table__subheader-wrapper {
    width: 100%;
    display: flex;

    p {
      width: 50%;
      align-items: center;
    }
  }

  p {
    margin: 0px;
    opacity: 0.7;
  }

  tr,
  th,
  td {
    border-left: unset;
  }

  th,
  td,
  tr {
    font-family: Montserrat;
    border-top: 1px solid var(--stroke);
    border-right: 1px solid var(--stroke);
    border-bottom: 1px solid var(--stroke);
    font-size: 14px;
    font-weight: 400;
    padding: 0px;

    p {
      color: var(--text-2);
    }
  }
}

.overview-table__data {
  display: flex;
  font-family: Montserrat;
  color: var(--text-2) !important;

  .overview-table__entity-name {
    width: 15%;
    overflow: hidden;
    gap: 5px;
    text-align: left;
    border-bottom: 1px solid var(--stroke);
    border-left: 1px solid var(--stroke);
    height: 44px;
    display: flex;
    align-items: center;

    padding-left: 10px;
    font-size: 14px;
  }

  .overview-table__entity-tentative,
  .overview-table__entity-billable,
  .overview-table__entity-time-off,
  .overview-table__entity-time-off-tentative {
    background: var(--background-2);
  }

  .overview-table__entity-tentative,
  .overview-table__entity-billable-tentative,
  .overview-table__entity-time-off-tentative,
  .overview-table__entity-availability,
  .overview-table__entity-name,
  .overview-table__entity-capacity {
    border-right: 1px solid var(--stroke);
  }

  .overview-table__entity-capacity,
  .overview-table__entity-tentative,
  .overview-table__entity-billable,
  .overview-table__entity-non-billable,
  .overview-table__entity-billable-tentative,
  .overview-table__entity-time-off,
  .overview-table__entity-time-off-tentative,
  .overview-table__entity-availability {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid var(--stroke);
  }

  .overview-table__entity-capacity {
    width: calc(10%);
    border-bottom: 1px solid var(--stroke);
  }

  .overview-table__entity-billable,
  .overview-table__entity-tentative,
  .overview-table__entity-non-billable,
  .overview-table__entity-billable-tentative,
  .overview-table__entity-time-off,
  .overview-table__entity-time-off-tentative {
    width: calc(10%);
  }

  .overview-table__entity-availability {
    width: 15%;
  }
}

.satriun-overview-header {
  display: flex;
  height: 44px;
  align-items: center;
  border-left: 1px solid var(--stroke) !important;

  p {
    width: 10%;

    vertical-align: middle;
    margin: 0px;
  }
}

.compare__planning-data {
  justify-content: center;
  background: rgba(76, 84, 160, 0.1);

  p {
    margin: unset;
  }
}

.compare__planning-data,
.compare__actual-data {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Montserrat;
  height: 36px;
}

.reporting-capacity__component {
  position: fixed;
  top: 111px;
  left: 30px;
  bottom: 0;
  right: 0;
  overflow-x: scroll;
  overflow-y: hidden;
  background-color: var(--background-1);

  .table-cell {
    border-bottom: none;
  }

  input {
    text-align: center;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
}

.reporting-block__dates-row {
  display: flex;
  align-items: center;
  height: 60px;
  position: sticky;
  top: 0;

  background-color: var(--background-1);
  z-index: 11;
  border-bottom: 1px solid var(--stroke);
  width: fit-content;
  min-width: 100%;

  &.drilled-down {
    .left-side {
      height: 36px;
      background-color: var(--background-1);
      border-bottom: 1px solid var(--stroke);
      border-left: 1px solid var(--stroke);
    }
  }

  .right-side {
    width: 100%;

    .year {
      color: var(--button-outline-text-default);
    }
  }

  .table-cell {
    padding: 13px 0;

    p {
      font-size: 14px;
    }
  }

  .left-side {
    min-width: 400px;
    max-width: 400px;
    display: flex;
    z-index: 2;
    position: sticky;
    left: 0;
  }

  .current-month {
    background: rgb(218, 223, 234);
    color: rgb(76, 84, 160);
  }

  .sorted-month {
    background: var(--background-2);
  }
}

.reporting-tags {
  .reporting__headline__name {
    margin: 0px;
  }

  .reporting__headline__name,
  .named-row-project {
    max-width: 400px;
    min-width: 400px;
    width: 400px;

    p {
      width: 175px;
      max-width: 175px;
      min-width: 175px;
    }
  }

  .left-side {
    max-width: 400px !important;
    min-width: 400px !important;
  }

  .right-side {
    padding-left: 0px !important;
  }

  .users-block__dates-row {
    .table-cell {
      border: unset;
    }
  }
}

.reporting__sorting-arrow {
  height: 16px;
}

.table-cell__hovered {
  &:hover {
    .reporting__sorting-arrow {
      display: block !important;

      height: 16px;
    }
  }
}

.users-block {
  min-width: fit-content;
  width: 100%;
  .date-row__sort-icon {
    display: flex;
  }
}

.sat-export-block {
  margin-top: 20px;

  p {
    margin: unset;
  }

  .title {
    font-size: 16px;
    font-weight: 700;
  }

  input {
    margin-left: 20px;
    width: 50px !important;
  }
}

.tb-default-dialog {
  .download-button {
    text-align: right;
    margin-top: 30px;
    margin-right: -25px;
  }
}

.totals_div {
  min-width: 76px;
  max-width: 76px;
  height: 74px;
  margin-top: -2px;
  position: sticky;
  right: 0;
  z-index: 1;
  background-color: var(--background-1);
  border: 1px solid var(--stroke);
}

.dashboard__percentage-container {
  width: calc(100% - 480px - 20px);
  @media (max-width: 640px) {
    padding: 0px;
  }

  gap: 20px;
  display: flex;
}

.dashboard-control-bar__right-side {
  margin-left: auto;
  display: flex;
  gap: 40px;
}
