:root[data-theme="light"] {
  --my-week-default-text: #4c54a0;
}

:root[data-theme="dark"] {
  --my-week-default-text: #ffffff99;
}

.my-week__control-bar {
  top: 60px;
  position: fixed;
  width: 100%;
  display: flex;
  align-items: center;
  height: 60px;
  padding: 10px 30px;
  background: var(--background-1);
  z-index: 2;

  .arrow-block {
    padding: 0 5px;
    height: 38px;
    gap: 5px;
  }

  .MuiInputBase-input {
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0.4px;
  }
}

.my-week__date-picker {
  width: 160px !important;
}

.my-week__today-button {
  width: 44px;
  height: 40px;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 7px;
  border: 1px solid var(--stroke);
}

.my-week__calendar-integration {
  cursor: pointer;
  margin-left: auto;
  height: 40px;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 8px 10px;
  border-radius: 7px;
  border: 1px solid var(--stroke);

  img {
    width: 20px;
    height: 20px;
  }

  p {
    margin: 0;
    color: var(--text-9);
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0.4px;
  }
}

.my-week__content-container {
  width: 100%;
  border-top: 1px solid var(--stroke);
}

.empty-myweek-bg {
  position: absolute;
  width: 520px;
  height: 525px;
  top: 180px;
  left: 1000px;

  @media (max-height: 800px) {
    transform: scale(0.8);
    top: 100px;
    left: 800px;
  }
}

.my-week__no-bookings {
  position: relative;
  top: 230px;
  left: 160px;
  gap: 80px;
  width: fit-content;

  p {
    color: var(--text-2);
    margin: 0;
    text-align: left;
    font-size: 24px;
    font-weight: 500;
    line-height: 120%;

    &:last-child {
      font-size: 20px;
      font-weight: 300;
    }
  }

  img {
    max-width: 600px;
    max-height: 623px;
    width: 100%;
    height: 100%;
  }
}

.my-week__content-titles {
  padding: 0 30px;
  top: 120px;
  position: fixed;
  width: 100%;
  height: 40px;
  display: flex;
  background: var(--background-1);
  z-index: 2;
}

.column__title {
  width: 13.07%;
  padding: 7px 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  color: var(--text-2);
  text-wrap: nowrap;
  overflow: hidden;
  font-size: 14px;
  line-height: 24.5px;
  letter-spacing: 0.4px;
  border-top: 1px solid var(--stroke);
  border-bottom: 1px solid var(--stroke);
  border-left: 1px solid var(--stroke);

  &:last-child {
    border-right: 1px solid var(--stroke);
  }

  &.total {
    width: 8.49%;
  }

  &.weekends {
    color: var(--weekend-day-text);
    opacity: 0.7;
  }

  &.today {
    background: var(--stroke);
    color: var(--text-9);
  }
}

.day-columns__container {
  padding: 0px 30px 10px 30px;
  width: 100%;
  height: fit-content;
  position: absolute;
  top: 160px;
  display: flex;
}

.my-week__column {
  width: 13.07%;
  height: auto;
  border-left: 1px solid var(--stroke);
  border-bottom: 1px solid var(--stroke);
  border-collapse: collapse;
  overflow: hidden;

  &:last-child {
    border-right: 1px solid var(--stroke);
  }

  &.total {
    height: auto;
    width: 8.49%;
    background: var(--background-2);

    p {
      margin: 80px auto 10px;
      color: var(--my-week-default-text);
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0.4px;
    }
  }

  &.weekends {
    .day-number {
      color: var(--text-2);
    }
  }

  &.today {
    .day-number {
      border: 1px solid var(--stroke);
      background: var(--stroke);
      color: var(--text-9);
    }
  }

  &.less-today {
    background: var(--background-2);

    .column__content {
      height: 100%;
      // opacity: 0.5;
    }
  }
}

.column__content {
  padding: 10px;
}

.column__day-info {
  display: flex;
  justify-content: space-between;
  text-align: center;
  margin-bottom: 12px;

  .day-number {
    border-radius: 4px;
    border: 1px solid var(--stroke);
    padding: 2px 8px;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.4px;
    color: var(--text-2);
  }

  .amount-hours {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.4px;
    text-decoration-line: underline;
    color: var(--text-2);
  }

  p {
    margin-bottom: 0px;
  }
}

.column__booking-frame {
  width: 100%;
  padding: 10px 8px;
  margin-bottom: 5px;
  border-radius: 8px;

  &:last-child {
    margin: 0;
  }

  .booking-info {
    display: flex;
    justify-content: space-between;
    gap: 5px;
    margin-bottom: 4px;

    p {
      margin-bottom: 0;
      font-size: 14px;
      font-weight: 500;
      line-height: 120%;
      letter-spacing: 0.4px;
      text-transform: uppercase;

      &:first-child {
        overflow: hidden;
        text-wrap: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .booking__start-time-block {
    margin: 0px;
    line-height: 100%; /* 12px */
    letter-spacing: 0.4px;
    font-size: 12px;
    margin-bottom: 4px;
    p {
      margin: 0px;
    }
  }

  .task-description {
    font-size: 12px;
    text-align: left;
    margin-bottom: 5px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    line-height: 120%;

    p {
      max-width: 95%;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0px;
      white-space: nowrap;
    }
  }

  .booking__comment {
    font-size: 12px;
    text-align: left;
    font-weight: 400;
  }
}

.booking__icons-container {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  align-items: center;

  img,
  svg {
    margin-bottom: 6px;
    width: 18px;
    height: 18px;
  }
}

.booking__repetition-text,
.booking__start-time p {
  margin: 0;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  cursor: default;
  margin: unset;
}

.booking__start-time {
  display: flex;
  align-items: center;
  gap: 5px;
}

.booking__start-time svg {
  margin: unset !important;
}

.calendar-integration__content {
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin: 20px 0 30px 0;
  color: var(--text-2);
}

.integration__firstStep {
  p {
    margin: 0;
    font-feature-settings: "clig" off, "liga" off;
    font-size: 16px;
    line-height: 20px;
  }
}

.integration__urlForm {
  height: 40px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-between;

  .input-form-component {
    margin: 0;
  }

  .integration__copyButton {
    width: 90px;
    height: 40px;
    border-radius: 7px !important;
    padding: 0;

    p {
      font-size: 14px;
      letter-spacing: 0.4px;
    }
  }
}

.integration__secondStep {
  p {
    margin: 0;
    font-size: 16px;
    line-height: 24px;
  }

  a {
    font-size: 16px;
    line-height: 24px;
    text-decoration-line: underline;
  }
}

.integration__thirdStep {
  p {
    margin: 0;
    font-size: 16px;
    line-height: 120%;
  }

  img {
    margin-top: 10px;
    border-radius: 7px;
    border: 1px solid var(--stroke);
    width: 440px;
    height: 225px;
  }
}

.calendar-integration-modal {
  .MuiDialog-paper {
    width: 660px;
    max-width: 660px;
  }
}

.beta-image {
  width: 60px;
}

.calendar-integration {
  height: 300px;
  margin-bottom: 100px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 20px;

  &.deactivated {
    justify-content: center;
    align-items: center;

    .beta-image {
      position: absolute;
      top: 127px;
      left: 30px;
    }
  }

  .connected-text {
    color: #23a942;
  }

  .disconnect-button {
    position: absolute;
    bottom: 40px;
    right: 40px;
  }

  .google-integration__row__secondary {
    display: flex;
    justify-content: space-between;
    padding-left: 60px;

    p {
      user-select: none;
      margin: 8px 0 0 0;
      color: var(--text-2);
      font-size: 14px;
    }
  }

  .google-integration__row {
    display: flex;
    justify-content: space-between;
    margin-top: 14px;

    p {
      user-select: none;
      margin: 8px 0 0 0;
      color: var(--text-2);
    }
  }

  .helper-text {
    font-size: 14px;
    color: var(--text-2);
    margin: unset;
  }
}

.outlook-integration {
  height: 300px;
  margin-bottom: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .beta-image {
    position: absolute;
    top: 127px;
    left: 30px;
  }

  .connected-text {
    color: #23a942;
  }

  .disconnect-button {
    position: absolute;
    bottom: 40px;
    right: 40px;
  }
}

.calendar-integration__tabs {
  width: 50%;
  cursor: pointer;
  border: 1px solid var(--stroke);
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  user-select: none;

  border-radius: 8px;

  img {
    width: 24px;
    height: 24px;
  }

  p {
    margin: 0;
    font-size: 16px;
    text-align: center;
    color: var(--text-2);
  }

  &.active {
    background: var(--stroke);
  }
}

.my-week__project-manager {
  display: flex;
  gap: 5px;
  align-items: center;

  p {
    font-size: 14px;
    margin: unset;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 14px;
  }

  .manager-avatar {
    width: 18px;
    height: 18px;
    border-radius: 10px;
  }
}
