.new-user-box {
  padding: 0 5%;
  text-align: left; /*must overwrite default setting*/
}

.new-user-box p {
  margin-top: revert; /*allows items to align center*/
}

.new-user-box input {
  max-width: 234px;
}

.new-user-general-info {
  display: flex;
  justify-content: space-between;
}

.new-user-general-info p {
  margin-top: 0px;
}

.new-user-avatar {
  padding-top: 15px;
}

.MuiSelect-select {
  max-width: 234px;
}

.new-user-form > .new-user__value {
  margin-top: 20px;
  margin-left: 15%;
}

.user__submit {
  background-color: #457ec1 !important;
  color: #ffffff !important;
  text-transform: capitalize !important;
  height: 36px;
}

.new-user__close {
  background-color: #ffffff;
  text-transform: capitalize !important;
  color: #333333;
}

.new-user-form {
  text-align: start;
}
