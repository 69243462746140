:root[data-theme="light"] {
  --actuals-reported: #7fa6ce;
  --actuals-approved: #516f90;
  --actuals-monthly: #9199e7;
}

:root[data-theme="dark"] {
  --actuals-reported: #49587a;
  --actuals-approved: #ffffff99;
  --actuals-monthly: #99a0e0;
}

.actuals-grid {
  position: absolute;
  top: 130px;
  width: 100%;
  padding-bottom: 25px;
  bottom: 0;
  display: flex;
  overflow-y: auto;
}

.actuals__control-bar {
  position: fixed;
  z-index: 11;
  background-color: var(--background-1);
  top: 60px;
  width: 100%;
  display: flex;
}

:root[data-theme="dark"] {
  .users-column__sort-icon {
    img {
      width: 18px;
      height: 18px;
    }
  }
}

.users-column__sort-icon {
  border: 1px solid var(--stroke);
  border-radius: 7px;
  padding: 8px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px !important;
  width: 44px;
  cursor: pointer;

  div {
    margin: 0px;
  }

  img {
    width: 24px;
    height: 24px;
  }
}

.actuals-text-field {
  .MuiInputBase-root {
    padding: 16.5px 14px !important;
    border-bottom: 1px solid var(--stroke) !important;
  }
}

.week-row__total {
  height: 40px;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--background-1) !important;
  box-shadow: unset !important;

  p {
    margin-bottom: unset;
    color: var(--text-2);
    font-weight: 300;
    font-size: 14px;
    text-transform: uppercase;
    box-shadow: 1px 0 0 0 var(--stroke), 0 1px 0 0 var(--stroke), 1px 1px 0 0 var(--stroke),
      1px 0 0 0 var(--stroke) inset, 0 1px 0 0 var(--stroke) inset;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.actuals__date {
  padding: 0 8px;
  border: 1px solid var(--stroke);
  border-radius: 7px;
}

.day-weekend {
  .actuals__date {
    color: var(--weekend-day-text);
    border-color: var(--stroke);
  }
}

.week-row,
.actuals-week {
  display: flex;
}

.week-row__days {
  display: flex;
  width: calc(100% - 120px);
  background: var(--background-2);
}

.week-row__week-days {
  display: flex;
  position: absolute;
  left: 120px;
  right: 0;
  flex: 1;
}

.week-row__week-days.approval {
  flex: unset;
  width: calc(100% - 120px - 7px);
}

.week-row__element {
  box-shadow: 1px 0 0 0 var(--stroke), 0 1px 0 0 var(--stroke), 1px 1px 0 0 var(--stroke), 1px 0 0 0 var(--stroke) inset,
    0 1px 0 0 var(--stroke) inset;
  color: var(--my-week-default-text);
}

#actuals-scroll-grid {
  padding-right: 7px !important;
}

#actuals-scroll-grid {
  padding-bottom: 20px;
  top: 52px !important;
}

.approval-page__current-user {
  span {
    font-size: 12px !important  ;
  }
}

.approval-page__approbation {
  margin-left: 20px;
  font-weight: 500;
}

.approval-page__selected-container {
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 0px auto;
  position: absolute;
  left: 0px;
  right: 0px;
  width: fit-content;
  p {
    font-weight: 500;
  }
}

.approval-page__approval-data {
  display: flex;
  position: absolute;
  gap: 20px;
  right: 20px;
  height: 100%;
  p {
    font-weight: 500;
  }
}

.approval-page__logged-hours,
.approval-page__approved-hours {
  height: fit-content;
  margin: auto 0px;
  width: 70px;

  p {
    font-size: 14px;
    line-height: 14px;
    color: var(--text-2);
    letter-spacing: 0.15px;
  }
}

.approval-page__selected {
  display: flex;
  position: relative;
  align-items: center;
  gap: 10px;
  margin-top: 2px;
  height: 50px;
  border: 1px solid var(--stroke);
  border-radius: 7px 7px 0px 0px;
  border-bottom: 0px;

  .approval-page__current-project {
    width: 8px;
    height: 8px;
    border-radius: 2px;
  }

  span {
    font-size: 14px;
    color: var(--text-8);
  }

  p {
    margin-bottom: 0px;
    color: var(--text-2);
    font-size: 14px;
  }
}

.actuals-total-cell.last {
  box-shadow: unset;
  background: var(--background-1) !important;

  p {
    background: var(--background-2) !important;
    width: 100%;
    height: 100%;
    box-shadow: 1px 0 0 0 var(--stroke), 0 1px 0 0 var(--stroke), 1px 1px 0 0 var(--stroke),
      1px 0 0 0 var(--stroke) inset, 0 1px 0 0 var(--stroke) inset;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: 7px;
  }
}

.week-row__day {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  .last {
    border: 1px solid var(--stroke);
    border-bottom: unset;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 1px 0px 0px var(--stroke);
  }

  p {
    margin: 0;
    font-size: 14px;
  }
}

.actuals__arrow-block {
  display: flex;
  align-items: center;
  gap: 20px;
}

.actuals__date-picker {
  align-self: center;
  cursor: pointer;
  display: flex;
  gap: 20px;
  width: 170px;

  input {
    cursor: pointer;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    display: flex;
    align-items: center;
    letter-spacing: 0.15008px;
    text-align: center;
    box-sizing: border-box;
    background-color: var(--background-1);
    color: var(--text-2);
    border-bottom: none;

    .MuiPaper-root {
      top: 150px !important;
      left: 50px !important;
    }
  }

  .MuiInputBase-root {
    color: var(--text-2);
    border-bottom: none;

    &:before {
      border-bottom: 1px solid var(--text-2);
    }

    &:after {
      border-bottom: 1px solid var(--accent-dark);
    }

    &:hover {
      &:before,
      &:after {
        border-bottom: 1px solid var(--text-2) !important;
      }
    }
  }
}

.time-logging__dialog {
  .MuiDialog-paper {
    width: 400px;
  }

  .MuiDialogContent-root {
    margin: 20px 20px 0 20px;
    padding: unset;
  }

  .MuiDialogActions-root {
    margin: 20px 20px 20px 20px;
    padding: unset;
  }

  .MuiDialogTitle-root {
    margin: 20px 20px 0 20px;
    padding: unset;
    border-bottom: unset;
    justify-content: unset;

    .time-logging__title-text {
      display: flex;
      flex: 1;
      justify-content: space-around;
    }
  }
}

.actuals-settings {
  width: 490px;
  height: 255px !important;

  scrollbar-gutter: unset !important;
  // padding: 0px 20px !important  ;
}

.actuals__stats {
  gap: 40px;
  display: flex;
  margin-left: 40px;

  .actuals_stat-type {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  .actuals__hours {
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
  }

  .actuals__stat {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--text-4);

    p {
      margin-bottom: unset;
      font-weight: 500;
    }
  }
}

.actuals__calendar {
  width: 100%;
}

.actuals__day-info {
  width: 100%;

  p {
    margin: 0px;
  }
}

.actuals__header {
  display: flex;
  justify-content: space-between;
}

.actuals__duration {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  margin-top: 3px !important;
  text-decoration: underline;
}

.actuals__project-approve {
  margin-top: 3px !important;
  color: var(--text-2);
  height: 30px !important;
  width: 100% !important;
  cursor: pointer;

  //text-transform: unset !important;
  font-family: "Montserrat", sans-serif;
  font-size: 16px !important;
}

.approve__day-logs {
  min-height: 130px;
  max-height: 130px;
}

.actuals__day-logs {
  min-height: 110px;
  max-height: 110px;
}

.actuals__day-logs,
.approve__day-logs {
  //margin-top: 10px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  .actuals__log {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    width: 100%;
    cursor: pointer;
    position: relative;
    align-items: center;
    border: 2px solid;
    border-radius: 8px;
    transition: all var(--transition-duration);
    padding: 0px 2px;

    .actuals__log-duration {
      margin-right: 6px;
      width: fit-content;
    }

    &:hover {
      opacity: 0.6;
    }

    p {
      margin-bottom: 0;
      font-size: 14px;
      line-height: 20px;
    }

    .actuals__log-color {
      height: 16px;
      width: 2px;
      min-width: 2px;
      max-width: 2px;
    }

    .actuals__log-project-name {
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 50%;
      max-width: 50%;
      min-width: 50%;
    }

    .actuals__log-user-name {
      margin-right: auto;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 50%;
      max-width: 50%;
      min-width: 50%;
    }

    .actuals__log-check {
      margin-left: auto;
      margin-right: 5px;
      height: 16px;
      width: 16px;
    }

    .actuals__button-approve {
      margin-left: auto;
      margin-right: 5px;
      height: 16px;
      width: 16px;
    }

    img {
      margin-left: auto;
      margin-right: 3px;
      min-width: 16px;
      max-width: 16px;
      max-height: 16px;
      min-height: 16px;
    }
  }
}

.actuals-total-cell {
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--background-2) !important;
  box-shadow: 1px 0 0 0 var(--stroke), 0 1px 0 0 var(--stroke), 1px 1px 0 0 var(--stroke), 1px 0 0 0 var(--stroke) inset,
    0 1px 0 0 var(--stroke) inset;
  height: 190px;

  p {
    margin: 0;
    color: var(--text-2);
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.4px;
  }
}

.actuals__control-buttons {
  display: none;
  gap: 4px;
  cursor: pointer;
  padding-top: 5px;

  p {
    color: var(--text-1);
    font-size: 12px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 0.4px;
  }
}

.actuals__date-cell_today {
  .actuals__date {
    background: var(--stroke) !important;
    color: var(--text-4);
  }
}

.actuals__date-cell {
  // box-shadow: 1px 0 0 0 var(--stroke), 0 1px 0 0 var(--stroke), 1px 1px 0 0 var(--stroke), 1px 0 0 0 var(--stroke) inset,
  //   0 1px 0 0 var(--stroke) inset;
  // border-right: 1px solid var(--stroke);

  box-shadow: 1px 0px 0px 0px var(--stroke) inset, 0px 1px 0px 0px var(--stroke) inset;

  &[data-last="true"] {
    box-shadow: 1px 0px 0px 0px var(--stroke) inset, 0px 1px 0px 0px var(--stroke) inset,
      -1px 0px 0px 0px var(--stroke) inset;
  }

  padding: 12px;
  height: 190px;
  color: var(--text-2);

  &:hover {
    background: var(--background-2) !important;

    .actuals__control-buttons {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.actuals__today {
  padding: 12px;
  -webkit-box-shadow: 0 0 0 2px #7fa6ce inset;
  -moz-box-shadow: 0 0 0 2px #7fa6ce inset;
  box-shadow: 0 0 0 2px #7fa6ce inset;
}

.past {
  background-color: var(--background-2) !important;

  .actuals__day-info {
    opacity: 0.7;
  }
}

.time-logging-window {
  border: 1px solid #9199e7;
  border-radius: 10px;

  .title {
    margin-bottom: 0;
  }

  .MuiDialogTitle-root {
    border-bottom: 1px solid #d5d9ff;
  }

  .MuiDialogContent-root {
    width: 370px;
  }
}

.time-logging-window__duration-input {
  font-family: Montserrat;
  width: 75px;
  margin: 0px;

  input {
    text-align: center;
  }

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.time-logging-window__project-selector {
  margin-top: 5px;

  .title {
    margin: 0;
    font-size: 14px;
    color: var(--text-1);
  }
}

.time-logging-window__duration,
.time-logging-window__notes {
  margin-top: 20px;

  .title {
    margin: 0;
    font-size: 14px;
  }
}

.time-logging-window__project-selector {
  .tb-react-select__control {
    min-height: 44px !important;
  }
}

.time-logging-window__project-selector,
.time-logging-window__notes {
  p {
    text-align: left;
  }
}
.time-logging-window__project-selector {
  .tb-react-select__single-value {
    color: var(--text-9) !important;
  }
}

.time-logging-window__duration {
  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    cursor: pointer;
  }

  .time-logging-window__hours {
    width: 30px;
    font-weight: 700;
    font-size: 16px;
  }
}

.actuals__context-menu {
  position: absolute;
  height: fit-content;
  min-width: 95px;
  background: var(--background-1);
  border: 1px solid var(--stroke);
  border-radius: 10px;
  z-index: 100;
  color: var(--text-2);

  .planner__context-menu-option {
    height: 38px;
    display: flex;
    align-items: center;
    padding: 0px 10px;
    gap: 10px;
    cursor: pointer;

    &:hover {
      background: var(--planner-rightclick-hover);

      p {
      }
    }

    &:first-child {
      border-radius: 10px 10px 0px 0px;
    }

    &:last-child {
      border-radius: 0px 0px 10px 10px;
    }
  }

  p {
    margin-bottom: 0px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    cursor: pointer;
    transition: background var(--transition-duration);

    &:hover {
      background: var(--background-2);
    }
  }
}

.actuals__tooltip-component {
  position: fixed;
  z-index: 22;
  display: none;

  .tooltip-component__block {
    height: 92px;
    width: 192px;
    border-radius: 7px;
    padding: 7px 8px 7px 8px;
    background-color: var(--background-1);
    border: 1px solid var(--stroke);
    box-shadow: 5px 10px 30px var(--box-shadow);
    .tooltip-component__icon {
      width: 10px;
      height: 10px;
    }
  }

  p {
    color: var(--text-2);
    margin: 0;
    font-size: 10px;
    text-align: start;
    white-space: nowrap;
  }

  .tooltip-component__project-name {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 151px;
    font-weight: 500;
    text-transform: uppercase;
  }

  .tooltip-component__client-name,
  .tooltip-component__task-name,
  .tooltip-component__comment,
  .tooltip-component__team {
    display: flex;
    align-items: center;
    gap: 2px;
    height: 12px;
    margin-bottom: 4px;

    p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .tooltip-component__client-name,
  .tooltip-component__task-name,
  .tooltip-component__comment,
  .tooltip-component__team {
    display: flex;
    align-items: center;
    gap: 2px;
    height: 12px;
    margin-bottom: 4px;

    p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .tooltip-component__team {
    z-index: 12;
  }

  .tooltip-component__triangle {
    position: absolute;
    bottom: -11px;
    left: 18px;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 12px solid var(--background-1);
    z-index: 10;
  }

  .tooltip-component__triangle_bg {
    position: absolute;
    bottom: -12px;
    left: 18px;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    transform: scale(1.11);
    border-right: 7px solid transparent;
    border-top: 12px solid var(--stroke);
  }

  .tooltip-component__stub {
    width: 14px;
    height: 7px;
    position: absolute;
    background: var(--background-1);
    z-index: 11;
    left: 18px;
    bottom: 1px;
  }
}

.time-logging-window__notes {
  .time-logging-window__notes-textarea {
    width: 100%;
    height: 80px;
    border: 0;
    resize: none;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    &:focus,
    &:focus-visible {
      outline: 0;
      border: 0;
    }
  }
}

.booking-window__project-type-option {
  p {
    margin-left: 30px;
    margin-right: 30px;
  }
}

.actuals-project-picker__option {
  overflow: hidden;
  text-overflow: ellipsis;
}

.actuals-project-picker {
  .actuals-project-picker__control {
    border-color: #9199e7;
  }

  .actuals-project-picker__indicator-separator {
    display: none;
  }

  .actuals-project-picker__dropdown-indicator {
    color: black;
  }
}

.actuals__approval-block {
  display: flex;
  flex: 1;
  .disabled {
    height: 40px;
  }

  a {
    text-decoration: none;
  }

  .actuals__admin-aproval-button {
    border-radius: 20px !important;
    background: #fff !important;
    padding: 10px 10px;
    gap: 10px;
    border: 1px solid #4c54a0;
    box-shadow: 0px 4px 25px rgba(127, 166, 206, 0.15);
    min-width: 150px;
    max-width: 200px;

    p {
      margin: 0px;
      color: #4c54a0;
      white-space: nowrap;
    }
  }
}

.users-column {
  padding: 0px 0px 30px 30px;
  gap: 30px;
  width: 350px;
  position: absolute;
  top: 0;
  bottom: 60px;

  .input-form-component {
    .MuiInputBase-root {
      height: unset !important;
    }
  }
}

.column-user__form {
  border: 1px solid var(--stroke);
  border-radius: 10px;
  width: 320px;
  min-height: 54px;
  max-height: 54px;
  display: flex;
  align-items: center;
  padding: 0px 11px 0px 10px;
  position: relative;
  cursor: pointer;
  gap: 10px;

  .column-user__user-approval-hours {
    background: var(--actuals-hours-background);
    border-radius: 7px;
    padding: 5px 10px 5px 10px;
    display: flex;
    align-items: center;
    width: 135px;
    justify-content: center;

    p {
      font-weight: 500;
      font-size: 12px;
      color: var(--button-outline-text-default);
      margin: 0px;
      letter-spacing: 0.4px;
      white-space: nowrap;
    }

    .column-user__avatar-block {
      height: fit-content;
      // padding: 10px 30px 10px 10px;
    }
  }

  .column-user__user-info {
    p {
      font-size: 14px;
      color: var(--text-2);
      text-align: left;
      line-height: 120%;
      width: 114px;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 0;
      white-space: nowrap;
    }

    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .column-user__tags {
    gap: 5px;
    overflow: hidden;
    margin-top: auto;
    max-width: 240px;
    display: flex;
    justify-items: flex-start;
    flex-wrap: wrap;
    height: 24px;

    .column-user__tag {
      display: flex;
      height: fit-content;
      border-radius: 10px;
      color: #ffffff;
      padding: 2px 5px 2px 5px;

      font-size: 14px;
      color: #ffffff;

      p {
        width: 100%;
      }
    }
  }
}

.actuals-project-picker__menu-portal {
  z-index: 1300 !important;
}

.time-logging-window__controls {
  width: 100%;
  left: 0px;
  padding: 8px 24px !important;
  gap: 10px;
  display: flex;

  img {
    cursor: pointer;
  }
}

.column-user__form_selected {
  background: var(--background-2);
  border: 1px solid var(--text-5);
}

.time-logging__dialog {
  .MuiDialogContent-root {
    scrollbar-gutter: unset;
  }
}

.sort-switch {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 200px;
  height: 40px;
  background: #ebedff;
  border-radius: 20px;

  .sort-switch__option {
    color: #a3a3a3;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    width: 45%;
    height: 35px;
    margin: 3px 5px;
  }

  .sort-switch__option_active {
    color: #474747;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(61, 80, 99, 0.3);
    border-radius: 20px;
  }

  p {
    font-size: 12px;
    margin: 0;
  }
}

.actuals__download-button {
  margin-right: 12px;
  width: 44px;
}

.actuals__duration-text-modal {
  width: 70%;
  margin-top: 6px !important;
  font-size: 14px;
  color: var(--text-1);
}

.actuals__duration-input-modal {
  width: 30%;

  input {
    padding: 8px;
    background-color: #f5f9fd !important;
  }
}

.planner-settings-dialog {
  box-shadow: 0 0 20px 1000px rgba(0, 0, 0, 0.25);

  .planner-settings-dialog__setting {
    display: flex;
    justify-content: space-between;
  }

  .planner-settings-dialog__setting-text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }

  .planner-settings-dialog__setting-control {
    width: 40px;
    display: flex;
    justify-content: center;
    margin-right: 15px;
  }
}

.copy-planner__checkboxes {
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  p {
    margin: unset;
    cursor: pointer;
    font-size: 14px;
  }
}

.copy-planner-select__multi-value {
  .copy-planner-select__multi-value__label {
    font-family: montserrat;
  }
}

.copy-planner-select__menu-portal {
  z-index: 1301 !important;

  .copy-planner-select__menu {
    max-height: 150px !important;
  }
}

.manage-data__list {
  max-height: 300px;
  scrollbar-gutter: stable;
  overflow: auto;
  padding-right: 11px;
}

.manage-data__entity {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 0px 0px 20px;

  p {
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 75%;
  }

  .manage-data__controls {
    margin: auto 0;
    display: flex;
    gap: 15px;

    img {
      width: 24px;
      height: 24px;
    }
  }
}

.manage-data__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;

  p {
    font-weight: 600;
    margin: auto 0;
  }
}

.actuals-react-select__indicator-separator {
  display: none;
}

.actuals-react-select__control {
  font-family: Montserrat;
  font-size: 14px !important;
  border-radius: 8px !important;
  height: 38px;
  border: 1px solid #c8d2e6 !important;
}

.actuals-react-select__menu-list {
  border-radius: 8px !important;
  padding: 0px !important;
}

.actuals-react-select__option {
  color: #000;
  font-size: 12px !important;
  padding: 8px 10px !important;
  font-family: Montserrat;
}

.actuals-react-select__single-value {
  transform: unset !important;
  top: 0px !important;
  bottom: 0px !important;
  margin: auto 0;
  display: flex;
  align-items: center;
}

.actuals-react-select__dropdown-indicator {
  transform: unset !important;
  padding: 0px 8px !important;
}

.actuals-react-select__menu {
  border-radius: 8px !important;
  z-index: 1000;
}

.actuals-react-select__menu-portal {
  z-index: 1300 !important;
}

.actuals__date-picker {
  width: 110px;

  .MuiInputBase-root {
    text-align: left;
    width: 90px;
  }
}

.copy-planner-subblock__title {
  color: var(--text-2);
  letter-spacing: -0.4px;
  font-weight: 500;
}

.week-row__header {
  position: sticky;
  top: 0px;
  z-index: 10;
}

.users-column__split-switcher {
  .tb-react-select__control {
    width: 220px;
  }
  .tb-react-select__single-value {
    color: var(--text-9) !important;
  }
}

.actuals__filter-component {
  .planner-control__filter-text-field .capacity {
    margin-left: 20px;
  }
}

.actuals__filter-component {
  flex: 1;
  max-width: 500px;
  position: relative;
}

.actuals__filter-component {
  .planner-filter-row {
    height: 40px;
    width: unset;
  }

  .planner-filter-row__value-container {
    height: 38px !important;
  }
}

.actuals__control-bar {
  .planner-control__filter-icon {
    position: unset !important;
  }
}

.week-row__header-approval {
  position: sticky;
  top: 0px;
  z-index: 10;
}

.actuals-modal__content {
  .actuals-modal__bold-info {
    font-weight: 600;
  }
}

.actuals-blocked {
  position: absolute;
  top: 120px;
  margin-left: 8%;
  width: 900px;

  p {
    text-align: left;
    margin-bottom: 0;
    color: var(--text-2);
  }

  .header-text {
    font-size: 20px;
    margin-bottom: 40px;
    color: var(--text-1);
  }

  .group-text {
    color: var(--text-1);
    margin-bottom: 20px;
  }

  .check-group {
    display: flex;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    margin-bottom: 10px;
  }

  .ending-text {
    margin-bottom: 20px;
  }
}

.actuals__upload-link-text {
  color: var(--header-nav-color);
  text-decoration: underline;
  cursor: pointer;
}

.copy-planner-dialog__date-row {
  display: flex;
  margin-bottom: 16px;
  align-items: center;
  gap: 10px;

  p {
    white-space: nowrap;
    margin: 0px;
    font-size: 14px;

    &[language="en"] {
      width: 75px;
    }

    &[language="de"] {
      width: 85px;
    }

    &[language="fr"] {
      width: 110px;
    }

    &[language="es"] {
      width: 110px;
    }

    &[language="it"] {
      width: 95px;
    }
  }
}

.copy-planner-dialog__date-picker-container {
  display: flex;
  position: relative;
  display: flex;
}

.actuals__week-view-container {
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  height: 100%;
  width: 100%;
}

.week-view__days-container {
  display: flex;
  flex: 1;
  width: calc(100% - 190px);
  min-width: calc(100% - 190px);
}

.week-view__log-duration {
  align-self: flex-start;
}

.week-view__total-cell {
  width: 130px;
  min-width: 130px;
  height: 100%;
  -webkit-box-shadow: 1px 0px 0px 0px var(--stroke);
  -moz-box-shadow: 1px 0px 0px 0px var(--stroke);
  box-shadow: 1px 0px 0px 0px var(--stroke);
  border-left: 1px solid var(--stroke);
}

.actuals-settings {
  &[language="en"] {
    .MuiSlider-markLabel {
      &[data-index="1"] {
        left: 90% !important;
      }
    }
  }

  &[language="es"] {
    .MuiSlider-markLabel {
      &[data-index="1"] {
        left: 94% !important;
      }
    }
  }

  &[language="fr"] {
    .MuiSlider-markLabel {
      &[data-index="1"] {
        left: 93% !important;
      }
    }
  }

  &[language="it"] {
    .MuiSlider-markLabel {
      &[data-index="1"] {
        left: 93% !important;
      }
    }
  }

  &[language="de"] {
    .MuiSlider-markLabel {
      &[data-index="1"] {
        left: 91% !important;
      }
    }
  }

  .MuiSlider-markLabel {
    text-transform: lowercase;
  }
}

.week-view__create-log-button {
  display: none;
  align-items: center;
  align-self: center;
  gap: 5px;
  position: absolute;
  bottom: 10px;

  p {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 0.4px;
    color: var(--text-1);
    margin: 0px;
  }
}

.week-view__total-cell {
  box-shadow: unset;
  border-left: unset;

  .week-view__day-data {
    border-left: 1px solid var(--stroke);
    border-bottom-left-radius: 7px;
  }

  .week-view__day-header {
    border-top-left-radius: 7px;
    border-left: 1px solid var(--stroke);
  }
}

.week-view__day-cell {
  position: relative;

  &:last-child {
    box-shadow: unset;
    border-bottom: unset;

    .week-view__day-data {
      border-right: 1px solid var(--stroke);
      border-bottom: 1px solid var(--stroke);
      border-bottom-right-radius: 7px;
    }

    .week-view__day-header {
      border-top-right-radius: 7px;
      border-right: 1px solid var(--stroke);
    }
  }

  &[weekend="true"] {
    .week-view__day-header,
    .week-view__day {
      color: var(--weekend-day-text);
    }
  }

  &[today="true"] {
    .week-view__day-header {
      background: var(--stroke);
      color: var(--text-1);
      font-weight: 400;
    }

    .week-view__day {
      background: var(--stroke);
      p {
        color: var(--text-11);
      }
    }
  }

  &:hover {
    .week-view__day-data {
      background: var(--background-2);
    }

    .week-view__create-log-button {
      display: flex;
    }
  }

  -webkit-box-shadow: 1px 0px 0px 0px var(--stroke);
  -moz-box-shadow: 1px 0px 0px 0px var(--stroke);
  box-shadow: 1px 0px 0px 0px var(--stroke);
  border-left: 1px solid var(--stroke);
  border-bottom: 1px solid var(--stroke);
  display: flex;
  flex-direction: column;

  min-height: 90%;

  &:last-child {
    border-right: unset;
  }
}

.week-view__day-header {
  margin: 0px;
  color: var(--text-2);
  min-height: 40px;
  border-bottom: 1px solid var(--stroke);
  border-top: 1px solid var(--stroke);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  letter-spacing: 0.4px;
  font-weight: 300;
}

.week-view__total-value {
  height: calc(100% - 40px);
  width: 100%;
  padding-top: 30px;
  border-left: 1px solid var(--stroke);
  border-bottom: 1px solid var(--stroke);
  border-bottom-left-radius: 7px;

  p {
    color: var(--text-2);
    font-weight: 500;
    letter-spacing: 0.4px;
  }
}

.week-view__day-data {
  padding: 10px;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}

.week-view__day {
  width: 37px;
  height: 24px;
  color: var(--text-2);
  padding: 2px 8px;
  border: 1px solid var(--stroke);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    margin: 0px;
  }
}

.week-view__day-data-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.week-view__day-duration {
  p {
    font-weight: 700;
    color: var(--text-2);
    text-decoration: underline;
    font-size: 14px;
    margin: 0px;
  }
}

.week-view__day-logs {
  height: 100%;
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-bottom: 44px;
}

.week-view__log-data {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px 8px;

  p {
    color: var(--text-2);
    text-align: left;
  }
}

.week-view__day-log {
  width: 100%;
  border-radius: 8px;
  min-height: 28px;
}

.week-view__log-project-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 70%;
  overflow: hidden;
}

.planner-settings-dialog__slider.actuals-dialog__slider.en {
  .MuiSlider-markLabel {
    &[data-index="0"] {
      left: 4% !important;
    }
    &[data-index="1"] {
      transform: none;
      left: 86% !important;
    }
  }
}

.planner-settings-dialog__slider.actuals-dialog__slider.de {
  .MuiSlider-markLabel {
    &[data-index="0"] {
      left: 5% !important;
    }

    &[data-index="1"] {
      transform: none;
      left: 87% !important;
    }
  }
}

.planner-settings-dialog__slider.actuals-dialog__slider.fr {
  .MuiSlider-markLabel {
    &[data-index="1"] {
      transform: none;
      left: 91% !important;
    }
  }
}

.planner-settings-dialog__slider.actuals-dialog__slider.es {
  .MuiSlider-markLabel {
    &[data-index="0"] {
      left: 4% !important;
    }

    &[data-index="1"] {
      transform: none;
      left: 91% !important;
    }
  }
}

.planner-settings-dialog__slider.actuals-dialog__slider.it {
  width: 200px;
  margin-left: 20px;
  .MuiSlider-markLabel {
    &[data-index="0"] {
      left: 3% !important;
    }

    &[data-index="1"] {
      transform: none;
      left: 88% !important;
    }
  }
}

.actual-log-empty-space {
  cursor: pointer;
  width: 100%;
}

.time-logging-window__notes {
  .editor-section {
    height: 90px;

    .DraftEditor-root {
      height: 90px;
    }
  }
}
