.form-error__error-message {
  padding: 10px;
  background-color: #d84d4a;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
}

.form-error__error-message__text {
  width: 100%;
  color: white;
  margin-left: 20px;
}

.form-error__error-message__text > p {
  margin: 2px 0 2px 0;
}

.form-error__main-text {
  font-size: 1.1rem;
}

.form-error__error-text {
  font-size: 0.9rem;
}

.form-error__error-message button {
  color: #ffffff;
}
