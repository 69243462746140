.filter-row {
  height: 60px;
  display: flex;
  padding-left: 30px !important;
  padding-right: 30px !important;
  position: fixed;
  top: 60px;
  width: 100%;
  z-index: 12;
  background-color: var(--background-1);
  @media (max-width: 600px) {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
}

.filter-row__filter-search {
  position: relative;
  margin: auto 0 !important;
  border: 1px solid var(--stroke);
  border-radius: 7px;
  padding: 0px 10px;
  display: flex;
  align-items: center;
  max-width: 500px;
  width: 100%;

  .filter-row__filter-icon {
    width: 24px;
    height: 24px;
    margin: auto 0;
  }

  .filter-row__filter-input {
    line-height: 19px;
    width: 100%;
    font-size: 14px;
    padding: 8px 10px;
    border: 0px;
    font-family: Montserrat;
    background: transparent;
    color: var(--text-2);
    caret-color: var(--cursor-color);
    line-height: 19.002px;
    letter-spacing: 0.15px;

    @media (max-width: 600px) {
      width: 140px !important;
    }

    &::placeholder {
      line-height: 19.002px;
      letter-spacing: 0.15px;
      color: var(--text-2);
      opacity: 0.5;
    }

    outline: none;
  }
}

.deactivate-dropdown__container {
  margin: auto 0;
  width: 145px;
  font-size: 14px;

  .tb-react-select__single-value {
    color: var(--button-outline-text-default) !important;
  }

  @media (max-width: 600px) {
    width: 120px;
  }

  .deactivate-dropdown__control {
    cursor: pointer;
    border: 1px solid #c8d2e6;
    border-radius: 7px;
  }

  .deactivate-dropdown__indicator-separator {
    display: none;
  }

  .deactivate-dropdown__indicator {
    color: #4c54a0;
    padding: 0px 8px !important;

    @media (max-width: 600px) {
      padding: 6px;
    }

    &:hover {
      color: #4c54a0;
    }
  }

  .deactivate-dropdown__single-value {
    font-family: Montserrat;
    color: #4c54a0;
  }
}

.MuiButton-root {
  box-shadow: none !important;
}

.manage-clients-button,
.manage-tags-button {
  display: flex;
  align-items: center;
  gap: 5px;
  height: 40px;
  padding: 8px 10px;
  border-radius: 7px;
  border: 1px solid var(--stroke) !important;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
  }

  p {
    margin: 0;
    color: var(--button-outline-text-default);
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 0.4px;
  }
}

.filter-row__button-caption {
  display: flex;
  align-items: center;
  gap: 5px;
}

.user-form__tag,
.user-form__team {
  p {
    white-space: nowrap;
  }
}

.deactivate-switch {
  display: flex;
  width: 218px;
  border: 1px solid var(--stroke);
  height: 40px;
  border-radius: 7px;
}

.active-entity-switch__option {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:first-child {
    border-radius: 6px 0px 0px 6px;
  }

  &:last-child {
    border-radius: 0px 6px 6px 0px;
  }

  p {
    color: var(--text-9);
    margin: 0px;
    font-size: 14px;
    letter-spacing: 0.4px;
  }
}

.active-entity-switch__option.active {
  background: var(--stroke);
}

.edit-form__tags {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  max-width: 100%;
  min-width: 100%;
  min-height: 20%;
  max-height: 28%;
  padding: 1px 2px 1px 18px;
  scrollbar-gutter: stable;
  overflow: auto;
  gap: 4px;

  .user-form__tag {
    display: flex;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    border-radius: 20px;
    flex-grow: 1;
    padding: 6px 8px;
    color: var(--background-1);
    font-size: 12px;
    align-items: center;

    .user-form__img-bg {
      margin-left: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 18px;
      max-width: 18px;
      min-height: 18px;
      max-height: 18px;
      border-radius: 50%;
    }

    &:hover {
      box-shadow: 0px 0px 2px 0px #4c54a0;

      .user-form__img-bg {
        background-color: #fff;
        box-shadow: 0px 0px 2px 0px #4c54a0;
      }
    }

    p {
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0px 0px 0px auto;
    }

    img {
      cursor: pointer;
    }
  }
}

.tooltip_teams {
  display: flex;
  flex-direction: column;

  .user-form__team {
    display: flex;
    max-width: 100%;
    min-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    border-radius: 20px;
    flex-grow: 1;
    margin-top: 5px;
    padding: 3px 8px !important;
    color: var(--text-2);
    font-size: 12px;
    align-items: center;
    justify-content: center;
    background: var(--background-2);

    p {
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0px;
    }
  }
}

.tooltip_teams,
.edit-form__teams {
  display: flex;
  max-width: 100%;
  min-width: 100%;
  scrollbar-gutter: stable;
  gap: 4px;

  .user-form__tag {
    display: flex;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    border-radius: 20px;
    flex-grow: 1;
    padding: 2px 4px;
    white-space: nowrap !important;
    color: var(--text-2);
    font-size: 12px;
    align-items: center;
    border: 1px solid var(--stroke);
    height: 26px;

    .user-form__img-bg {
      margin-left: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 18px;
      max-width: 18px;
      min-height: 18px;
      max-height: 18px;
      border-radius: 50%;
    }

    &:hover {
      .user-form__img-bg {
        background-color: #fff;
        box-shadow: 0px 0px 2px 0px #4c54a0;
      }
    }

    p {
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0px 0px 0px auto;
    }

    img {
      cursor: pointer;
    }
  }

  .extra_teams {
    width: 35px !important;
    max-width: 35px !important;
    min-width: 35px;
    font-weight: 600;
    max-height: 26px;
    margin-left: 4px;
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
  }

  .user-form__team {
    display: flex;
    max-width: 31%;
    text-overflow: ellipsis;
    overflow: hidden;
    border-radius: 20px;
    padding: 6px 8px;
    color: var(--text-2);
    font-size: 12px;
    align-items: center;
    justify-content: center;
    background: var(--background-2);
    overflow: hidden;

    p {
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0px;
      max-width: 118px;
    }
  }

  .user-form__team.tag {
    display: flex;
    max-width: 130px !important;
    text-overflow: ellipsis;
    overflow: hidden;
    border-radius: 20px;
    padding: 6px 8px;
    color: var(--text-2);
    font-size: 12px;
    align-items: center;
    justify-content: center;
    background: var(--background-2);
    overflow: hidden;

    p {
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0px;
      max-width: 118px;
    }
  }
}

.user-form__tag-delete {
  min-width: 18px;
  height: 18px;
}

.user-form__select-value_teams__option {
  text-overflow: ellipsis;
  overflow: hidden;
}

.user-form__select-value_teams__value-container {
  overflow-y: auto !important;
  max-height: 131px;
  scrollbar-gutter: stable;
}

.user-form__select-value_teams__multi-value {
  flex-grow: 1;
  border-radius: 10px !important;
  font-family: Montserrat;
  font-size: 14px;

  .user-form__select-value_teams__multi-value__label {
    margin-left: auto;
  }

  .user-form__select-value_teams__multi-value__remove {
    margin-left: auto;
    border-radius: 50%;
  }
}

.user-form__select-value_teams__control {
  &:hover,
  &:focus,
  &:focus-visible,
  &:focus-within {
    outline: none !important;
    box-shadow: none !important;
  }
  .user-form__select-value_teams__clear-indicator {
    display: none;
  }
}

.user-form__select-value_teams__dropdown-indicator,
.user-form__select-value__dropdown-indicator {
  padding: 2px !important;
}

.user-form__select-value__menu-portal,
.user-form__select-value_teams__menu-portal,
.project-form__select-value__menu-portal {
  z-index: 10000 !important;
}

.filter-row__right-side,
.filter-row__left-side {
  display: flex;
  align-items: center;
}

.filter-row__left-side {
  gap: 15px;
  width: 60%;
  flex: 1;
}

.filter-row__right-side {
  margin-left: auto;
  gap: 12px;
}

.filter-row__mobile-creation-button {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 24px;
    height: 24px;
    opacity: 0.55;
  }
}

.filter-row__users-extra-functions-button {
  border: 1px solid #c8d2e6 !important;
  background-color: #ffffff !important;
  text-transform: unset !important;
  height: 40px;
  border-radius: 7px !important;
  padding: 0px 15px !important;

  display: flex;
  justify-content: space-between;

  gap: 5px;
  p {
    margin: 0;
    color: #4c54a0;
    font-weight: 600;
  }
}

.control-bar__dropdown-fields {
  width: 100%;
  height: fit-content;
  white-space: nowrap;
  background: var(--background-1);
  position: absolute;
  top: 42px;
  right: 0px;
  border: 1px solid var(--stroke);
  border-radius: 10px;
  box-shadow: var(--box-shadow);
  overflow: hidden;

  p {
    color: var(--text-2);
    margin: 0px;
    font-size: 14px;
    line-height: 14px;

    img {
      width: 16px;
      height: 16px;
    }
  }
}

.control-bar__dropdown {
  min-width: 100%;
  white-space: nowrap;
  background: var(--background-1);
  position: absolute;
  top: 42px;
  border: 1px solid var(--stroke);
  border-radius: 10px;
  cursor: pointer;
  box-shadow: var(--box-shadow);
  display: flex;
  flex-direction: column;

  .control-bar__dropdown__option {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;

    img {
      width: 18px;
      height: 18px;
    }

    p {
      margin: 0;
      color: var(--text-1);
      font-size: 14px;
      line-height: 14px;
      font-weight: 400;
    }

    &:hover {
      background: var(--background-2);
    }

    &:first-child {
      border-radius: 10px 10px 0 0;
    }

    &:last-child {
      border-radius: 0 0 10px 10px;
    }
  }
}

.create-dropdown-trigger {
  border-radius: 0px 7px 7px 0px !important;
  width: 44px;
  padding: 8px 10px;
  background: var(--accent-dark);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;

  &:hover {
    background-color: var(--primary-btn-hover-bg);
  }

  img {
    width: 24px;
    height: 24px;
  }
}

.button-separator {
  position: absolute;
  left: 0px;
  background: #c8d2e6;
  width: 1px;
  height: 24px;
}

.filter-row__add-user-button {
  img {
    height: 20px;
    width: 20px;
  }
}

.filter-row__add-project-button {
  img {
    height: 24px;
    width: 24px;
  }
}

.filter-row__add-user-button,
.filter-row__add-project-button {
  padding: 8px 10px 8px 10px !important;
  border-radius: 7px 0px 0px 7px !important;
  display: flex;

  .filter-row__add-user-button_text {
    letter-spacing: 0.4px;
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
    font-weight: 400;
  }
}

.user-list__block {
  width: 100%;
}

.users-page__users-buttons {
  position: fixed;
  top: 130px;
  width: 100%;
  padding: 0px 28px 0px 30px;
  display: flex;
  gap: 30px;
  color: var(--text-2);

  .users-page__user-button {
    cursor: pointer;
    width: 24px;
    height: 24px;
  }
}

.deactivate-dropdown__menu-portal {
  z-index: 10 !important;
}

.deactivate-dropdown {
  width: auto;
  min-width: 150px;
  margin-top: 11px;
  padding-left: 15px;
}

.edit-user-form {
  position: absolute;
  width: 100%;
  bottom: 64px;
  top: 55px;
  overflow: scroll;

  .profile-photo {
    margin-top: 30px;
  }
}
.users-page__users-content {
  position: fixed;
  bottom: 0;
  width: 100%;
  top: 165px;
  z-index: 10;
  overflow: auto;
}

.users-page__user-list {
  width: fit-content;
  scrollbar-gutter: stable;
  color: var(--text-2);
  display: flex;
  flex-direction: column;
}

.users-page__user-list-header {
  width: fit-content;
  display: flex;
  height: 50px;
  align-items: center;
  gap: 25px;
  scrollbar-gutter: stable;
  position: sticky;
  top: 0;
  background: var(--background-1);
  z-index: 1;

  @media (max-width: 600px) {
    padding: 0px 0px 0px 5px;
    gap: 10px;
  }

  p {
    text-align: left;
    margin: 0;
  }

  .users-page__user-list-header-value {
    color: var(--text-2);
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
  }

  .users-page__user-list-header-value.name {
    @media (max-width: 600px) {
      width: 50% !important;
      white-space: nowrap;
    }
  }

  .users-page__user-list-header-value.role {
    @media (max-width: 600px) {
      width: 20% !important;
    }
  }

  .users-page__user-list-header-value_team {
    cursor: pointer;
  }

  .users-page__user-list-header-value_tags,
  .users-page__user-list-header-value_team {
    width: 20%;
  }
}

.users-page__user-element {
  display: flex;
  align-items: center;
  gap: 25px;
  height: 48px;
  background: var(--background-1);
  box-sizing: border-box;
  border-radius: 3px;
  cursor: pointer;
  color: var(--text-2);
  font-size: 14px;

  &.is-selected {
    background: var(--background-2);

    .user-block__tags-overflow-indicator,
    .is-teams {
      border: 1px solid var(--stroke);
    }
  }

  &:hover {
    background: var(--stroke);
  }

  @media (max-width: 600px) {
    padding: 0px 0px 0px 5px;
    gap: 10px;
  }
}

.users-block__avatar-container {
  display: flex;
  align-items: center;
  gap: 5px;
  position: relative;
  padding-right: 8px;

  @media (max-width: 600px) {
    max-width: 50%;
    min-width: 50%;
    white-space: nowrap;
  }
}

.user__buttons {
  display: flex;
  border-top: 1px solid #e6e6e9;
  justify-content: space-around;
  position: absolute;
  background: #fff;
  padding: 20px;

  bottom: 0;
  width: 100%;
}

.user-form-dialog-paper {
  overflow: inherit !important;
}

.page-switch__button_schedule,
.page-switch__button_info {
  justify-content: space-between;
  width: 120px !important;
  position: absolute !important;
  top: 165px;
  right: -40px;
  transform: rotate(270deg);
  height: 30px !important;
  padding: 0px 4px !important;
  p {
    font-size: 14px;
  }
  z-index: 100;
}

.form-show,
.form-edit,
.form-create {
  .MuiDialogTitle-root {
    font-size: 22px;
  }
}

.form-show,
.form-edit,
.form-create {
  .MuiPaper-root {
    background: var(--background-1);
    width: 760px;
    height: 780px;
    max-width: unset;
    min-height: fit-content;
    max-height: 100%;
    border-radius: 20px;
    box-shadow: var(--box-shadow);
    overflow-x: hidden;

    @media (max-width: 600px) {
      width: 100%;
      height: 100%;
      margin: 0;
    }
  }

  &[project-type="time_off"] {
    .MuiPaper-root {
      height: 500px;
    }
  }

  .MuiTypography-root {
    padding: 30px 40px 0px 40px;
    display: flex;
    position: relative;
    align-items: center;

    p {
      color: var(--text-1);
      margin: 0px auto 5px auto;
      font-weight: 400;
    }

    img {
      position: absolute;
      right: 40px;
      margin: auto 0px;
    }
  }

  .MuiDialogContent-root {
    display: flex;
    padding: 0 40px 0px 40px;

    flex: 1;
    color: var(--text-2);
  }

  .MuiDialogActions-root {
    gap: 20px;
    padding-right: 20px;
    display: flex;
    align-items: center;

    .teambook-icon-button {
      margin: unset;
    }
  }

  .form_close {
    margin-left: auto;
    cursor: pointer;
    width: 24px;
    height: 24px;
    z-index: 15;

    @media (max-width: 600px) {
      top: 75px;
      right: 10px;
    }
  }
}

.user-form__change-photo {
  .modal-trigger {
    visibility: unset !important;
    background: var(--background-1) !important;
    width: 120px !important;
    height: 30px !important;
    border: 1px solid var(--stroke) !important;
    border-radius: 23.5px !important;
    bottom: -20px !important;
    top: unset !important;
    left: -10px !important;
    right: 0px !important;
    margin: 0 auto !important;

    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    gap: 10px;

    p {
      color: var(--text-2) !important;
      margin: 0 !important;
    }
  }
}

.user-form__left-side__field-name {
  font-size: 16px;
  color: var(--text-2);
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 7px;
  white-space: nowrap;
  align-self: self-start;
  padding-left: 18px;
}

.user-form__left-side {
  width: 110px;
  display: flex;
  flex-direction: column;
  padding: 0 10px 0 0;

  @media (max-width: 640px) {
    padding: 0px 10px;
    width: 45%;
  }

  .user-form__select-value__menu-list {
    min-height: 41px;
    max-height: 145px !important;
    padding: 0;
  }

  .user-form__select-value__menu {
    min-height: 45px;

    @media (max-width: 600px) {
      left: 125px;
      width: 170px;

      .user-form__select-value__option {
        white-space: nowrap;
      }
    }
  }
}

.user-form__select-value__option {
  overflow: hidden;
  text-overflow: ellipsis;
}

.user-form__tags-select {
  width: 100%;
}

.form__date-field {
  display: flex;
  align-items: center;
  position: relative;

  p {
    line-height: 24px;
  }

  .calendar-icon {
    width: 24px;
    height: 24px;
    position: absolute;
    right: 0px;
  }

  .MuiFormControl-root {
    width: fit-content !important;
    min-width: 150px;
    height: 24px;
    margin-left: 5px;
    display: flex;
    justify-content: center;
  }

  .MuiInputBase-root {
    font-size: 14px;
    color: var(--darkgray);

    input {
      margin: 0px;
      padding: 0px 0px 0px 0px;
      // color: var(--darkgray);
      font-family: Montserrat;
    }
  }

  .MuiOutlinedInput-root {
    & fieldset {
      border-color: transparent;
    }

    &:hover fieldset {
      border-color: transparent;
    }

    &.Mui-focused fieldset {
      border-color: transparent;
    }
  }
}

.user-form__teams-select {
  width: 100%;
  padding: 0px 18px;
  margin-bottom: 0px;
  display: flex;
  flex-direction: column;
  margin-top: 1px;
}

.user-form__teams-select.creating {
  margin-top: 11px;
}

.user-form__left-side {
  .user-form__select-value__control {
    margin-bottom: 15px;

    @media (max-width: 600px) {
      width: 120px !important;
    }
  }

  .user-form__select-value__multi-value {
    display: none;
  }
}

.form-show {
  .user-form__field {
    font-weight: 500;
  }

  .user-form__show-date {
    .user-form__value,
    .edit-form__teams {
      margin-top: 0px;
      padding-left: 0px;
    }
  }

  .user-form__value,
  .edit-form__teams {
    margin-top: 10px;
    height: 26px;
    font-size: 14px;
  }
}

.user-form__show-date {
  display: flex;
  justify-content: space-between;
}

.user-form__left-side,
.user-form__right-side {
  .form-error__error-message {
    margin-top: -10px;
  }
  .user-form__select-value__control,
  .user-form__select-value_teams__control {
    min-height: 43px;
    width: 100%;
  }

  .user-form__select-value__clear-indicator {
    display: none;
  }
}

.user-form__right-side {
  width: calc(100% - 110px);
  height: 100%;
  position: relative;

  @media (max-width: 600px) {
    width: 55%;
  }

  p {
    margin: 0;
    text-align: left;
  }

  .user-form__row {
    display: flex;
    margin-bottom: 20px;
    height: fit-content;
    width: 100%;
    gap: 20px;
    justify-content: space-between;

    .MuiInputBase-root {
      color: var(--text-2);
      border-bottom: none;

      input {
        font-family: Montserrat;
      }

      &:before {
        border-bottom: unset !important;
      }

      &:after {
        border-bottom: unset !important;
      }

      .Mui-Focused {
        border: unset !important;
      }

      .MuiInput-underline {
        &::after {
          border: unset !important;
        }
      }

      &:hover,
      &:active,
      &:focus-within {
        &:before,
        &:after {
          border-bottom: unset !important;
        }
      }
    }

    @media (max-width: 600px) {
      flex-direction: column;
      height: unset;
      gap: 5px;
      margin-bottom: 5px;
      padding-left: 10px;
    }

    .user-form__field-input {
      margin: 0px !important;

      width: 100% !important;

      .MuiOutlinedInput-root {
        font-family: Montserrat !important;
      }
    }

    .user-form__full-row-value {
      width: 100% !important;
      min-width: 100% !important;
      max-width: 100% !important;
      .user-form__field-input {
        width: 100% !important;
      }
    }

    .user-form__value-section {
      max-width: calc(50% - 15px);
      flex: 0.5;

      .text-form__paragraph {
        font-size: 14px;
      }

      .tb-react-select__field-name {
        margin-bottom: 0px !important;
      }

      @media (max-width: 600px) {
        max-width: 100%;
        min-width: 100%;
      }

      .user-form__field {
        min-width: fit-content;
        font-size: 14px;
        color: var(--text-2);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .user-form__value {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .MuiTextField-root {
        width: 100%;
      }
    }
  }
}

.form-error__error-message {
  @media (max-width: 600px) {
    width: calc(100% - 10px) !important;
    margin-left: 10px;
    padding: 10px 0px !important;
  }
}

.user-form__teams_show {
  max-height: 131px;
  overflow-y: auto !important;
}
.user-form__create-bg {
  margin-bottom: 20px;
}

.user-form__teams {
  overflow-x: hidden !important;
  padding-top: 2px;

  .user-form__team {
    margin-bottom: 5px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    background-color: var(--background-2);
    height: fit-content;
    border-radius: 20px;
    text-align: center;
    color: var(--text-2);
    padding: 6px 5px;
    margin: 2px 3px 2px 3px;
    font-size: 12px;
    line-height: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.deactivate-dropdown__value-container {
  font-weight: 600;
  justify-content: center;
}

.user-form__buttons,
.project-form__buttons {
  position: absolute;
  bottom: -10px;
  width: 100%;
  left: 0px;
  display: flex;
  gap: 20px;
  padding-bottom: 20px;

  @media (max-width: 600px) {
    bottom: unset;
    left: 10px;
    gap: 10px;
  }

  .form__edit-button {
    width: 180px;
    height: 40px;
    white-space: nowrap;

    @media (max-width: 600px) {
      width: fit-content;
    }
  }
}

.user-form__tags {
  width: 100%;
  max-height: 28%;
  min-height: 28%;
  padding: 1px 2px 1px 18px;
  scrollbar-gutter: stable;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  overflow-y: auto;
  align-content: flex-start;

  .user-form__tag {
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    border-radius: 20px;
    flex-grow: 1;
    padding: 3px 8px;
    color: #fff;
    font-size: 12px;
    white-space: nowrap;
    text-align: center;
  }
}

.user-form__teams {
  width: 100%;
  max-height: 32%;
  min-height: 32%;
  padding: 1px 2px 1px 18px;
  scrollbar-gutter: stable;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  overflow-y: auto;

  .user-form__tag {
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    border-radius: 20px;
    flex-grow: 1;
    padding: 6px 8px;
    color: #fff;
    font-size: 12px;
    white-space: nowrap;
    text-align: center;
  }
}

.users-block__content {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 22px;
  margin-left: 10px;
}

.users-block__invited-indicator {
  width: fit-content;
  height: fit-content;
  background-color: #5e6ab8;
  position: absolute;
  top: -3px;
  left: -10px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1px;

  img {
    margin: 0px;
    padding: 0px;
    width: 20px;
    height: 20px;
  }
}

.users-block__email {
  width: 13.5%;

  @media (max-width: 600px) {
    width: 25%;
  }
}

.users-block__role {
  width: 7.5%;

  @media (max-width: 600px) {
    width: 20%;
  }
}

.users-page__user-list-header-value_billable {
  width: 8.5%;
  cursor: pointer;
}

.users-block__billability {
  margin: 0;
  width: 8.5%;
  text-align: left;
}

.user-block__select-checkbox {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;

  img {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  input[type="checkbox"] {
    accent-color: #474747;
    width: 18px;
    height: 18px;
  }
}

.users-block__teams {
  width: 18% !important;
}

.users-block__tags {
  flex: 1;
}

.users-block__tags,
.users-block__teams {
  width: 20%;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.user-block__invited,
.users-page__user-list-header-value_invited {
  width: 5% !important;
  text-align: left;
  margin: 0;

  @media (max-width: 600px) {
    flex: 1;
  }
}

.users-block__name,
.users-block__email,
.users-block__role {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-align: left;
  overflow: hidden;
  word-break: break-all;
  margin: 0px;
}

.users-block__department {
  display: flex;
  width: 13.5%;

  p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 6px 0 0 5px;
  }

  .departments-color-box {
    margin-top: 5px;
  }

  @media (max-width: 600px) {
    width: 25%;
  }
}

.users-block__buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  font-size: 30px;
}

.users-block__buttons {
  button {
    padding: 0px;
    color: #333333;
  }
}

.users-block-right {
  max-width: 200px;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 1700px) and (min-width: 1100px) {
    max-width: 140px !important;
  }
}

.user-block__status {
  background-color: #5e6ab8;
  border-radius: 100px;
  line-height: 22px;
  font-size: 0.9rem;
  color: white !important;
  width: 100px;
  margin: 10px 0 5px 0;
  text-align: center;
  position: absolute;
  bottom: 10px;
}

.user-show__status {
  background-color: #5e6ab8;
  border-radius: 100px;
  line-height: 22px;
  font-size: 0.9rem;
  color: white !important;
  width: 100px;
  margin: 10px 0 5px 0;
  text-align: center;
}

.user-show__component {
  margin-top: 15px;
}

.user-show__main-data {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: 0 10%;
  margin-bottom: 15px;
}

.user-show__main-data > p {
  margin-bottom: unset;
  color: #333333;
  font-size: 1.1rem;
}

.tags-color-circle {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.profile-photo .change-photo-cmps {
  flex-direction: column;
  align-items: center;
}
.profile-photo .change-photo-cmps .image {
  position: relative;
}
.profile-photo .change-photo-cmps .text-block {
  display: none;
}

@media (max-width: 1100px) {
  .users-page__user-list {
    width: 100% !important;
    margin: 0px !important;
  }
  .users-block__tags {
    max-width: 100px !important;
  }
}

.tags-dialog__container {
  .row {
    @media (max-width: 640px) {
      flex-wrap: nowrap;
    }
  }
  .col-md-3 {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;

    @media (max-width: 640px) {
      margin: 0;
    }
  }

  .MuiPaper-root {
    width: 628px;
    max-width: 629px;
    height: 369px;
    max-height: 369px;
    border-radius: 20px;
    @media (max-width: 640px) {
      width: 480px;
      p {
        margin-bottom: 0px;
      }
    }
  }

  .filter-menu__title {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-right: 30px;

    img {
      cursor: pointer;
    }
    .filter-menu__title-text {
      margin: 0;
    }
    .filter-menu__title-add {
      width: 24px;
      height: 24px;
    }
    .filter-menu__title-close {
      margin-left: auto;
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }
  .tag-menu__tag-content {
    .MuiOutlinedInput-root {
      height: 40px;
    }
    .tag-menu__items__filter-buttons {
      margin: 20px 0 0 0;
      display: flex;
      padding-right: 18px;
      background-color: var(--background-2);
      border-radius: 7px;
      height: 39px;
      gap: 20px;

      p {
        user-select: none;
        display: flex;
        align-items: center;
        // justify-content: center;
      }
    }
    .tag-menu__items__filter-buttons > .tags-filter {
      text-transform: unset !important;
      cursor: pointer;
      margin-bottom: 0;
      padding-left: 10px;
    }

    .tag-menu__items__filter-buttons > .column-1 {
      width: 70px;
      font-weight: 500;
    }

    .tag-menu__items__filter-buttons > .column-2 {
      width: 200px;
      font-weight: 500;
    }

    .tag-menu__items {
      .tags-menu__tag-element {
        display: flex;
        align-items: center;
      }

      .tags-menu__tag-element__info {
        display: flex;
        gap: 20px;
        align-items: center;
        width: 100%;
        height: 44px;
        margin-top: 10px;

        .tags-menu__tag-element__name {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          width: 200px;
          padding-left: 16px;
        }

        .tags-menu__tag-element__number {
          width: 70px;
          user-select: none;
          cursor: pointer;
          text-align: center;
        }

        p {
          color: var(--text-2);
        }

        .tags-menu__tag-element__color-box {
          width: 70px;

          .tags-menu__tag-element__color-circle {
            width: 24px;
            height: 24px;
            border-radius: 16px;
            margin-left: 23px;
          }
        }
        .tag-menu__tag-element__control {
          width: 108px;
          display: flex;
          gap: 20px;
          padding-left: 48px;
        }
      }

      .tags-menu__tag-element__info > p {
        margin: 10px 0px;
        @media (max-width: 640px) {
          margin: 0px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

.manage-tags-icon {
  padding: 8px 10px;
  border: 1px solid var(--stroke);
  border-radius: 7px;
  max-width: 46px;
  max-height: 42px;
}
img {
  cursor: pointer;
}

.info-tooltip {
  position: fixed;
  width: 130px;
  height: 115px;
  background: #ffffff;
  border: 1px solid #d5d9ff;
  z-index: 100;
  padding: 5px;
  box-shadow: 5px 10px 30px rgba(90, 140, 199, 0.2);
  border-radius: 4px;

  .info-tooltip__data {
    background-color: #fff;
    overflow: hidden;
    height: 100%;
    width: 100%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    gap: 5px;

    .info-tooltip__tag,
    .info-tooltip__team {
      display: flex;
      padding: 0px 8px;
      border-radius: 100px;
      height: 24px;
      width: 100%;
      p {
        font-size: 14px;
        margin: auto;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .info-tooltip__tag {
      color: #fff;
    }

    .info-tooltip__team {
      color: #474747;
    }
  }

  &::after {
    content: "";
    z-index: -1;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: -7px;
    width: 14px;
    height: 14px;
    background: inherit;
    transform: rotate(45deg);
    box-shadow: inset -1px -1px 0px 0px #d5d9ff;
  }
}

.tag-menu__control-panel {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 10px;
}

.tag-menu__tag-editing {
  margin-top: 10px;
  height: 44px;
  display: flex;
  align-items: center;
  gap: 10px;

  .tag-menu__tag-number {
    width: 70px;
    text-align: center;
    padding-left: 20px;
  }

  .MuiFormControl-root {
    background-color: var(--background-2) !important;
  }
}

.tag-menu__tag-editing__tag-name {
  margin-left: 10px;
  height: fit-content;
  //width: 200px;
}

.user-form__creation-info {
  color: var(--text-2);

  b {
    color: var(--text-1);
  }

  p {
    margin: 0px;
    font-size: 12px;
  }
}

.users-data__tabs {
  gap: 10px;
  margin: 20px auto;
}

.users-data__tab {
  width: 50%;
  cursor: pointer;
  border: 1px solid var(--stroke);
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  border-radius: 8px;

  img {
    width: 24px;
    height: 24px;
  }

  p {
    margin: 0;
    font-size: 16px;
    text-align: center;
    color: var(--text-2);
  }

  &.active {
    background: var(--stroke);

    p {
      font-weight: 400;
      color: var(--text-1);
    }
  }
}

.upload-photo-dialog {
  width: 545px;
  height: 490px;
  position: relative;

  section {
    height: 360px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 36px;
  }

  .actions {
    position: absolute;
    bottom: 0px;
    right: 0px;
  }
}

.users__schedule-tab__head-row {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  margin-bottom: 20px;
}

.users__schedule-tab {
  padding: 0 0px 20px 0px;

  .head-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    width: 220px;

    .input-form__form-text,
    .tb-react-select__field-name {
      margin-bottom: 0px !important;
    }

    .head-text {
      width: fit-content;
      color: var(--text-2);
      font-family: Montserrat;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: -0.408px;
      margin-bottom: 10px;
    }
  }

  .head-text__strict-working-hours {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-right: 10px;
  }

  .users__custom-working-hours__input {
    display: flex;

    .user-form__field-input {
      margin: unset;
    }

    .text-form__paragraph {
      font-size: 14px;
    }

    .input-description {
      position: absolute;
      bottom: 11px;
      margin: unset;
      margin-left: 88px;
      font-size: 14px;
    }
  }

  .head-value {
    color: var(--text-2);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 22px;
    letter-spacing: -0.408px;
  }

  .schedule__day {
    display: flex;
    width: 100%;
    align-items: center;
  }

  .schedule__day-checkbox {
    width: 11.19%;
  }
  .schedule__day-start {
    width: 14.14%;
    margin-left: 8.83%;
  }
  .schedule__day-lunch {
    width: 25.48%;
    margin-left: 5.89%;
  }
  .schedule__day-end {
    width: 14.14%;
    margin-left: 5.89%;
  }
  .schedule__day-duration {
    width: 9.28%;
    margin-left: 5.89%;
  }
}

.users__custom-working-hours {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  p {
    margin: 0px;
  }

  .users__custom-working-hours__title {
    display: flex;
    align-items: center;

    p {
      margin: unset;
      cursor: pointer;
    }
  }
}

.users__schedule-tab {
  .schedule__component {
    .schedule__header {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      padding-bottom: 10px;

      .schedule__header-lunch {
        margin-left: 55px;
      }
      .schedule__header-end {
        margin-left: 58px;
      }
      .schedule__header-duration {
        margin-left: 50px;
      }
    }

    .schedule-time__picker {
      background-color: var(--background-1);
    }
    .schedule__day-start,
    .chedule__day-end,
    .schedule__day-duration {
      text-align: center;
    }
    input {
      font-family: Montserrat;
      width: 48px !important;
    }
  }
}

.user-form__define-dates-text {
  display: flex;
  gap: 10px;
  margin-bottom: 8px;

  p {
    color: var(--text-2);
  }
}

.users__notifications {
  margin-top: 30px;
  padding: 0px;
}

.users__import-inline-text {
  p {
    display: inline-block;
    margin-bottom: unset;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.41px;
  }

  .users__import-link-text {
    color: var(--link);
    text-decoration: underline;
    cursor: pointer;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.41px;
  }
}

.users__import-defaul-text {
  p {
    margin-bottom: unset;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.41px;
  }
}

.users__import-or-line {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 40px 0;

  .line {
    flex-grow: 1;
    height: 1px;
    background-color: var(--stroke);
  }
  p {
    font-size: 14px;
    margin: 0 10px;
    color: var(--link);
  }
}

.tag-menu__tag-add {
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.tags-tooltip {
  position: fixed;
  width: 230px;
  height: fit-content;
  background: var(--background-1);
  overflow: auto;
  border: 1px solid var(--stroke);
  box-shadow: var(--box-shadow);
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;

  p {
    color: var(--text-2);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0;
  }
  @media (max-width: 1100px) {
    display: none;
  }
}

.users-page-filter {
  width: 500px !important;
  max-width: 500px !important;
}

.empty-filtered {
  width: fit-content;
  position: relative;
  left: 100px;
  top: 95px;

  img {
    position: relative;
    left: 750px;

    @media (min-width: 1600px) {
      left: 900px;
    }

    @media (max-height: 800px) {
      top: -40px;
    }
  }
}

.empty-filtered-text {
  font-size: 20px;
  color: var(--text-9);
  letter-spacing: 0.4px;
}

.filter-row__filter-search {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px var(--background-1) inset !important;
    -webkit-text-fill-color: var(--text-2) !important;
  }
}

.user__edit-button {
  p {
    font-weight: 400;
  }
}

.users-page__user-element:hover,
.projects-page__project-element:hover {
  .user-list__fixed-field {
    background: var(--stroke) !important;
  }
}

.users-page__user-element.is-selected,
.project-list__block.is-selected {
  .user-list__fixed-field {
    background: var(--background-2);
  }
}

.user-list__fixed-field {
  display: flex;
  align-items: center;
  position: relative;
  position: sticky;
  left: 0px;
  padding-left: 30px;
  top: 0px;
  gap: 25px;
  background: var(--background-1);
  height: 100%;
}

.users-page__user-list-header,
.projects-page__project-list-header {
  .user-list__fixed-field {
    z-index: 50;
  }
}

.projects-page__project-element {
  .user-list__fixed-field {
    z-index: 2;
  }
}

.departments-color-box {
  min-width: 8px;
  height: 24px;
  border-radius: 2px;
  margin-top: 10px;
}

.edit-user__outlined-button {
  margin: unset !important;
  min-width: 90px !important;
}
.edit-user__blue-button {
  margin: unset !important;
}

.users-schedule__text-link {
  display: flex;
  margin-bottom: 30px;

  p {
    font-size: 12px;
    font-style: italic;
  }

  .users-schedule__link {
    text-decoration: underline;
    color: var(--link);
    cursor: pointer;
  }
}

.user-form__department-name {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 26px;
  margin-top: 8px;

  .user-form__department-box {
    width: 5px;
    height: 15px;
    border-radius: 2px;
    margin-top: 6px;
  }
}
