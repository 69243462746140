.header__container {
  position: fixed;
  z-index: 30;
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 30px;
  background: var(--background-1);
  border-bottom: 1px solid var(--stroke);

  .col-3,
  .col-6 {
    height: 100%;
    padding: 0;
  }

  p {
    margin: 0 !important;
  }
}

.header__left-side {
  display: flex;
  align-items: center;

  img {
    width: 50px;
    height: 32px;
  }

  .mobile-logo {
    width: 50px;
    height: 32px;
  }
}

.header__center {
  display: flex;
  align-items: center;
  justify-content: center;
  .no-gap {
    gap: 0px;
  }
}

.mobile-menu__select {
  cursor: pointer;
  color: var(--text-2);
  font-size: 18px;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.4px;

  &:hover {
    color: var(--header-nav-color);
  }
}

.header__right-side {
  display: flex;
  justify-content: end;
}

.header__user-info-container {
  display: flex;
  gap: 40px;
  align-items: center;
}

.header__help-icon {
  cursor: pointer;
  width: 24px;
  height: 24px;
}

.header__user-info {
  display: flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;

  p {
    color: var(--text-2);
    font-size: 16px;
    letter-spacing: 0.503px;
  }
}

.header__items {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 60px;

  a {
    height: 100%;
    color: var(--text-3);
    font-size: 20px;
    text-decoration: none;
    display: flex;
    align-items: center;
  }

  .active {
    font-weight: 600;
    p {
      color: var(--text-1) !important;
    }
    // border-bottom: 2px solid var(--header-nav-color);
    opacity: 1 !important;
    border-radius: 7px;
    background: var(--stroke);

    .header-link {
      opacity: 1;
    }
  }
}

.header__items-group {
  display: flex;
  gap: 10px;
}

.header-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 5px 10px;
  opacity: 0.6;

  p {
    color: var(--text-2);
    text-align: center;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px; /* 85.714% */
    letter-spacing: 0.15px;
    white-space: nowrap;
  }

  img {
    width: 24px;
    height: 24px;
  }
}

.header__help-menu {
  opacity: 0.6;
  width: 54px;

  p {
    color: var(--text-2);
    line-height: 12px;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.15px;
    margin-top: 5px !important;
  }
}

.header-mobile-menu {
  position: fixed;
  bottom: 0;
  top: 60px;
  left: 0;
  right: 0;
  background-color: var(--background-2);
  z-index: 100;

  .header-mobile-menu__container {
    display: flex;
    flex-direction: column;

    .header-mobile-menu-logout__container {
      width: 100%;
      background: var(--stroke);
      height: 1px;

      @media (orientation: landscape) {
        margin-top: 30px;
      }
    }
  }

  .header-mobile-menu__link {
    text-transform: uppercase;
    font-size: 18px;
    text-align: start;
    color: var(--text-2);
    margin: 16px auto;
    text-decoration: none !important;

    &:hover {
      color: var(--header-nav-color);
    }

    @media (orientation: landscape) {
      margin: 10px auto;
    }
  }
}

.event-logs__filters {
  display: flex;
  margin-bottom: 10px;
  min-width: 670px;
  justify-content: space-between;
  align-items: center;
}

.event-logs__rows {
  overflow-y: scroll;
  max-height: 235px;
}

.event-logs__row {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 13px;
  margin: 5px 0;
}

.event-logs__no-logs {
  text-align: center;
}

.event-logs__select-option {
  p {
    font-size: 14px;
    margin: unset;
  }
}

.event-logs__export {
  display: flex;
  gap: 5px;
  align-items: center;
}
