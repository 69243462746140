.invoice-page {
  background-color: #efefef;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.invoice-container p {
  text-align: left;
}

.invoice-container {
  width: 600px;
  height: max-content;
  background-color: white;
  padding: 20px;
}

.invoice-header {
  display: flex;
  justify-content: space-between;
}

.left-side-text {
  font-size: 13px;
  color: #474747;
  margin-top: 5px;
}

.right-side-text {
  font-size: 21px;
  color: #454c9a;
  margin: 10px;
}

.thanks-text {
  font-size: 21px;
  text-align: left;
  margin: 40px 0;
}

.billed-text {
  font-size: 17px;
  color: #454c9a;
  font-weight: 500;
  text-align: left;
}

.billing-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}

.service-header {
  display: flex;
  justify-content: space-between;
}

.service-body {
  padding: 20px 0;
  border-bottom: 1px solid #8c97b2;
  border-top: 1px solid #8c97b2;
}

.invoice-footer {
  margin-top: 30px;
}

.footer-text {
  font-weight: 400;
  font-size: 13px;
}

.billing-text {
  font-size: 15px;
  margin-bottom: 3px;
  color: black;
}

.billing-blue-text {
  font-size: 15px;
  margin-bottom: 3px;
  color: #4c549f;
}
